import React, { useRef } from 'react';
import { Text, TouchableOpacity } from 'react-native';
import { withRouter } from 'react-router-native';
import { K } from '../../styles';

import styleSpread from '../../style-spread';
import styles from './link.styles';
import _ from 'lodash';

var s = styleSpread(styles);

var destructureRNCSS = rnStyles => {
  var webStyles = rnStyles;

  if (rnStyles.paddingHorizontal) {
    webStyles.paddingLeft = rnStyles.paddingHorizontal;
    webStyles.paddingRight = rnStyles.paddingHorizontal;
  }

  if (rnStyles.paddingVertical) {
    webStyles.paddingTop = rnStyles.paddingVertical;
    webStyles.paddingBottom = rnStyles.paddingVertical;
  }

  return webStyles;
}

class Link extends React.Component {
  constructor(props) {
    super(props);

    this.handlePress = this.handlePress.bind(this);
  }

  handlePress(event) {
    var {to} = this.props;

    if (to && !(event.ctrlKey || event.metaKey)) {
      this.props.history.push(to);

      if (this.props.onPress) this.props.onPress(event);

      if (K.isWeb) event.preventDefault();
    }
  }

  render() {
    var props = {..._.omit(this.props, ['staticContext', 'history', 'location', 'match', 'to', 'children', 'onPress'])};
    var children = typeof(props.children) === 'string' ? <Text accessibilityRole='link'>{this.props.children}</Text> : this.props.children;

    if (K.isWeb) {
      props.href = this.props.to;
      props.style = {textDecoration: 'none', display: 'flex', flexDirection: 'column', ...destructureRNCSS(props.style)};
      props.onClick = this.handlePress;
      props.dataset = props.dataSet;

      delete props.dataSet;
    }
    else {
      props.onPress = this.handlePress;
    }

    return K.isWeb ? (
      <a {...props}>{children}</a>
    ) : (
      <TouchableOpacity {...props}>{children}</TouchableOpacity>
    );
  }
}

export default withRouter(Link);
