import {K} from '../../styles';

var s = {};

s.docContainer = {
  ...(K.isWeb ? {maxHeight: 300} : {maxHeight: 150}),
};
  s.docTitle = {
    padding: K.spacing,
    textTransform: 'uppercase',
    fontSize: K.calcFont(14),
    fontWeight: 'bold',
    letterSpacing: 1
  };
  s.docContent = {
    backgroundColor: K.colors.gray,
    borderRadius: K.borderRadius,
    padding: K.spacing,
    ...(!K.isWeb ? {height: 150} : {}),
  };
s.buttons = {
  flexDirection: 'row',
  marginBottom: K.spacing * 2
};
  s.cancelButton = {
    ...K.button,
    flex: 1,
    marginRight: K.margin
  };
  s.acceptButton = {
    ...K.button,
    flex: 1,
    backgroundColor: 'black'
  };

export default s;
