import React, { Component } from 'react';

import { View, ScrollView, Keyboard, Platform, TouchableOpacity, Image, Text } from 'react-native';
import { CopilotStepView, confirm } from '@symbolic/rn-lib';
import { K } from '../../../styles';
import protagonistIcon from '../../../assets/protagonist-icon.png';
import polydotIcon from '../../../assets/polydot-icon.png';
import appleAppStoreDownloadIcon from '../../../assets/apple-app-store-download-icon.png';
import googleAppStoreDownloadIcon from '../../../assets/google-app-store-download-icon.png';
import Label from '../../label/label';
import styleSpread from '../../../style-spread';
import styles from './mobile-web-view.styles';
import * as Linking from 'expo-linking';

var s = styleSpread(styles);

class MobileWebView extends Component {
  get storeLink() {
    return K.appStoreLinks[Platform.OS === 'android' ? 'google' : 'apple'][this.props.appKey];
  }

  get storeIcon() {
    return Platform.OS === 'android' ? googleAppStoreDownloadIcon : appleAppStoreDownloadIcon;
  }

  render() {
    var {appKey} = this.props;

    return (
      <View style={{width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center', padding: 25}}>
        <Image source={{weflowLite: polydotIcon, protagonist: protagonistIcon}[appKey]} style={{width: 200, height: 200}}/>
        <Label style={{marginTop: 50, marginBottom: 100, letterSpacing: 3, fontSize: 14}}>{this.props.appName}</Label>
        {appKey === 'designEngine' ? (
          <Text style={{textAlign: 'center'}}>
            {'This app is not compatible with mobile devices.\n\nPlease use a computer to access.'}
          </Text>
        ) : (
          <TouchableOpacity onPress={() => Linking.openURL(this.storeLink)}>
            <Image style={{width: 200, height: 59}} source={this.storeIcon}/>
          </TouchableOpacity>
        )}
      </View>
    );
  }
}

export default MobileWebView;
