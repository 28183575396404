import React, { Component } from 'react';
import { MentionInput, replaceMentionValues } from '@symbolic/react-native-controlled-mentions';
import { ScrollView, View, TouchableOpacity, Animated, Image } from 'react-native';
import confirm from '../../../confirm.js';
import prepareToAnimate from '../../../prepare-to-animate';
import Text from '../../text/text';
import styleSpread from '../../../style-spread';
import Button from '../../button/button';
import Popup from '../../popup/popup';
import PickerInput from '../../picker-input/picker-input';
import _ from 'lodash';
import {K} from '../../../styles';
import styles from './comment.styles';
import lib from '@symbolic/lib';
import moment from 'moment';
import Swipeable from 'react-native-gesture-handler/Swipeable';
import xIcon from '../../../assets/x-icon.png';

var s = styleSpread(styles);

export default function commentClassFor({CommentComponent, resourceActions, appKey}) {
  class Comment extends Component {
    state = {};

    constructor(props) {
      super(props);

      this.renderRightSwipeActions = this.renderRightSwipeActions.bind(this);
      this.renderLeftSwipeActions = this.renderLeftSwipeActions.bind(this);
      this.destroy = this.destroy.bind(this);
      this.getSwipeableRef = ref => this.swipeableRef = ref;
    }

    renderSwipeActions({dragX, viewStyles, interpolation}) {
      const translateX = dragX.interpolate(interpolation);

      var swipeActionViewStyles = {
        ...styles.swipeActionView,
        borderRadius: K.borderRadius
      };

      return (
        <Animated.View style={[swipeActionViewStyles, {...viewStyles, backgroundColor: '#F89A90'}]}>
          <Animated.Text style={[styles.swipeActionText, {transform: [{translateX}]}]}>{'delete'}</Animated.Text>
        </Animated.View>
      );
    }

    renderRightSwipeActions(_progress, dragX) {
      return this.renderSwipeActions({
        dragX,
        viewStyles: {alignItems: 'flex-end'},
        interpolation: {inputRange: [-96, -95, 0], outputRange: [0, 1, 1]}
      });
    }

    renderLeftSwipeActions(_progress, dragX) {
      return this.renderSwipeActions({
        dragX,
        interpolation: {inputRange: [0, 100, 101], outputRange: [0, 0, 1]}
      });
    }

    async destroy() {
      var commentAuthorUser = _.get(this.props.usersById, `[${this.props.comment.authorUserId}]`);
      var activeUser = this.props.session.user;

      if (commentAuthorUser && commentAuthorUser.id === activeUser.id) {
        this.setState({resetSwipeable: true});

        if (await confirm('Delete', `Delete this comment?`)) {
          prepareToAnimate();

          this.props.destroyComments({ids: [this.props.comment.id]});
        }
        else {
          if (this.swipeableRef) this.swipeableRef.close();
        }
      }
    }

    get commentBodyArray() {
      return _.map(_.split(this.props.comment.body, /{{(@user\d*)}}/), body => {
        var isMention = body.match(/@user\d*/);
        var user;

        if (isMention) {
          var userId = _.replace(body, /\D/g, '');
          var user = _.get(this.props.usersById, `[${userId}]`);

          body = user ? `@${user.firstName}` : '?';
        }

        return {isMention, body, user};
      });
    }

    get isOwner() {
      var user = _.get(this.props.usersById, `[${this.props.comment.authorUserId}]`);

      return user && user.id === this.props.session.user.id;
    }

    render() {
      var user = _.get(this.props.usersById, `[${this.props.comment.authorUserId}]`);
      var SwipeableComponent = K.isWeb ? View : Swipeable;

      if (this.isOwner && !K.isWeb) {
        var swipeableProps = {
          renderRightActions: this.renderRightSwipeActions,
          renderLeftActions: this.renderLeftSwipeActions,
          onSwipeableRightOpen: this.destroy,
          onSwipeableLeftOpen: this.destroy,
          leftThreshold: 101,
          rightThreshold: 96,
          ref: this.getSwipeableRef,
          containerStyle: {
            width: '100%',
            borderRadius: K.borderRadius
          },
          childrenContainerStyle: {flex: 1, flexDirection: 'row'}
        };
      }
      else {
        var swipeableProps = {
          style: {flex: 1, flexDirection: 'row'}
        };
      }

      var commentContainerStyles = {
        ...styles.commentContainer,
        marginRight: 0
      }

      var commentStyles = {
        ...styles.comment,
        borderRadius: K.borderRadius
      }

      var lastUpdated = moment.utc(this.props.comment.lastUpdated).local();

      return (
        <View style={commentContainerStyles}>
          <SwipeableComponent {...swipeableProps}>
            <View style={commentStyles}>
              <View {...s.timeAuthorContainer}>
                <View style={{...styles.authorNameContainer, backgroundColor: user ? lib.colors.colorFor({user: user}) : K.colors.doubleGray}}>
                  <Text {...s.authorNameText}>{user ? user.name : '[Somebody]'}</Text>
                </View>
                <Text {...s.commentTimeText}>{lastUpdated.format(`h:mma ${moment().isSame(lastUpdated, 'day') ? '' : 'MMM D'}`)}</Text>
              </View>
              <Text {...s.commentBody}>
                {_.map(this.commentBodyArray, (commentSection, index) => (
                  <Text key={index+commentSection.body} style={{fontWeight: commentSection.isMention ? 'bold' : 'normal'}}>{commentSection.body}</Text>)
                )}
              </Text>
              {this.isOwner && (
                <TouchableOpacity style={{position: 'absolute', right: K.spacing, top: K.margin}}onPress={this.destroy}>
                  <Image style={{width: K.calc(20), height: K.calc(20)}} source={xIcon}/>
                </TouchableOpacity>
              )}
            </View>
          </SwipeableComponent>
        </View>
      );
    }
  }

  Comment = CommentComponent.connect(Comment, {
    mapState: (state, ownProps) => ({
      session: state.session,
      usersById: {..._.keyBy(ownProps.additionalUsers, 'id'), ...state.resources.users.byId}
    }),
    mapDispatch: {
      ..._.pick(resourceActions.comments, ['destroyComments']),
    }
  });

  return Comment;
}
