import {K} from '../../../styles';

var s = {};

s.commentContainer = {
  marginBottom: K.margin,
  // flex: 1
};
s.comment = {
  flex: 1,
  // paddingHorizontal: K.spacing,
  // paddingVertical: K.spacing,
  backgroundColor: K.colors.gray,
};
  s.swipeActionView = {
    justifyContent: 'center',
    paddingHorizontal: K.spacing,
    flex: 1,
    height: '100%'
  };
    s.swipeActionText = {
      ...K.fonts.label,
      opacity: 1
    };
  s.timeAuthorContainer = {
    flexDirection: 'row',
    marginBottom: K.calc(10),
    alignItems: 'center'
  };
  s.authorNameContainer = {
    paddingHorizontal: K.spacing,
    paddingVertical: K.calc(10),
    borderTopLeftRadius: K.borderRadius,
    borderBottomRightRadius: K.borderRadius,
    marginRight: K.spacing
  };
  s.authorNameText = {
    // fontStyle: 'italic',
    // fontWeight: 'bold',
    // lineHeight: 20
  };
  s.commentBody = {
    // lineHeight: 20,
    paddingHorizontal: K.spacing,
    paddingBottom: K.calc(10)
  };
  s.commentTimeText = {
    color: '#aaa',
  };

export default s;
