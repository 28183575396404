import React, { Component } from 'react';
import { View, ScrollView, Keyboard, Platform, TouchableOpacity, Image } from 'react-native';
import { connect, setActiveOrg, updateOrgs, acceptOrgInvite } from '@symbolic/redux';
import { withRouter, Redirect } from 'react-router-native';
import { withSafeAreaInsets } from 'react-native-safe-area-context';
import { validation } from '@symbolic/lib';
import { K } from '../../../styles';

import lib, {api} from '@symbolic/lib';
import _ from 'lodash';

import './org-invite-accept.styles.js';

class OrgsInvitesAcceptPage extends Component {
  state = {requestComplete: false};

  componentDidMount() {
    this.request();
  }

  async request() {
    var {orgInviteId, code} = this.props.match.params;

    orgInviteId = parseInt(orgInviteId);

    await this.props.acceptOrgInvite({orgInviteId, code});

    setTimeout(() => {
      this.setState({requestComplete: true})
    }, 100);
  }

  render() {
    var render = null;

    if (this.state.requestComplete) {
      render = <Redirect to={`/`}/>
    }

    return render;
  }
}

export default connect({mapState: (state, ownProps) => {session: state.session}, mapDispatch: {acceptOrgInvite, updateOrgs, setActiveOrg}})(OrgsInvitesAcceptPage);
