import { K } from '../../styles';
import { Animated } from 'react-native';
import React, { useState } from 'react';
import _ from 'lodash';

export default class AttentionIndicator extends React.Component {
  state = {opacity: new Animated.Value(0.8)};

  componentDidMount() {
    Animated.loop(
      Animated.sequence([
        Animated.timing(this.state.opacity, {
          toValue: 0,
          duration: 1000,
          useNativeDriver: true,
        }),
        Animated.timing(this.state.opacity, {
          toValue: 0.8,
          duration: 1000,
          useNativeDriver: true,
        })
      ])
    ).start();
  }

  render() {
    var radius = this.props.radius || 5;

    return (
      <Animated.View
        pointerEvents='none'
        style={{
          opacity: this.state.opacity,
          position: 'absolute',
          top: '50%',
          left: '50%',
          marginTop: -radius + _.get(this.props.style, 'marginTop', 0),
          marginLeft: -radius + _.get(this.props.style, 'marginLeft', 0),
          backgroundColor: K.colors.deleteRed,
          borderColor: 'white',
          borderRadius: radius,
          width: radius * 2,
          height: radius * 2,
          zIndex: 1000,
          ..._.omit(this.props.style, ['marginLeft', 'marginTop'])
        }}
      />
    );
  }
}
