import {K} from '../../styles';

var s = {};

s.introQuestionnaire = {
  flex: 1,
  width: '100%',
  ...(K.isWeb ? {paddingVertical: K.spacing * 2} : {paddingTop: K.spacing * 3})
};
  s.logo = {
    marginHorizontal: K.spacing,
    alignSelf: 'center',
    alignItems: 'center',
    ...(K.isWeb ? {
      alignSelf: 'center',
      alignItems: 'center',
      width: 400
    } : {
      marginTop: 30,
    })
  };
  s.header = {
    ...K.fonts.label,
    lineHeight: 20 * 1.2,
    fontSize: 20,
    opacity: 1,
    // textAlign: 'right',
    marginBottom: 10,
    letterSpacing: 4,
  };


export default s;
