import React, { Component } from 'react';

import { Platform, Share, View, Modal, ScrollView, Pressable, TouchableOpacity } from 'react-native';
import { connect, logOut, createOrg } from '@symbolic/redux';
import { withRouter } from 'react-router-native';
import { withSafeAreaInsets } from 'react-native-safe-area-context';
import { K } from '../../styles';

import Label from '../label/label';
import Button from '../button/button';
import OrgPickerInput from '../org-picker-input/org-picker-input';
import ManageSubscriptionButton from '../manage-subscription-button/manage-subscription-button';
import styleSpread from '../../style-spread';
import styles from './menu.styles';
import _ from 'lodash';

var s = styleSpread(styles);

class Menu extends Component {
  state = {isCreatingOrg: false};

  tellAFriend = async () => {
    await Share.share({message: `Hey I've been using this app called ${this.props.appName} to ${{protagonist: 'make decisions in a more structured way', weflowLite: 'help me manage my projects and clarify process with my team'}[this.props.appKey]}. You should check it out: https://${{protagonist: 'protag', weflowLite: 'polydot'}[this.props.appKey]}.app`});
  }

  handleOrgCreate = async () => {
    if (!this.state.isCreatingOrg) {
      this.setState({isCreatingOrg: true});

      await this.props.createOrg({props: {type: 'business', title: 'Untitled Workspace'}});

      setTimeout(() => {
        var newOrg = _.last(this.props.session.orgs);

        this.props.hideMenu();

        this.setState({isCreatingOrg: false});

        this.props.history.push(`/edit-workspace/${newOrg.id}`);
      }, 1000);
    }
  }

  handleNotificationClick = ({notification}) => {
    this.props.hideMenu();
    this.props.onNotificationPress({notification});
  }

  handleLogOutPress = () => {
    this.props.onLogOut();
    this.props.logOut();

    this.props.history.push('/');
  }

  render() {
    var Notifications = this.props.Notifications || View;

    var {insets, appKey, session} = this.props;
    var {activeOrg} = session;

    return (
      <Modal {...s.modal} transparent animationType='fade' supportedOrientations={['portrait', 'landscape']}>
        <View {...s.container}>
          <Pressable dataSet={{nohover: 1}} {...s.hideMenuButton} onPress={this.props.hideMenu}/>
          <View style={{...styles.menu}}>
            <ScrollView style={{flex: 1}} contentContainerStyle={{...styles.scrollViewContentContainer, paddingRight: K.spacing + K.insets(insets, 'right')}}>
              <View style={{marginBottom: K.spacing * 2, alignSelf: 'stretch'}}>
                <Button style={{...styles.link}} label={'account settings'} onPress={() => {
                  this.props.hideMenu();

                  setTimeout(() => this.props.history.push('/my-account'));
                }}/>
                {/* {K.isWeb && <ManageSubscriptionButton style={{...styles.link}} hideMenu={this.props.hideMenu}/>} */}
                {!K.isWeb && (
                  <Button style={{...styles.link}} onPress={this.tellAFriend} label='Tell a friend'/>
                )}
                <Button style={{...styles.link}} label={'log out'} onPress={() => this.handleLogOutPress()} />
              </View>
              {_.includes(['member', 'owner'], activeOrg.role) && (                
                <View {...s.orgsContainer}>
                <Label style={{alignSelf: 'flex-end', marginBottom: K.spacing, marginRight: K.spacing}}>Workspaces</Label>
                <OrgPickerInput value={activeOrg} onChange={this.handleOrgChange} hideMenu={() => this.props.hideMenu()}/>
                <Button label='+ New Team Workspace' mode='dark' style={{marginTop: K.spacing}} onPress={this.handleOrgCreate}/>
              </View>
              )}
              <View {...s.notificationsContainer}>
                <Notifications {...{appKey}} userId={this.props.session.user.id} onNotificationPress={this.handleNotificationClick}/>
              </View>
              <Label style={{marginTop: K.spacing, textAlign: 'center', alignSelf: 'stretch'}}>v{global.APP_VERSION}</Label>
              <View style={{flex: 1}}/>
            </ScrollView>
          </View>
        </View>
      </Modal>
    );
  }
}

export default withSafeAreaInsets(withRouter(connect({mapDispatch: {logOut, createOrg}})(Menu)));
