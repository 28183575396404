import {K} from '../../styles';

var s = {};

s.modal = {
  height: '100%',
  width: '100%',
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: 10,
  borderWidth: 0
};
  s.container = {
    flex: 1,
    flexDirection: 'row'
  };
    s.hideMenuButton = {
      flex: 1,
      backgroundColor: K.colors.overlay
    };
    s.menu = {
      backgroundColor: 'white',
      width: '80%',
      maxWidth: 300,
    };
      s.scrollViewContentContainer = {
        alignItems: 'flex-end',
        paddingHorizontal: K.spacing,
        paddingTop: 60,
        paddingBottom: 40,
        minHeight: '100%'
      };
      s.orgsContainer = {
        alignSelf: 'stretch',
        marginBottom: K.spacing
      };
      s.notificationsContainer = {
        // flex: 1,
        marginTop: K.spacing,
        width: '100%',
        alignItems: 'flex-end'
      };
      s.link = {
        marginBottom: 1,
        paddingVertical: K.spacing / 2,
        paddingRight: K.spacing,
        alignItems: 'flex-end',
        alignSelf: 'stretch'
        //marginRight: K.spacing
      };


export default s;
