import { K } from '../../styles';

var s = {};

s.trialButton = {
  ...K.button,
  width: 'auto',
  backgroundColor: K.colors.blackGray,
  paddingHorizontal: K.spacing
};
  s.trialButtonTextContainer = {
    alignItems: 'flex-start',
    justifyContent: 'center'
  }
s.trialText = {
  ...K.fonts.su,
  opacity: 1,
  color: 'white',
  fontSize: K.calcFont(12)
};
s.titleContainer = {
  marginLeft: K.button.width - K.margin,
};


export default s;
