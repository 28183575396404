import {K} from '../../styles';

var s = {};

s.org = {
  flexDirection: 'row'
};
    s.editImage = {
      width: K.calc(20),
      height: K.calc(20),
      opacity: 0.5
    };
  s.orgDescriptionContainer = {
    marginBottom: 1,
    paddingHorizontal: K.spacing,
    paddingVertical: K.margin,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    alignSelf: 'stretch'
  };
    s.orgTextContainer = {
      flex: 1,
      paddingHorizontal: K.spacing,
      alignItems: 'flex-end'
    };

export default s;
