import {K} from '../../../styles';

var s = {};

s.editOrgView = {
  height: '100%',
  width: '100%',
  display: 'flex',
  flex: 1,
  position: 'absolute',
  backgroundColor: 'white'
}
  s.errorMessage = {
    paddingVertical: 15,
    height: 45,
    alignSelf: 'flex-end',
    marginRight: 20
  };
    s.errorMessageText = {
      ...K.fonts.sl,
      color: '#eb6c6c'
    }

  s.inputGroup = {
    marginVertical: 15
  };
    s.inputRow = {
      flexDirection: 'row',
    }
      s.input = {

      };
      s.inputContainer = {
        outerView: {paddingTop: K.spacing, flex: 1}
      }
      s.leftInputContainer = {
        ...s.inputContainer,
        outerView: {...s.inputContainer.outerView, marginRight: K.margin}
      }
      s.fullLineInputContainer = {
        ...s.inputContainer
      }
    s.buttonContainer = {
      backgroundColor: 'white',
      paddingVertical: K.spacing
    };
      s.saveButton = {
        backgroundColor: 'black'
      };
    s.userEditButton = {
      paddingLeft: K.spacing,
      width: 30,
      borderTopRightRadius: K.borderRadius,
      borderBottomRightRadius: K.borderRadius,
      marginLeft: 1,
      padding: 10,
      justifyContent: 'center',
      alignItems: 'center'
    };
    s.editImage = {
      width: 20,
      height: 20
    };
    s.importBackupButton = {
      backgroundColor: 'black'
    }
    s.leaveWorkspaceButton = {
      backgroundColor: K.colors.deleteRed,
      marginBottom: K.margin
    }
    s.deleteWorkspaceButton = {
      backgroundColor: '#c56360'
    }
    s.inviteLinkRow = {
      //paddingHorizontal: K.spacing,
      //height: K.button.height,
      flex: 1,
      flexDirection: 'row',
      // width: '100%',
      // flex: 1,
      borderRadius: K.borderRadius,
      marginBottom: K.margin
    }
      s.inviteLinkImage = {
        alignItems: 'center',
        justifyContent: 'center',
        height: K.calc(14),
        marginRight: 0,
        width: K.calc(14)
      }


export default s;
