import {K} from '../../styles';

var s = {};

s.dateInput = {
  // backgroundColor: K.colors.gray,
  paddingHorizontal: K.paddingHorizontal,
  height: K.inputHeight,
  justifyContent: 'center'
};
  s.text = {
    ...K.fonts.label
  };

s.quickValues = {
  marginBottom: K.spacing * 2,
  marginTop: K.spacing
}
  s.quickValueRow = {
    flexDirection: 'row',
  };
    s.quickValueButton = {
      ...K.button,
      flex: 1,
      backgroundColor: K.colors.gray,
      // flex: 0.5,
    };
      s.quickValueText = {
        ...K.fonts.label,
        opacity: 1
      };
      s.quickValueTextHint = {
        ...K.fonts.label,
        opacity: 0.3
      };

s.previousNextIconButton = {
  ...K.button,
  backgroundColor: K.colors.gray
};
  s.previousNextIcon = {
    width: K.calc(9),
    height: K.calc(16)
  };
s.calendar = {};
  s.calendar.textStyle = {
    ...K.fonts.label,
    opacity: 1
  };
  s.calendar.dayLabelsWrapper = {
    borderTopWidth: 0,
    borderBottomWidth: 0,
    opacity: 0.5,
    position: 'relative',
    left: 2
  };
  s.calendar.selectedDayColor = 'transparent';
  s.calendar.selectedDayStyle = {borderRadius: K.borderRadius, width: 44, height: 40, backgroundColor: 'black'};
  s.calendar.selectedDayTextColor = 'white';
  s.calendar.todayBackgroundColor = K.colors.gray;

export default s;
