import React, { Component } from 'react';
import { ScrollView, TouchableOpacity, View } from 'react-native';

import _ from 'lodash';
import styles from './notifications.styles';
import styleSpread from '../../style-spread';
import {K} from '../../styles';
import {api} from '@symbolic/lib';
import Label from '../label/label';
import Button from '../button/button';
import notificationClassFor from './notification/notification';

var s = styleSpread(styles);

export default function notificationsClassFor({NotificationsComponent, NotificationComponent, resourceActions, appKey='protagonist'}) {
  var Notification = notificationClassFor({NotificationComponent, resourceActions});

  class Notifications extends Component {
    state = {
      preloadedNotifications: [],    };

    constructor(props) {
      super(props);

      this.handleLoadMorePress = this.handleLoadMorePress.bind(this);
    }

    get sortedNotifications() {
      return _.orderBy(_.reject(this.props.notifications, {sourceUser: undefined}), [notification => notification.status === 'unseen', 'created'], ['desc', 'desc']);
    }

    async componentDidMount() {
      if (this.props.session.user.id !== null) {
        await api.update('notifications', _.map(_.filter(this.props.notifications, {status: 'unseen'}),  notification => ({props: {status: 'unread'}, where: {id: notification.id}})));

        await this.preloadMore();
      }
    }

    async componentWillUnmount() {
      this.props.updateNotifications({updates: _.map(_.filter(this.props.notifications, {status: 'unseen'}),  notification => ({props: {status: 'unread'}, where: {id: notification.id}})), hitApi: false});
    }

    async preloadMore() {
      var resourceKey = {
        protagonist: 'decision',
        weflowLite: 'processInstance'
      }[this.props.appKey] || 'decision';

      var notifications = await api.get('notifications', {
        page: {number: 1, count: 10},
        order: [{field: 'created', direction: 'desc'}],
        where: {
          resourceKey,
          id: {value: [-1, ..._.map(this.props.notifications, 'id'), ..._.map(this.state.preloadedNotifications, 'id')], operator: '!in'},
          userId: this.props.session.user.id
        }
      });

      this.setState({preloadedNotifications: [...this.state.preloadedNotifications, ...notifications]});
    }

    async handleLoadMorePress() {
      await this.props.trackNotifications({notifications: this.state.preloadedNotifications});

      setTimeout(() => {
        this.setState({preloadedNotifications: []}, () => {
          this.preloadMore();
        });
      });
    }

    render() {
      return (
        <View style={{width: '100%'}}>
          {this.sortedNotifications.length > 0 && (
            <Label style={{alignSelf: 'flex-end', marginRight: K.spacing, marginBottom: K.margin}}>notifications</Label>
          )}
          {_.map(this.sortedNotifications, notification => (
            <Notification key={notification.id} id={notification.id} onNotificationPress={this.props.onNotificationPress}/>
          ))}
          {this.state.preloadedNotifications.length > 0 && (
            <Button
              label={!_.isEmpty(this.props.notifications) ? 'load more...' : 'show old notifications'}
              style={{marginTop: K.margin}}
              onPress={this.handleLoadMorePress}
            />
          )}
        </View>
      );
    }
  }

  Notifications = NotificationsComponent.connect(Notifications, {
    mapDispatch: {
      ..._.pick(resourceActions.notifications, ['updateNotifications', 'trackNotifications']),
    }
  });

  return Notifications;
}
