import React from 'react';
import { View, ActivityIndicator } from 'react-native';
import {K} from '../../styles';
import Label from '../label/label';
import styleSpread from '../../style-spread';
import styles from './loading.styles';


var s = styleSpread(styles);


export default function Loading({styles={}, ...props}) {
  return (
    <View {...s.activityIndicatorContainer}>
      <ActivityIndicator size='large' color='black'/>
      <Label {...s.activityLabel}>{props.text}</Label>
    </View>
  );
};
