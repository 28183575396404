import {K} from '../../styles';

var s = {};

s.activityIndicatorContainer = {
  flex: 1,
  justifyContent: 'center'
};
s.activityLabel = {
  textAlign: 'center',
  marginTop: K.spacing * 4
};


export default s;
