import React, { Component } from 'react'; //eslint-disable-line

import { View, TouchableOpacity, } from 'react-native';
import { K } from '../../styles';

import lib, {api} from '@symbolic/lib';

import PickerInput from '../picker-input/picker-input';
import _ from 'lodash';
import Text from '../text/text';
import Label from '../label/label';
import TextInput from '../text-input/text-input';
import LabelledView from '../labelled-view/labelled-view';
import confirm from '../../confirm.js';
import styleSpread from '../../style-spread';
import styles from './color-scheme-editor.styles';
import Button from '../button/button';
import editIcon from '../../assets/edit-icon-black.png';
import checkIcon from '../../assets/check-icon.png';
import xIcon from '../../assets/x-icon.png';

var s = styleSpread(styles);

var colorGroups = [
  ['red', 'darkRed', 'darkDarkRed'],
  ['yellow', 'orange', 'darkOrange'],
  ['green', 'darkGreen', 'darkDarkGreen'],
  ['blue', 'darkBlue', 'darkDarkBlue'],
  ['purple', 'darkPurple', 'darkDarkPurple'],
];

var colorCodes = {
  '': 'black',

  red: '#F4AAAA',
  darkRed: '#ED9191',
  darkDarkRed: '#EC7979',

  yellow: '#FACD55',
  orange: '#FAB855',
  darkOrange: '#FA9A55',

  green: '#C8D4A3',
  darkGreen: '#A9CC9D',
  darkDarkGreen: '#99CDBA',

  blue: '#ABC0DB',
  darkBlue: '#97ABC5',
  darkDarkBlue: '#839EC0',

  purple: '#C3ABDB',
  darkPurple: '#A5A1DA',
  darkDarkPurple: '#8486B9'
};

var templates = [
  {templateKey: 'default',
    groups: [
      {label: 'Completion', colors: ['green', 'darkGreen', 'darkDarkGreen']},
      {label: 'Brainstorming', colors: ['yellow', 'orange', 'darkOrange']},
      {label: 'Barriers / Issues', colors: ['red', 'darkRed', 'darkDarkRed']},
      {label: 'Core Progress', colors: ['blue', 'darkBlue', 'darkDarkBlue']},
      {label: 'Planned / Preparing', colors: ['purple', 'darkPurple', 'darkDarkPurple']}
    ]
  },

  {templateKey: 'custom',
    groups: [
      {label: '', colors: []},
      {label: '', colors: []},
    ]
  },

  {templateKey: 'none',
    groups: [
      {label: '', colors: []},
      {label: '', colors: []},
    ]
  },
];

class ColorSchemeEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      colorScheme: this.props.org.colorScheme || {
          templateKey: 'none',
          groups: []
      },
      isEditingGroupIndex: null,
    };
  };

  handleColorSchemeChange(value) {
    if (this.state.colorScheme.templateKey == 'custom') {
      templates[1] = {...this.state.colorScheme};
    }

    newScheme = _.find(templates, ['templateKey', value.value]);

    this.setState({colorScheme: newScheme});

    this.props.onChange({value: newScheme});
  }

  getSelectedColorsFor(scheme) {
    return _.flatMap(scheme.groups, 'colors')
  }

  removeLabelFor (groupIndex) {
    var newLabelGroups = this.state.colorScheme.groups;

    newLabelGroups.splice(groupIndex, 1);

    var newScheme = {templateKey: 'custom', groups: [...newLabelGroups]};

    this.setState({colorScheme: newScheme});
    this.setState({isEditingGroupIndex: null});

    this.props.onChange({value: newScheme});
  }

  changeLabelName(groupIndex, value) {
    var schemeGroups = this.state.colorScheme.groups;

    schemeGroups[groupIndex].label = value;

    var newScheme = {templateKey: 'custom', groups: schemeGroups};

    this.setState({colorScheme: newScheme});

    this.props.onChange({value: newScheme});
  }

  deselectColor(groupIndex, colorName ) {
    if (this.isEditingGroupIndex !== null) {
      var labelGroups = [...this.state.colorScheme.groups];

      var newColors = _.filter(labelGroups[groupIndex].colors, value => value !== colorName);

      var labelName = labelGroups[groupIndex].label;
      var newGroup = {label: labelName , colors: newColors};

      labelGroups[groupIndex] = newGroup;

      var newScheme = {templateKey: 'custom', groups: labelGroups};

      this.setState({colorScheme: newScheme});

      this.props.onChange({value: newScheme});
    }
  }

  selectColor(selectedColors, colorName) {
    if (this.isEditingGroupIndex !== null && !_.includes(selectedColors, colorName)) {
      var labelGroups = [...this.state.colorScheme.groups];

      labelGroups[this.state.isEditingGroupIndex].colors.push(colorName);

      var newScheme = {templateKey: 'custom', groups: labelGroups};

      this.setState({colorScheme: newScheme});

      this.props.onChange({value: newScheme});
    }

  }

  async save() {
    await api.update('orgs', {where: {id: this.props.orgId}, props: {colorScheme: this.state.colorScheme}});

    this.props.onCancel();
  }

  async cancel() {
    if (await confirm('', 'Are you sure you want to cancel editing your workspace color scheme?')) {
      this.props.onCancel();
    }
  }

  get groupCount() {
    return this.state.colorScheme.groups.length;
  }

  render() {
    var currentScheme = this.state.colorScheme;
    var selectedColors = this.getSelectedColorsFor(currentScheme);

    return (
      <View>
        <View style={{flexDirection:'row'}}>
          <Button
            style={{alignSelf: 'stretch', marginRight: K.margin, flex: 1}}
            label='Cancel'
            onPress={() => this.cancel()}
          />
          <Button
            style={{alignSelf: 'stretch', flex: 1}}
            mode='dark'
            label='Save'
            onPress={() => this.save()}
          />
        </View>
        <Text style={{...K.fonts.label, backgroundColor: 'white', marginTop: K.spacing * 2, marginLeft: K.spacing}}>
          Template
        </Text>
        <PickerInput
          showDownArrow
          style={{height: K.inputHeight, marginTop: K.margin}}
          buttonStyle={{backgroundColor: K.colors.gray}}
          value={this.state.colorScheme.templateKey}
          options={[
            {value: 'none', title: 'None'},
            {value: 'default', title: 'Default'},
            {value: 'custom', title: 'Custom'}
          ]}
          onChange={({value}) => {
            this.handleColorSchemeChange({value});
          }}
        />

        <LabelledView label='Selected Colors' gray styles={{outerView: {marginBottom: K.spacing * 2, marginTop: K.spacing}, label: {marginBottom: 0}}}>
          <View style={{flexDirection: 'row', paddingHorizontal: K.spacing, paddingVertical: K.spacing}}>
              {_.map(colorGroups, (colorGroup, column) => (
                <View key={column} style={{flex: 1}}>
                {_.map(colorGroup, (key, index) => (
                  <TouchableOpacity
                    {...{key}}
                    onPress={() => this.selectColor(selectedColors, key)}
                    style={{alignSelf: 'stretch', backgroundColor: colorCodes[key],
                      marginBottom: index !== colorGroup.length - 1 ? K.margin : 0,
                      marginRight: column === colorGroups.length - 1 ? 0 : K.margin,
                      height: K.button.height, borderRadius: K.borderRadius,
                      borderWidth: selectedColors.includes(key) ? 1 : 0}}
                  />
                ))}
                </View>
              ))}
          </View>
        </LabelledView>
        <View style={{ width:'100%'}}>
          <View style={{ width:'100%',flexDirection:'row', marginBottom: K.margin}}>
            <Label style={{marginLeft: K.spacing}}>Meaning</Label>
            <Label style={{position:'absolute', left: '40%'}}>Colors</Label>
          </View>
          <View style={{flexDirection: 'column'}}>
            {_.map(currentScheme.groups, (group, groupIndex) => (
              <View key={groupIndex} style={{flexDirection:'row'}}>
                <TextInput
                  style={{width: '40%', marginTop: K.margin}}
                  editable={this.isOwner}
                  value={group.label}
                  placeholder='Label'
                  autoCapitalize='none'
                  onChange={({value}) => this.changeLabelName(groupIndex, value)}
                />
                <View style={{flexDirection: 'row', flexWrap: 'wrap', width: '40%'}}>
                  {_.map(group.colors, (key, index) => (
                    <TouchableOpacity
                      {...{key}}
                      onPress={this.state.isEditingGroupIndex == groupIndex ? ()=> this.deselectColor(groupIndex, key) : ''}
                      style={{alignSelf: 'stretch', backgroundColor: colorCodes[key],
                        marginTop: K.margin, marginLeft: K.margin,
                        height: K.button.height, width: K.button.width, borderRadius: K.borderRadius, borderWidth: 0}}
                    />
                  ))}
                </View>
                <View style={{flexDirection:'row'}}>
                  <Button
                    onPress={() => this.state.isEditingGroupIndex == groupIndex ? this.setState({isEditingGroupIndex: null}) : this.setState({isEditingGroupIndex: groupIndex})}
                    icon={this.state.isEditingGroupIndex == groupIndex ? checkIcon : editIcon}
                    style={{width: K.inputHeight, height: K.inputHeight, marginTop: K.margin, marginRight: K.margin}}
                  />
                  <Button
                    onPress={() => this.removeLabelFor(groupIndex)}
                    icon={xIcon}
                    style={{width: K.inputHeight, height: K.inputHeight, marginTop: K.margin}}
                  />
                </View>
              </View>
            ))}
          </View>

          <View style={{flexDirection:'row', width: '100%'}}>
            <Button
              onPress={() => this.setState({colorScheme: {templateKey: 'custom',
                groups: [...this.state.colorScheme.groups, {label: '', colors: []}]}})}
              label='+ Add Color Association'
              mode='dark'
              style={{height: K.inputHeight, marginTop: K.spacing * 2, marginBottom: K.margin}}
            />
          </View>

          <Text style={{...K.explanationText, marginTop: K.spacing * 2}}>Workspaces are able to have a color scheme. A color scheme is a collection of associations between meanings and colors, so that a team can infer general meaning based on the colors you select. The color scheme appears in the Help Menu under "Color & Symbol Key"</Text>
        </View>
      </View>
    );
  }
}

export default ColorSchemeEditor;
