import { K } from '../../styles';

var s = {};
  s.header = {
    flexDirection: 'row',
    padding: K.spacing
  };
    s.headerChildren = {
      flex: 1,
      marginRight: K.margin
    };
    s.button = {
      ...K.button,
      backgroundColor: K.colors.blackGray
    };
      s.backButton = {
        ...s.button,
        // marginLeft: K.spacing
      };
      s.menuImage = {
        width: K.calc(20),
        height: K.calc(20)
      };

export default s;
