import React, { Component } from 'react';

import { View, Image, Dimensions } from 'react-native';
import { K } from '../../styles';
import { Video } from 'expo-av';
import { withSafeAreaInsets } from 'react-native-safe-area-context';

import Text from '../text/text';
import Button from '../button/button';
import styleSpread from '../../style-spread';
import AppIntroSlider from 'react-native-app-intro-slider';
import nextIcon from './right-arrow-white.png';
import styles from './intro-slider.styles';
import _ from 'lodash';

var s = styleSpread(styles);

class IntroSlider extends Component {
  state = {index: 0, isShowingPopup: false, shouldClosePopup: false};

  componentDidMount() {
    setTimeout(() => this.setState({isLoaded: true}), 100);
  }

  renderItem = ({item: slide, index}) => {
    var maxSize = K.isWeb ? 400 : 320;
    var deviceWidth = Dimensions.get('window').width;
    var mediaHeight = _.min([maxSize, _.max([deviceWidth, K.isWeb ? 400 : 400])]);
    var mediaSize = slide.mediaResizeMode === 'contain' ? {width: _.min([deviceWidth, maxSize]), height: mediaHeight} : slide.mediaSize;

    return (
      <View style={[styles.slide, slide.styles]}>
        <Text {...s.slideTitle}>{slide.title}</Text>
        <View style={[styles.slideMedia, {height: mediaHeight}]}>
          {slide.image && <Image source={slide.image} style={{...mediaSize, resizeMode: slide.mediaResizeMode}}/>}
          {slide.video && <Video source={slide.video} style={mediaSize} resizeMode={slide.mediaResizeMode} shouldPlay={index === this.state.index} isLooping isMuted/>}
        </View>
        <Text {...s.slideBody}>{slide.body}</Text>
      </View>
    );
  }

  renderNextButton = () => (
    <Button
      component={View}
      icon={nextIcon}
      style={{marginRight: K.spacing, backgroundColor: 'black'}}
    />
  )

  renderDoneButton = () => this.renderNextButton
  keyExtractor = slide => slide.title

  render() {
    return (
      <AppIntroSlider
        data={this.props.slides}
        keyExtractor={this.keyExtractor}
        renderItem={this.renderItem}
        renderNextButton={this.renderNextButton}
        renderDoneButton={this.renderNextButton}
        onDone={this.props.onDone}
        onSlideChange={(index) => this.setState({index})}
        dataSet={{'intro-slider': 1}}
        {...styles.slider} //WARNING {...styles.slider}, not {...s.slider} - this is a collection of various style props
        style={{...styles.slider.style, opacity: this.state.isLoaded ? 1 : 0, paddingTop: this.props.insets.top}}
      />
    );
  }
}

export default withSafeAreaInsets(IntroSlider);
