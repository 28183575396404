import {K} from '../../../styles';

var s = {};

s.footer = {
  flexDirection: 'row',
  marginLeft: 1
};
s.footerRightContent = {
  marginLeft: 'auto',
  flexDirection: 'row',
  justifyContent: 'flex-end'
};
s.commentInput = {
  backgroundColor: K.colors.gray,
  height: 'auto',
  ...K.fonts.standard,
  borderRadius: K.borderRadius,
  paddingBottom: K.verticalInputPadding,
  paddingHorizontal: K.spacing,
  paddingTop: K.verticalInputPadding, //WARNING keep for web
  ...(K.isWeb ? {height: 60} : {maxHeight: 80}),
  //maxHeight: 200
};
s.addCommentButton = {
  backgroundColor: 'black',
  borderTopLeftRadius: 0,
  borderBottomLeftRadius: 0,
  height: 'auto',
  width: K.button.width,
  alignSelf: 'stretch'
};

export default s;
