import {K} from '../../styles';

var s = {};

s.sliderInput = {
  height: K.inputHeight
};
  s.sliderInputBackground = {
    backgroundColor: '#f0f0f0',
    width: '100%',
    height: '100%',
    borderRadius: K.borderRadius,
  }
  s.sliderInputFill = {
    height: K.inputHeight,
    borderRadius: K.borderRadius
  };

export default s;
