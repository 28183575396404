import { Platform } from 'react-native';
import Constants from 'expo-constants';
import uuid from 'uuid';

export default async function getDevice({appKey}) {
  var id = await sessionStore.get('deviceId');

  if (!id) {
    id = uuid();

    await sessionStore.set('deviceId', id);
  }

  var device = {id, env: process.env.NODE_ENV || 'development', os: Platform.OS, platform: Constants.platform, name: Constants.deviceName, appKey};

  return device;
}
