import React, { Component } from 'react';

import confirm from '../../../confirm';
import Button from '../../button/button';
import Label from '../../label/label';
import OrgIcon from '../../org-icon/org-icon';
import styleSpread from '../../../style-spread';
import getTrialStatus from '../../../get-trial-status';
import Text from '../../text/text';

import { View, TouchableOpacity, ScrollView, Image } from 'react-native';
import { api } from '@symbolic/lib';
import { connect, setAppData } from '@symbolic/redux';
import { K } from '../../../styles';

import Tooltip from '../../tooltip/tooltip';
import _ from 'lodash';
import processTypeTemplateCategories from './process-type-template-categories';
import styles from './process-type-picker.styles';
import deleteIcon from '../../../assets/x-icon-white.png';
import backIcon from '../../../assets/left-arrow.png';

var s = styleSpread(styles);

//TODO would need to load processSteps in protag and polydot and pass them as dependencies to get this to work. Alternatively could have this component make api request to get steps
// function handleStepsWithDefaults({processSteps, processType}) {
//   var steps = {};

//   var processStepsWithDefaultTeam = _.filter(processSteps, ({defaultTeam}) => !_.isEmpty(defaultTeam));

//   _.forEach(processStepsWithDefaultTeam, step => {
//     steps[step.id] = {
//       team: _.map(step.defaultTeam, (assigneeData, userId) => {
//         return {userId: _.toInteger(userId), ...assigneeData};
//       })
//     };
//   });

//   _.forEach(processSteps, step => {
//     steps[step.id] = {...step, status: processType.defaultStepStatus}
//   });

//   return steps;
// }

class ProcessTypeCategory extends Component {
  state = {isExpanded: false};

  render() {
    var {processTypeCategory} = this.props;
    var isExpanded = this.props.isExpanded || this.state.isExpanded;

    return (
      <View {...s.processTypeCategory} key={processTypeCategory.title}>
        <TouchableOpacity
          style={{
            ...styles.categoryTitle,
            ...(isExpanded ? {borderBottomRightRadius: 0, borderBottomLeftRadius: 0} : {}),
            ...(processTypeCategory.color ? {backgroundColor: processTypeCategory.color} : {})
          }}
          onPress={() => this.setState({isExpanded: !isExpanded})}
        >
          <View {...s.categoryTitleTextContainer}>
            <Text style={{...styles.categoryTitleText, textAlign: this.props.isCentered ? 'center' : 'left'}}>{processTypeCategory.title}</Text>
          </View>
        </TouchableOpacity>
        {isExpanded && (
          <ScrollView>
            {processTypeCategory.content.processTypes ? (
              <View {...s.processTypes}>
                {_.map(processTypeCategory.content.processTypes, (processType) => (
                  <TouchableOpacity
                    key={processType.id}
                    onPress={() => this.props.onSelect({processType})}
                    {...s.processType}
                  >
                    <Text style={{...styles.processTypeTitle, textAlign: this.props.isCentered ? 'center' : 'left'}}>{processType.title}</Text>
                  </TouchableOpacity>
                ))}
              </View>
            ) : (
              <View>
                {_.map(processTypeCategory.content.subCategories, (subCategory) => (
                  <View {...s.subCategoryContainer} key={subCategory.title}>
                    {!!subCategory.title && (<Text {...s.subCategoryTitle}>{subCategory.title}</Text>)}
                    <View {...s.processTypes}>
                      {_.map(subCategory.processTypes, (processType) => (
                        <View key={processType.id} style={{flexDirection: 'row', marginTop: K.margin}}>
                          <TouchableOpacity
                            key={processType.title}
                            onPress={() => this.props.onSelect({processType})}
                            style={{...styles.processType, ...(processType.isEditable ? {borderTopRightRadius: 0, borderBottomRightRadius: 0} : {})}}
                          >
                            <Text style={{...styles.processTypeTitle, textAlign: this.props.isCentered ? 'center' : 'left'}}>{processType.isCustom ? `${processType.pluralTitle}` : processType.pluralTitle}</Text>
                          </TouchableOpacity>
                          {processType.isEditable && (
                            <Button
                              style={{backgroundColor: 'black', borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderLeftWidth: 1, borderLeftColor: 'rgba(255, 255, 255, 0.5)'}}
                              onPress={() => this.deleteProcessType({id: processType.id})}
                            >
                              <Image source={deleteIcon} style={{width: K.calc(20), height: K.calc(20)}}/>
                            </Button>
                          )}
                        </View>
                      ))}
                    </View>
                  </View>
                ))}
              </View>
            )}
          </ScrollView>
        )}
      </View>
    );
  }
}

class ProcessTypePicker extends Component {
  state = {
    isShowingTemplates: this.props.showTemplates || false,
    orgId: _.get(this.props.session.user.appData, 'weflowLite.activeOrgId') || this.props.session.activeOrg.id,
    isSingleUse: 1
  };

  componentDidMount() {
    var {session} = this.props;

    if (!this.hasAccess) this.setState({orgId: session.user.personalOrgId});
  }

  createProcessInstanceFromType = async ({processType, newType, isBlank, processSteps}) => {
    var {user} = this.props.session;

    if (this.hasAccess || processType.orgId === user.personalOrgId) {
      var {showingTutorial} = this.props.activeView.data;

      if (this.props.beforeCreateProcessInstanceFromType) this.props.beforeCreateProcessInstanceFromType();

      this.props.logAnalyticsEvent('process_instance_created');

      var [processInstance, ...processInstances] = await api.create('processInstances', _.times(showingTutorial ? 1 : 1, index => {
        var localId = newType ? (index + 1) : processType.instanceCount + 1;

        return {
          title: `Untitled ${!isBlank ? (processType.title || 'Project') : 'Project'}`,
          orgId: processType.orgId,
          ownerId: user.id,
          processTypeId: processType.id,
          steps: {}, //handleStepsWithDefaults({processSteps, processType}),
          isPublished: processType.autoPublish,
          localId,
          wasModified: 0,
          ...this.props.creationProps
        };
      }));

      await this.props.trackProcessInstances({processInstances: [processInstance, ...processInstances]});

      if (this.props.afterCreateProcessInstanceFromType) this.props.afterCreateProcessInstanceFromType({processInstance, processType, newType, isBlank});
    }
    else {
      this.processTypeAlert();
    }
  }

  createProcessType = async ({processType: processTypeTemplate, orgId, isBlank=false}) => {
    var {session={}, beforeCreateProcessType, afterCreateProcessType} = this.props;

    var orgIsNotPersonal = _.find(session.orgs, {id: orgId}).type !== 'personal';
    var ownsNonPersonalWorkspaces = _.filter(session.orgs, {role: 'owner', type: 'business'}).length > 0;
    var onlyOneOrg = session.orgs.length === 1;
    var {showingTutorial} = this.props.activeView.data;

    if (orgIsNotPersonal && !this.hasAccess) {
      this.processTypeAlert();
    }
    else if (orgIsNotPersonal || onlyOneOrg || showingTutorial || await confirm('', 'Just checking! Do you mean to create a new project in your personal workspace?' + (!ownsNonPersonalWorkspaces ? '\n\nIf you want this project to be visible to others, the workspace owner will have to create it.' : ''))) {
      if (beforeCreateProcessType) beforeCreateProcessType();

      if (showingTutorial) this.props.setActiveView({data: {tutorialStep: 2}});

      var processType = await api.create('processType', {
        title: processTypeTemplate.title,
        pluralTitle: processTypeTemplate.pluralTitle, // + (this.props.activeView.data.showingTutorial ? ' (tutorial)' : ''),
        createdByUserId: session.user.id,
        instanceCount: 1,
        isSingleUse: this.state.isSingleUse,
        orgId
      });

      await this.props.trackProcessTypes({processTypes: [processType]});

      var processSteps = await api.create('processSteps', _.map(processTypeTemplate.processSteps, (step, index) => ({
        orgId,
        ..._.omit(step, ['substeps']),
        rank: index,
        processTypeId: processType.id,
        hasProtagonistDecision: step.hasProtagonistDecision ? 1 : 0
      })));

      var childSteps = _.flatten(await Promise.all(_.map(_.filter(processTypeTemplate.processSteps, 'substeps'), ({title, substeps}, index) => {
        var parentStep = _.find(processSteps, {title});

        return api.create('processSteps', _.map(substeps, (step, substepIndex) => ({
          orgId,
          ...step,
          rank: substepIndex,
          parentStepId: parentStep.id,
          processTypeId: processType.id,
        })));
      })));

      await this.props.trackProcessSteps({processSteps: [...processSteps, ...childSteps]});

      await this.createProcessInstanceFromType({processType, processTypeTemplate, newType: true, isBlank, processSteps: [...processSteps, ...childSteps]});

      if (afterCreateProcessType) afterCreateProcessType();
    }
  }

  get customProcessTypeCategories() {
    var {session={}} = this.props;
    var {user, activeOrg, orgs} = session;
    var customProcessTypeCategories = [];

    _.forEach(_.sortBy(orgs, type => type === 'personal' ? 0 : 1), org => {
      var customProcessTypes = _.filter(this.props.customProcessTypes, {orgId: org.id, isSingleUse: 0});

      if (customProcessTypes && customProcessTypes.length) {
        customProcessTypeCategories = [{
          title: `${org.type === 'personal' ? 'My' : org.title} Categories`,
          color: '#676d82',
          content: {
            subCategories: [
              {
                title: '',
                processTypes: _.map(customProcessTypes, processType => ({
                  ...processType,
                  isCustom: true,
                  // isEditable: activeOrg.role === 'owner' || processType.ownerId === user.id
                }))
              }
            ]
          }
        }, ...customProcessTypeCategories];
      }
    });

    return customProcessTypeCategories;
  }

  createBlankProcessType = () => {
    this.createProcessType({
      orgId: _.get(this.state, 'orgId', this.props.session.activeOrg.id),
      processType: {
        title: 'Project',
        pluralTitle: 'Untitled Category',
        processSteps: [
          {title: '', color: 'purple'},
          {title: '', color: 'darkPurple'},
          {title: '', color: 'blue'}
        ]
      }
    });
  }

  processTypeAlert = () => {
    var webMessage = `
Your 30-day trial has expired, so creating process types and projects in a shared workspace is temporarily disabled.

If you're part of a team, you can ask them to set you up with a paid plan that unlocks this feature, or you can purchase it yourself for $4/month.
    `;

    alert(K.isWeb ? webMessage : `This is a desktop-only feature - please visit ${this.domain} on your computer to give it a try!`);
  }

  get hasAccess() {
    return _.includes(['active', 'trial'], getTrialStatus({session: this.props.session}));
  }

  render() {
    var {customProcessTypes={}, session={}} = this.props;
    var {createProcessType, createBlankProcessType, createProcessInstanceFromType, customProcessTypeCategories} = this;
    var {user} = session;

    var userHasTypes = _.filter(customProcessTypes, ({orgId, isSingleUse}) => !isSingleUse && _.includes(_.map(session.orgs, 'id'), orgId)).length > 0;
    var isShowingTemplates = !userHasTypes || this.state.isShowingTemplates;
    var {showingTutorial} = this.props.activeView.data;
    var showBlank = this.props.showBlank; // && !showingTutorial;
    var orgId = showingTutorial || !this.hasAccess ? user.personalOrgId : _.get(this.state, 'orgId', _.get(session, 'activeOrg.id'));

    return (
      <View {...s.processTypePicker}>
        <ScrollView contentContainerStyle={{position: 'relative', paddingTop: K.spacing}}>
          {showingTutorial || isShowingTemplates ? (
            <View>
              {this.state.isShowingTemplates && (
                <Button
                  onPress={() => this.setState({isShowingTemplates: false})}
                  style={{position: 'absolute', top: -K.spacing / 2}}
                  icon={backIcon}
                />
              )}
              <Text style={{opacity: 0.5, textAlign: 'center', marginBottom: K.spacing * 1, marginHorizontal: K.spacing * 2}}>{showBlank ? 'Start with a customizable template' : 'Choose a template to get familiar with the app.\n\nYou\'ll be able to create projects from scratch later.'}</Text>
              {_.map(processTypeTemplateCategories, (processTypeCategory, index) => (
                <ProcessTypeCategory key={index} {...{processTypeCategory}}
                  key={processTypeCategory.title}
                  onSelect={({processType}) => createProcessType({processType, orgId})}
                  isCentered
                />
              ))}
              {showBlank && (
                <View style={{marginTop: K.spacing * 2}}>
                  <Text style ={{opacity: 0.5, marginBottom: K.spacing * 2, alignSelf: 'center'}}>OR</Text>
                  <TouchableOpacity onPress={createBlankProcessType} {...s.processType}>
                    <Text {...s.processTypeTitle}>Start from scratch</Text>
                  </TouchableOpacity>
                  {!showingTutorial && (
                    <View style={{marginVertical: K.spacing * 2, alignItems: 'center'}}>
                      <Label>Workspace:</Label>
                      <View style={{flexDirection: 'row', marginTop: K.margin}}>
                        {_.map(this.props.session.orgs, org => (
                          <Tooltip key={org.id} text={org.type === 'personal' ? 'My Private Workspace' : org.title}>
                            <OrgIcon
                              key={org.id}
                              initialsStyle={{fontSize: K.calcFont(12), letterSpacing: K.calcFont(1.5)}}
                              hideBorder={orgId !== org.id}
                              onPress={() => {
                                //HINT don't need to await
                                if (this.hasAccess) {
                                  this.props.setAppData({appKey: 'weflowLite', key: 'activeOrgId', value: org.id});

                                  this.setState({orgId: org.id});
                                }
                                else {
                                  this.processTypeAlert();
                                }
                              }}
                              org={org}
                            />
                          </Tooltip>
                        ))}
                      </View>
                    </View>
                  )}
                </View>
              )}
            </View>
          ) : (
            <View>
              <Text style={{opacity: 0.5, textAlign: 'center', marginBottom: K.spacing, marginHorizontal: K.spacing * 2}}>{'Choose an existing category:'}</Text>
              {_.map(customProcessTypeCategories, (processTypeCategory, index) => (
                <ProcessTypeCategory key={index} {...{processTypeCategory}}
                  key={processTypeCategory.title}
                  onSelect={createProcessInstanceFromType}
                  isCentered
                />
              ))}
              <Text style={{opacity: 0.5, textAlign: 'center', marginTop: K.spacing * 2, marginHorizontal: K.spacing * 2}}>{`OR`}</Text>
              <View style={{marginTop: K.spacing * 2}}>
                <TouchableOpacity onPress={() => this.setState({isShowingTemplates: true, isSingleUse: 1})} style={{marginBottom: K.margin, ...styles.processType}}>
                  <Text {...s.processTypeTitle}>Create new project</Text>
                </TouchableOpacity>
              </View>
            </View>
          )}
        </ScrollView>
      </View>
    );
  }
}

export default connect({
  mapState: state => _.pick(state, ['activeView']),
  mapDispatch: {setAppData}
})(ProcessTypePicker);
