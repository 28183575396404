import React, { Component } from 'react';
import _ from 'lodash';
import Button from '../button/button';
import Text from '../text/text';
import Popup from '../popup/popup';
import { View } from 'react-native';
import { withRouter } from 'react-router-native';
import { connect, setProtagonistData, setAppData, setActiveView } from '@symbolic/redux';

import styleSpread from '../../style-spread';
// import styles from './expired-trial-popup.styles';
import { K } from '../../styles';

// var s = styleSpread(styles);

class SubscriptionPopup extends Component {
  state = {};

  close = async () => {
    await this.props[APP_KEY === 'protagonist' ? 'setProtagonistData' : 'setAppData']({key: 'showedSubscriptionPopup', value: true});

    this.props.onClose();
  }

  handleButtonPress = () => {
    this.close();

    this.props.history.push('/billing');
  }

  get domain() {
    var url = {protagonist: 'protag.app', weflowLite: 'polydot.app'}[APP_KEY];

    return process.env.NODE_ENV === 'development' ? 'http://localhost:19006' : url;
  }

  render() {
    var webMessage = `
Your 30-day trial has expired, so this feature is temporarily disabled.

If you're part of a team, you can ask them to set you up with a paid plan that unlocks this feature, or you can purchase it yourself for $4/month.
    `;

    var copy = K.isWeb ? (this.props.webMessage || webMessage) : (this.props.mobileMessage || `This is a desktop-only feature - please visit ${this.domain} on your computer to give it a try!`);

    return (
      <Popup
        {...(K.orientation === 'landscape' ? {height: '75%'} : {})}
        onClose={this.close}
        scrollEnabled
      >
        <Text>{copy}</Text>
        {K.isWeb && <View style={{flexDirection: 'row', width: 'auto', marginTop: K.spacing * 2}}>
          <Button onPress={this.close} style={{flex: 1, marginRight: K.margin}} label={'Maybe later'}/>
          <Button style={{backgroundColor: K.colors.purchaseBlue, flex: 1}} textStyle={{color: 'black'}} onPress={this.handleButtonPress} label={'View paid plans'}/>
        </View>}
      </Popup>
    );
  }
}

export default withRouter(connect({
  mapState: (state, ownProps) => {
    var {activeView} = state;

    return {activeView};
  },
  mapDispatch: {
    setActiveView, setProtagonistData, setAppData
  }
})(SubscriptionPopup));
