import React, {Component} from 'react'; //eslint-disable-line

import { withSafeAreaInsets } from 'react-native-safe-area-context';
import { View, PanResponder, Dimensions } from 'react-native';
import { connect } from '@symbolic/redux';
import { K } from '../../styles';

import _ from 'lodash';
import Text from '../text/text';
import styleSpread from '../../style-spread';
import styles from './slider-input.styles';

var s = styleSpread(styles);

class SliderInput extends Component {
  state = {
    containerViewWidth: '',
  };

  async componentDidMount() {
    if (!this.props.disabled) {
      this._panResponder = PanResponder.create({
        onPanResponderTerminationRequest: () => false,
        onStartShouldSetPanResponder: (_event, gestureState) => {
          this.dx = 0;

          return true;
        },
        onPanResponderMove: (_event, gestureState) => {
          var {dx} = gestureState;
          var {maxBarWidth} = this;

          this.dx = dx;

          var value = this.props.value + (dx / maxBarWidth);
          var snapValue =  Math.min(Math.max(0,(Math.round(value * 20) / 20)), 1);

          this.setState({tempValue: snapValue});
        },
        onPanResponderRelease: () => {
          if (this.dx) {
            this.props.onChange({value: this.state.tempValue});
          }
        }
      });

      this.forceUpdate();
    }
  }

  componentDidUpdate() {
    if (this.state.tempValue !== undefined && this.state.tempValue === this.props.value) {
      this.setState({tempValue: undefined});
    }
  }

  get value() {
    return _.get(this.state, 'tempValue', this.props.value);
  }


  get maxBarWidth() {
    return this.state.containerViewWidth || 0;
  }

  considerSettingContainerViewWidth(event) {
    if (this.state.containerViewWidth !== event.nativeEvent.layout.width) {
      this.setState({containerViewWidth: event.nativeEvent.layout.width});
    }
  }

  handleTouchStart = () => {
    this.touchStarted = true;
    this.touchStartedTime = Date.now();
  }

  handleTouchEnd = (event) => {
    if (this.dx === 0) {
      var x = event.nativeEvent[K.isWeb ? 'layerX' : 'locationX'];
      var value = x / this.maxBarWidth;
      var snapValue =  Math.min(Math.max(0,(Math.round(value * 20) / 20)), 1);

      if (snapValue !== this.value) {
        this.setState({tempValue: snapValue});
        this.props.onChange({value: snapValue});
      }
    }
  }

  render() {
    var {maxBarWidth} = this;
    var barWidth = this.value * maxBarWidth;
    var color = this.props.color || 'white';

    return (
      <View
        style={{...styles.sliderInput, width: '100%', cursor: 'pointer', ...this.props.style}}
        onLayout={event => this.considerSettingContainerViewWidth(event)}
        onTouchStart={this.handleTouchStart}
        onMouseDown={this.handleTouchStart}
        onTouchEnd={this.handleTouchEnd}
        onMouseUp={this.handleTouchEnd}
        {..._.get(this, '_panResponder.panHandlers')}
      >
        <View style={{...styles.sliderInputBackground, backgroundColor: K.colors.doubleGray, ...this.props.backgroundStyle}}>
          <View style={{...styles.sliderInputFill, backgroundColor: color, width: barWidth, ...this.props.fillStyle}}>
            <View style={{justifyContent: 'center', height: '100%'}}>
              <Text style={{left: K.spacing, position: 'absolute', color: 'black', ...(K.isWeb ? {cursor: 'pointer', userSelect: 'none'} : {})}}>{this.props.formatDisplayValue({value: this.value})}</Text>
            </View>
          </View>
        </View>
      </View>
    );
  }
}

export default SliderInput;
