import React, { Component } from 'react';
import { View, Pressable, Animated, TouchableHighlight, TouchableWithoutFeedback, Image, TouchableOpacity } from 'react-native';

import _ from 'lodash';
import styles from './notification.styles';
import styleSpread from '../../../style-spread';
import {K} from '../../../styles';
import Swipeable from 'react-native-gesture-handler/Swipeable';
//import {TouchableOpacity} from 'react-native-gesture-handler';
import prepareToAnimate from '../../../prepare-to-animate';
import hideIcon from '../../../assets/x-icon.png';
import commentIcon from '../../../assets/comment-icon.png';

//import StoryIcon from '../../story-icon/story-icon';
import Text from '../../text/text';
import lib from '@symbolic/lib';
import moment from 'moment';

var s = styleSpread(styles);

export default function notificationClassFor({NotificationComponent, resourceActions}) {
  class Notification extends Component {
    state = {};

    constructor(props) {
      super(props);

      this.renderRightSwipeActions = this.renderRightSwipeActions.bind(this);
      this.renderLeftSwipeActions = this.renderLeftSwipeActions.bind(this);
      this.markAsRead = this.markAsRead.bind(this);
      this.handleNotificationPress = this.handleNotificationPress.bind(this);
      this.getSwipeableRef = ref => this.swipeableRef = ref;
    }

    renderSwipeActions({dragX, viewStyles, interpolation}) {
      const translateX = dragX.interpolate(interpolation);

      return (
        <Animated.View style={[styles.swipeActionView, {...viewStyles, backgroundColor: '#8DBECC'}]}>
          <Animated.Text style={[styles.swipeActionText, {transform: [{translateX}]}]}>Hude</Animated.Text>
        </Animated.View>
      );
    }

    renderRightSwipeActions(_progress, dragX) {
      return this.renderSwipeActions({
        dragX,
        viewStyles: {alignItems: 'flex-end'},
        interpolation: {inputRange: [-96, -95, 0], outputRange: [0, 1, 1]}
      });
    }

    renderLeftSwipeActions(_progress, dragX) {
      return this.renderSwipeActions({
        dragX,
        interpolation: {inputRange: [0, 100, 101], outputRange: [0, 0, 1]}
      });
    }

    markAsRead() {
      prepareToAnimate();

      this.props.updateNotifications({updates: [{props: {status: 'read'}, where: {id: this.props.notification.id}}]});
    }

    handleNotificationPress() {
      this.markAsRead();

      this.props.onNotificationPress({notification: this.props.notification});
    }

    get notificationBodyArray() {
      return [{isMention: false, body: this.props.notification.body}];
    }

    renderNotificationLink() {
      var {notification} = this.props;
      var {sourceUser, status} = notification;

      var lastUpdated = moment.utc(this.props.notification.lastUpdated).local();

      return (
        <TouchableOpacity style={{opacity: status === 'read' ? 0.5 : 1, alignSelf: 'stretch'}} onPress={this.handleNotificationPress} {...((/*!K.isWeb */false) && {waitFor: this.swipeableRef})}>
          <View {...s.notification}>
            <View {...s.notificationTimeContainer}>
              <View style={{...styles.sourceUser, backgroundColor: lib.colors.colorFor({user: sourceUser})}}>
                <Text {...s.sourceUserText}>{_.get(sourceUser, `name`, '?')}</Text>
              </View>
              {_.includes(['commentMention', 'comment'], notification.type) && (<Image style={{width: 10, height: 9, marginRight: K.spacing, opacity: 0.5}} source={commentIcon}/>)}
              <Text {...s.notificationTimeText}>{lastUpdated.format(`h:mma ${moment().isSame(lastUpdated, 'day') ? '' : 'MMM D'}`)}</Text>
            </View>
            <Text {...s.notificationBody}>
              {_.map(this.notificationBodyArray, (notificationSection, i) => (
                <Text key={i}>{notificationSection.body}</Text>)
              )}
            </Text>
          </View>
        </TouchableOpacity>
      );
    }

    render() {
      var swipeableProps = (K.isWeb || true) ? ({
        style: {
          width: '100%',
          flexDirection: 'row',
          borderTopLeftRadius: K.borderRadius,
          borderBottomLeftRadius: K.borderRadius
        }
      }) : {
        renderRightActions: this.renderRightSwipeActions,
        renderLeftActions: this.renderLeftSwipeActions,
        onSwipeableRightOpen: this.markAsRead,
        onSwipeableLeftOpen: this.markAsRead,
        leftThreshold: 101,
        rightThreshold: 96,
        ref: this.getSwipeableRef,
        containerStyle: {
          width: '100%',
          flex: 1,
          borderRadius: K.borderRadius
        }
      };

      var SwipeableComponent = (K.isWeb || true) ? View : Swipeable;

      return (
        <View {...s.notificationContainer}>
          <SwipeableComponent {...swipeableProps}>
            {(K.isWeb || true) ? (
              <View style={{flexDirection: 'row', width: '100%', alignItems: 'stretch'}}>
                <View style={{flex: 1}}>
                  {this.renderNotificationLink()}
                </View>
                {/* <TouchableOpacity {...s.hideNotificationButton} onPress={this.markAsRead}>
                  <Image {...s.hideNotificationImage} source={hideIcon}/>
                </TouchableOpacity> */}
              </View>
            ) : (
              this.renderNotificationLink()
            )}
          </SwipeableComponent>
        </View>
      );
    }
  }

  Notification = NotificationComponent.connect(Notification, {
    mapDispatch: {
      ..._.pick(resourceActions.notifications, ['updateNotifications']),
    }
  });

  return Notification;
}
