
import * as Notifications from 'expo-notifications';
import Constants from 'expo-constants';

export default async function getExpoPushToken({appKey='protagonist'}={}) {
  var expoPushToken;

  try {
    if (Platform.OS !== 'web' && Constants.isDevice) {
      var {status: notificationStatus} = await Notifications.getPermissionsAsync();

      if (notificationStatus !== 'granted') {
        ({status: notificationStatus} = await Notifications.requestPermissionsAsync());

        // if (notificationStatus !== 'granted') {
        //   alert('Please go to Settings > Notifications > Protagonist and turn on Allow Notifications');
        // }
      }

      if (notificationStatus === 'granted') {
        expoPushToken = (await Notifications.getExpoPushTokenAsync({experienceId: `@symbolic-frameworks/${appKey === 'weflowLite' ? 'polydot' : appKey}`})).data;
      }
    }
  }
  catch (error) {
    console.error(error);
  }

  return expoPushToken;
}
