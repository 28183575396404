import { K } from '../../../styles';

var s = {};

s.billingPortal = {
  height: '100%',
  flex: 1,
  // justifyContent: 'center',
  // alignItems: 'center',
  // padding: K.spacing
};
  s.tiers = {
    // flexDirection: 'row'
  };
    s.tier = {
      alignItems: 'center',
      backgroundColor: K.colors.gray,
      borderRadius: K.borderRadius,
      flexDirection: 'row',
      padding: K.spacing
    };
      s.tierHeader = {
        flexDirection: 'row',
        alignItems: 'flex-end',
        paddingLeft: K.spacing,
        borderRadius: K.borderRadius,
        width: 250
      };
      s.title = {
        fontSize: K.calcFont(20),
        marginBottom: K.spacing,
        width: 120
      };
      s.price = {
        fontSize: K.calcFont(30),
        marginBottom: K.spacing,
        width: 50,
        textAlign: 'center',
      };
      s.dots = {
        alignItems: 'center',
        // justifyContent: 'center',
        flexDirection: 'column-reverse',
        height: 18 * 2 + K.margin
      };
        s.dot = {
          width: 18,
          height: 18,
          borderRadius: 9,
          backgroundColor: K.colors.doubleGray,
          marginHorizontal: K.margin / 2
        };
      s.features = {

      };
        s.feature = {
          textAlign: 'center',
          marginBottom: K.margin,
        };
  s.termsPrivacyContainer = {
    marginTop: K.spacing,
    marginBottom: K.spacing * 4,
    justifyContent: 'center',
    alignItems: 'center',
  };
    s.termsPrivacyLink = {
      opacity: 0.5
    };
  s.assignmentButton = {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderLeftWidth: 1,
    borderLeftColor: 'white',
  }

export default s;
