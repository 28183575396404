import React, { Component } from 'react';

import { View, Image } from 'react-native';
import { K } from '../../styles';
import { withSafeAreaInsets } from 'react-native-safe-area-context';

import Text from '../text/text';
import TextInput from '../text-input/text-input';
import PickerInput from '../picker-input/picker-input';
import LabelledView from '../labelled-view/labelled-view';
import Button from '../button/button';
import { withRouter } from 'react-router-native';

import lib, {api} from '@symbolic/lib';
import { connect, createOrg } from '@symbolic/redux';
import createIcon from '../../assets/create-icon-white.png';
import styleSpread from '../../style-spread';
import styles from './intro-questionnaire.styles';
import _ from 'lodash';

var s = styleSpread(styles);

class IntroQuestionnaire extends Component {
  state = {
    willCreateWorkspace: true,
    hasOutsideCollaborators: 1,
    hasInsideCollaborators: 1,
    workspaceTitle:'',
    inviteeEmails: [],
    questionIndex: 0,
    buttonId: 'next',
    inviteeInputValue: ''
  };

  componentDidUpdate() {
    if (this.props.session.isLoggedIn && this.hasExistingWorkspace === undefined) {
      this.hasExistingWorkspace = _.get(this.props.session, 'orgs.length') > 1;
    }
  }

  // functions that handle choices & switch DOM elements
  handleOutsideCollaboratorsChange = ({value}) => {
    this.setState({hasOutsideCollaborators: value});
  }

  handleInsideCollaboratorsChange = ({value}) => {
    this.setState({willCreateWorkspace: value === 1});

    this.setState({hasInsideCollaborators: value});
  }

  setWorkspaceTitle = (value) => {
    this.setState({workspaceTitle: value});
  }

  showNextButton = () => {
    this.setState({buttonId: 'next'});
  }

  showNextQuestion = ({questionIndex}) => {
    this.setState({buttonId: 'start'})

    if (questionIndex) {
      this.setState({questionIndex});
    }
    else if (this.state.hasInsideCollaborators === 0) {
      this.setState({questionIndex: 6});
    }
    else if (this.state.questionIndex === 1 && this.hasExistingWorkspace) {
      this.setState({questionIndex: 8});
    }
    else if (this.state.questionIndex === 3 ) {
      this.setState({questionIndex: 5});
    }
    else {
      this.setState({questionIndex: this.state.questionIndex + 1})
    }
  }

  inviteCollaborator = ()=> {
    this.setState({inviteeEmails: [this.state.inviteeInputValue, ...this.state.inviteeEmails]});
    this.setState({inviteeInputValue: ''});
  }

  skipToEnd = () => {
    if (this.state.questionIndex === 3){
      this.setState({questionIndex: 6});
    }
    else{
      this.setState({questionIndex: 6});
    }
  }

  back = () => {
    this.setState({questionIndex: this.state.questionIndex - 1});
  }

  done = async () => {
    if (this.state.willCreateWorkspace === true && !this.hasExistingWorkspace) {
      if (!this.isCreatingWorkspace) {
        this.isCreatingWorkspace = true;

        alert(`Ok we're creating your workspace now!\n\nYour team members will receive invite emails with accept links.`);

        await this.props.createOrg({props: {type: 'business', title: this.state.workspaceTitle}});

        setTimeout(async () => {
          var newOrg = _.last(this.props.session.orgs);

          await Promise.all(_.map(this.state.inviteeEmails, inviteeEmail => {
            return api.request({uri: '/orgs/invites/create', body: {orgId: newOrg.id, email: inviteeEmail, appKey: this.props.appKey}})
          }));

          this.props.onDone();
        }, 1000);
      }
    }
    else {
      this.props.onDone();
    }
  }

  render() {
    var maxInputWidth = 300;
    var {questionIndex} = this.state;

    return (
      <View {...s.introQuestionnaire}>
        <View {...s.logo}>
          <Text {...s.header}>Polydot</Text>
        </View>
        <View style={{flex: 1, alignSelf: 'center', alignItems: 'center', justifyContent: 'center', marginBottom: K.spacing * 4}}>
          {questionIndex === 0 && (
            <View style={{alignItems: 'center'}}>
              <LabelledView label='Does your work involve people outside your organization?' styles={{outerView: {maxWidth: maxInputWidth, marginTop: K.spacing, marginBottom: K.spacing}}, {label: {maxWidth: maxInputWidth, paddingBottom: K.spacing}}}>
                <PickerInput
                  basic inline
                  options={[{value: 1, title: 'Yes'}, {value: 0, title: 'Not currently'}]}
                  value={this.state.hasOutsideCollaborators}
                  onChange={this.handleOutsideCollaboratorsChange}
                />
              </LabelledView>
              <Image
                style={{marginTop: K.spacing * 4, width: 319 , height: 276}}
                source={require('./assets/questionnaire-graphic-1.png')}
              />
            </View>
          )}
          {questionIndex === 1 && (
            <View styles= {{alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%'}}>
              <Image
                style={{width: 296, height: 248}}
                source={require('./assets/questionnaire-graphic-2.png')}
              />
            </View>
          )}
          {questionIndex === 2 && (
            <View style={{alignItems: 'center'}}>
              <LabelledView label='Do you have an organization you might use Polydot with?' styles={{outerView: {marginTop: K.spacing, marginBottom: K.spacing}}, {label: {maxWidth: maxInputWidth, paddingBottom: K.spacing}}}>
                <Button
                  mode='dark'
                  style={{width: 'auto'}}
                  onPress={() => {
                    this.setState({willCreateWorkspace: true, hasInsideCollaborators: 1, questionIndex: this.state.questionIndex + 1})
                  }}
                  text='Yes'
                />
                <Button
                  style={{backgroundColor: K.colors.gray, width: 'auto', marginTop: K.margin}}
                  onPress={() => {
                    this.setState({willCreateWorkspace: false, hasInsideCollaborators: 0, questionIndex: 6});
                  }}
                  text='Not currently'
                />
                <Button
                  style={{backgroundColor: K.colors.gray, width: 'auto', marginTop: K.margin}}
                  onPress={() => {
                    this.setState({willCreateWorkspace: false});
                    this.showNextQuestion({questionIndex: 7});
                  }}
                  text='My team already set up a workspace'
                />
              </LabelledView>
              <Image
                style={{marginTop: K.spacing * 4, width: 319, height: 276}}
                source={require('./assets/questionnaire-graphic-3.png')}
              />
            </View>
          )}
          {questionIndex === 3 && (
            <View style={{alignItems: 'center', justifyContent: 'center', height:'100%'}}>
              <View>
                <TextInput
                  value={this.state.workspaceTitle}
                  placeholder='TEAM OR COMPANY NAME'
                  label='Name your Workspace'
                  style={{width: 300}}
                  onInput={this.showNextButton}
                  onChangeText={this.setWorkspaceTitle}
                />
              </View>
            </View>
          )}
          {questionIndex === 5 && (
            <View style={{alignItems: 'center', justifyContent: 'center', height:'100%'}}>
              <View style={{flexDirection: 'row', marginBottom: 20, position: 'relative'}}>
                <TextInput
                  value= {this.state.inviteeInputValue}
                  placeholder='EMAILS'
                  label='Invite your team'
                  style={{alignSelf:'center', width: 300}}
                  onChangeText={value => this.setState({inviteeInputValue: value})}
                />
                <Button icon={createIcon} style={{position: 'absolute', bottom: 0, right: 0, backgroundColor: K.colors.accent,borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderLeftWidth: 1, borderLeftColor: 'white'}} onPress={this.inviteCollaborator}/>
              </View>
              <View style={{justifyContent: 'start', paddingLeft: K.spacing, width:'100%'}}>
                {_.map(this.state.inviteeEmails, email => (
                  <Text style={{marginTop: K.spacing}}>{email}</Text>
                ))}
              </View>
            </View>
          )}
          {_.includes([6, 7, 8], questionIndex) && (
            <View style={{alignItems: 'center'}}>
              <Text style={{marginBottom: K.spacing, ...K.fonts.label, textAlign: 'center', maxWidth: maxInputWidth}}>
                {{
                  6: `No worries! FYI, Polydot is useful for:

Executing a Process
Collaborating on a project
Notifying your team about progress`,
                  7: `Great - ask your team to send you an invite so you share a workspace together!`,
                  8: `It looks like your team is already set up with a workspace.\n\nThat's ideal, because Polydot works best with a team.`
                }[questionIndex]}
              </Text>
              <Image
                style={{marginTop: K.spacing * 4, width: 319, height: 276 }}
                source={require('./assets/questionnaire-graphic-4.png')}
              />
            </View>
          )}
        </View>
        <View style={{position: 'absolute', bottom: K.spacing * 3, right: K.spacing * 3, alignSelf:'center', width:'95%'}}>
          {this.state.buttonId === 'next' && (
            <View style={{flexDirection:'row-reverse'}}>
              <Button
                mode='dark'
                onPress={this.showNextQuestion}
                label='Next'
              />
            </View>
          )|| questionIndex === 1 && (
            <View style={{flexDirection:'row-reverse'}}>
              <Button
                mode='dark'
                onPress={this.showNextQuestion}
                label='Next'
              />
            </View>
          )|| questionIndex === 3 && (
            <View style={{flexDirection:'row-reverse'}}>
              <Button
                mode='dark'
                onPress={this.skipToEnd}
                label='Skip'
              />
            </View>
          )|| _.includes([5, 6, 7, 8], questionIndex) && (
            <View style={{flexDirection:'row-reverse'}}>
              <Button
                mode='dark'
                onPress={this.done}
                label='Done'
              />
            </View>
          )}
        </View>
      </View>
    );
  }
}

export default withSafeAreaInsets(withRouter(connect({
  mapDispatch: {createOrg}
})(IntroQuestionnaire)))
