import {K} from '../../styles';

var s = {};

s.addCommentButton = {
  paddingLeft: K.spacing,
  marginVertical: K.spacing
};
s.addCommentText = {
  ...K.fonts.label,
  opacity: 0.5
};

export default s;
