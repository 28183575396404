import {K} from '../../styles';

var s = {};

s.popup = {
  height: '100%',
  width: '100%',
  position: 'fixed',
  top: 0,
  left: 0,
  borderWidth: 0
};
  s.background = {
    flex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    position: 'absolute',
    width: '100%',
    height: '100%'
  };
  s.pressOutsideButton = {
    flex: 1
  };
  s.window = {
    width: '100%',
    // borderRadius: K.borderRadius,
    backgroundColor: 'white',
    position: 'relative',
  };
    s.swipeIndicator = {
      borderRadius: 3,
      height: 6,
      backgroundColor: K.colors.gray,
      width: 50,
      alignSelf: 'center',
      position: 'absolute',
      top: K.spacing / 2,
    };

export default s;
