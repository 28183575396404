import React, { Component, Fragment } from 'react';
import { View, TouchableOpacity } from 'react-native';
import _ from 'lodash';
import { ScrollView, Image } from 'react-native';
import { connect, updateUserProps } from '@symbolic/redux';
import { withSafeAreaInsets } from 'react-native-safe-area-context';
import confirm from '../../../confirm.js';
import TextInput from '../../text-input/text-input';
import Button from '../../button/button';
import Text from '../../text/text';
import Label from '../../label/label';
import LabelledView from '../../labelled-view/labelled-view';
import PickerInput from '../../picker-input/picker-input';
import Tooltip from '../../tooltip/tooltip';
import TermsPrivacyPopup from '../../terms-privacy-popup/terms-privacy-popup';
import { api } from '@symbolic/lib';

import { K } from '../../../styles';
import styleSpread from '../../../style-spread';
import styles from './billing-portal.styles';

import protagonistIcon from './app-icons/protagonist-icon.png';
import weflowIcon from './app-icons/polydot-icon.png';
import suiteIcon from './app-icons/suite-icon.png';
import backIcon from '../../../assets/left-arrow-white.png';
import addIcon from '../../../assets/create-icon-white.png';
import checkIcon from '../../../assets/check-icon.png';
import removeIconWhite from '../../../assets/x-icon-white.png';
import removeIconBlack from '../../../assets/x-icon.png';

var metaDataMap = {
  allApps: {title: 'The Suite', icon: suiteIcon, userFriendlyProductKey: 'suite'},
  protagonist: {title: 'Protagonist', icon: protagonistIcon, userFriendlyProductKey: 'protagonist'},
  weflowLite: {title: 'Polydot', icon: weflowIcon, userFriendlyProductKey: 'polydot'}
};

var s = styleSpread(styles);

var getDisplayNameForUser = ({user, currentUserId}) => {
  var {id, firstName, lastName} = user;
  var displayName = '';

  if (currentUserId === id) {
    displayName = 'You';
  }
  else {
    displayName = firstName;

    if (lastName) displayName += ` ${lastName}`;
  }

  if (user.isCoveredByCurrentBillingGroup) displayName += ' (not currently in any shared orgs)';

  return displayName;
}

class BillingPortal extends Component {
  state = {
    managingSeatsForProductKey: null,
    isShowingTermsPrivacyPopup: false,
    billingGroupInviteeEmail: '',
    billingAdminSwapKey: 1,
    assigneeEmail: ''
  };

  async componentDidMount() {
    await this.resetUsers();
  }

  async resetUsers() {
    var usersData = await api.request({uri: '/get-users', body: {returnLicenseStatuses: true}});
    var users = _.get(usersData, 'data.users', []);
    var {user} = this.props.session;

    _.assign(user, _.find(users, {id: user.id}));

    var relevantOrgIds = _.pickBy(user.rolesByOrgId, role => !_.includes(['left', 'disabled'], role));

    users = _.filter(users, u => {
      var {rolesByOrgId} = u;
      var isShared = _.every(rolesByOrgId, (role, orgId) => !relevantOrgIds[orgId]);
      var isInOrg = false, isCoveredByCurrentBillingGroup = false;

      if (!isShared) {
        isInOrg = _.some(rolesByOrgId, role => !_.includes(['left', 'disabled'], role));

        if (!isInOrg && !_.isEmpty(u.licenseStatuses)) {
          isCoveredByCurrentBillingGroup = _.some(u.licenseStatuses, ({status, billingGroupId}) => (
            status === 'active' && billingGroupId === user.billingGroupId
          ));

          u.isCoveredByCurrentBillingGroup = isCoveredByCurrentBillingGroup;
        }
      }

      return isShared || isInOrg || isCoveredByCurrentBillingGroup;
    });

    this.props.trackUsers({users, reset: true});
  }

  componentWillUnmount() {
    if (this.purchaseUpdateSubscription) {
      this.purchaseUpdateSubscription.remove();
      this.purchaseUpdateSubscription = null;
    }
    if (this.purchaseErrorSubscription) {
      this.purchaseErrorSubscription.remove();
      this.purchaseErrorSubscription = null;
    }
  }

  proceedToCheckout = async ({productKey}) => {
    if (this.state[`${productKey}-promoCode`]) {
      var response = await api.request({uri: `/payments/free-subscription`, body: {
        promoCode: this.state[`${productKey}-promoCode`], productKey
      }});

      var {user} = response.data;

      this.trackUsers({users: [user]})

      this.setState({[`${productKey}-promoCode`]: ''});

      this.props.history.push(`/billing/seats/${metaDataMap[productKey].userFriendlyProductKey}`)
    }
    else {
      var results = await api.request({uri: '/payments/create-checkout-session', body: {
        successUrl: `${this.domain}/billing/seats/${metaDataMap[productKey].userFriendlyProductKey}`, cancelUrl: `${this.domain}/billing`, productKey
      }});

      var checkoutSessionId = _.get(results, 'data.checkoutSessionId');

      if (checkoutSessionId) {
        await sessionStore.set('checkoutSessionId', checkoutSessionId);

        var { loadStripe } = require('@stripe/stripe-js');

        var stripe = await loadStripe(
          process.env.NODE_ENV === 'production' ?
            'pk_live_51IbuXTIYr4UB19lTGy2F519B2jCG7eFSbSRY7W43Y5LDbo6OU1v5K5goTFQwEW1cKTdj92NcqCUlG7ovR0UNXrpB00YAJHKwVh' :
            'pk_test_51IbuXTIYr4UB19lTxv8KIhDwg36B3eUNPeHZLIosWBX4UKNnjxEatJvRf08xkq4KBiDDPqG8Hyv6aa43AmyNTnT500qRJofPi6'
        );

        stripe.redirectToCheckout({sessionId: checkoutSessionId});
      }
      else {
        alert('Something went wrong. Please try again or email support@symbolicframeworks.com.');
      }
    }
  }

  getPriceLabel = productKey => {
    var isFree = _.get(this.props, `session.user.licenseStatuses.${productKey}.isFree`, false) === true;
    var isPaid = _.get(this.props, `session.user.licenseStatuses.${productKey}.active`, false) === true;

    return isFree ? 'Free' : (isPaid ? 'Purchased' : (productKey === 'allApps' ? '$5' : '$4'));
  }

  assignSeat = async ({assigneeId, assigneeEmail}) => {
    var response = await api.request({uri: `/assign-seat`, body: {assigneeId, assigneeEmail, productKey: this.managingSeatsForProductKey}});

    var {user, billingGroup} = response.data;

    this.props.trackUsers({users: [user]});

    var props = {billingGroup};

    if (user.id === this.props.session.user.id) props.licenseStatuses = user.licenseStatuses;

    this.props.updateUserProps({props});

    if (assigneeEmail) this.setState({assigneeEmail: ''});
  }

  unassignSeat = async ({unassigneeUser}) => {
    var {user} = this.props.session;

    if (user.id === unassigneeUser.id || await confirm('Delete', `Are you sure you want to unassign the seat that ${getDisplayNameForUser({user: unassigneeUser})} is using?`)) {
      var response = await api.request({uri: `/unassign-seat`, body: {assigneeId: unassigneeUser.id, productKey: this.managingSeatsForProductKey}});

      var {user, billingGroup} = response.data;

      this.props.trackUsers({users: [user]});

      var props = {billingGroup};

      if (user.id === this.props.session.user.id) props.licenseStatuses = user.licenseStatuses;

      this.props.updateUserProps({props});
    }
  }

  addToBillingGroup = async () => {
    var {billingGroupInviteeEmail} = this.state;

    var response = await api.request({uri: `/add-to-billing-group`, body: {billingGroupInviteeEmail, appKey: APP_KEY}});

    var {user} = response.data;

    this.props.trackUsers({users: [user]});

    this.setState({billingGroupInviteeEmail: ''});
  }

  removeFromBillingGroup = async user => {
    var {user: activeUser} = this.props.session;
    var isActiveUser = activeUser.id === user.id;

    if (isActiveUser && !this.billingGroupSelfRemovalPermission) {
      alert(`You can't leave your billing group if you are the admin or you are the only member, and at least one seat is currently being paid for.`);
    }
    else if (await confirm('Delete', `Are you sure you want ${isActiveUser ? 'leave' : `to remove ${getDisplayNameForUser({user})} from`} your billing group?`)) {
      var response = await api.request({uri: `/remove-from-billing-group`, body: {idToRemove: user.id}});

      var {user} = response.data;

      this.props.trackUsers({users: [user]});
    }
  }

  handleChangeAdmin = async ({userId}) => {
    var user = this.props.users[userId];

    if (await confirm('Confirm', `Are you sure you want to make ${getDisplayNameForUser({user})} the admin of your billing group? You won't be the admin anymore.`)) {
      var response = await api.request({uri: `/change-billing-group-admin`, body: {newAdminId: userId, appKey: APP_KEY}});

      var {billingGroup} = response.data;

      this.props.updateUserProps({props: {billingGroup}});
    }
    else {
      this.setState({billingAdminSwapKey: this.state.billingAdmin++});
    }
  }

  handleCancelPress = () => {
    alert("Please email support@symbolicframeworks.com with your seat reduction request. Please note that we cannot reduce your seat subscription to any number less than the number currently assigned.");
  }

  get domain() {
    var url = {protagonist: 'https://protag.app', weflowLite: 'https://polydot.app'}[APP_KEY];

    return process.env.NODE_ENV === 'development' ? 'http://localhost:19006' : url;
  }

  get managingSeatsForProductKey() {
    return _.findKey(metaDataMap, ({userFriendlyProductKey: this.props.userFriendlyProductKey}));
  }

  get sortedUsers() {
    var {user} = this.props.session;

    return _.orderBy(this.props.users, [({id}) => id === user.id, 'orgId'], ['desc', 'desc']);
  }

  get billingGroupMembers() {
    var {user} = this.props.session;

    return user.billingGroupId ? _.filter(this.sortedUsers, ({billingGroupId}) => billingGroupId === user.billingGroupId) : [user];
  }

  get billingGroupSelfRemovalPermission() {
    var {user} = this.props.session;
    var {billingGroup} = user;

    return _.get(billingGroup, 'licensePurchases') && _.every(billingGroup.licensePurchases, purchases => (
      purchases === 0 || (billingGroup.adminUserId !== user.id && this.billingGroupMembers.length > 1)
    ));
  }

  render() {
    var {managingSeatsForProductKey, sortedUsers, billingGroupMembers} = this;
    var {user} = this.props.session;
    var {licensePurchases, licensedUserIds} = _.get(user, 'billingGroup', {});
    var billingGroupAdminId = _.get(user, 'billingGroup.adminUserId', user.id);

    var seatCountsByProductKey = _.mapValues(metaDataMap, (_value, productKey) => ({
      usedSeats: _.keys(_.get(licensedUserIds, productKey, {})).length,
      purchasedSeats: _.get(licensePurchases, productKey, 0)
    }));

    if (managingSeatsForProductKey) {
      var {usedSeats, purchasedSeats} = seatCountsByProductKey[managingSeatsForProductKey];

      var isOwned = u => (_.get(u, `licenseStatuses.${managingSeatsForProductKey}.billingGroupId`) === user.billingGroupId && _.get(u, `licenseStatuses.${managingSeatsForProductKey}.status`, 'inactive') === 'active');

      var ownedUsers = _.filter(sortedUsers, isOwned);
      var unownedUsers = _.reject(sortedUsers, isOwned);
    }

    var width = 440;
    var hasSeats = _.get(licensePurchases, 'polydot', 0) > 0;

    return !hasSeats ? (
      <View style={{justifyContent: 'center', alignItems: 'center', height: '100%'}}>
        {this.state.showingEmail ? (
          <Text style={{backgroundColor: K.colors.gray, borderRadius: K.borderRadius, padding: K.spacing}}>sales@symbolicframeworks.com</Text>
        ) : (
          <Text style={{backgroundColor: K.colors.gray, borderRadius: K.borderRadius, padding: K.spacing}} onClick={() => this.setState({showingEmail: true})}>Talk to us!</Text>
        )}
      </View>
    ) : (
      <View {...s.billingPortal}>
        <ScrollView style={{flex: 1, alignSelf: 'stretch'}} contentContainerStyle={{padding: K.spacing, alignItems: 'center', paddingVertical: K.spacing * 4}}>
          <Text style={{...K.fonts.pageHeader, marginBottom: K.spacing * 2, width}}>Billing Portal</Text>
          {!managingSeatsForProductKey && (<Fragment>
            {/* <Text style={{opacity: 0.8, textAlign: 'left', width: width, marginBottom: K.spacing}}>{`Subscriptions include:`}</Text> */}
            {_.map([
              {title: 'Sharing', description: 'Invite others to collaborate - they get free access to what you share with them'},
              {title: 'Workspaces', description: 'See your teams work easily'},
              {title: 'Trial', description: 'Free for 30 days'}
            ], ({title, description}) => (
              <View key={title} style={{opacity: 0.8, width: width, marginBottom: K.margin * 2, flexDirection: 'row', textTransform: 'uppercase'}}>
                <Label style={{width: 120, opacity: 1}}>{title}</Label>
                <Label style={{opacity: 0.7, width: width - 120, lineHeight: K.fonts.label.fontSize * 1.4}}>{description}</Label>
              </View>
            ))}
            <View style={{marginTop: K.spacing * 2}}>
              {_.map(_.map(['allApps', 'protagonist', 'weflowLite'], productKey => ({
                hasSeats: _.get(this.props, `session.user.billingGroup.licensePurchases.${productKey}`, 0) > 0,
                price: this.getPriceLabel(productKey),
                ...metaDataMap[productKey],
                productKey
              })), ({hasSeats, price, title, icon, productKey, userFriendlyProductKey}) => (
                !_.includes(this.props.hiddenProductKeys, productKey) && (<View key={title} style={{...styles.tier, marginTop: K.margin}}>
                  <View>
                    <Text style={{...styles.title, textAlign: 'center'}}>{title}</Text>
                    <View>
                      {!hasSeats ? (<Fragment>
                        <TextInput
                          onInput={({value}) => this.setState({[`${productKey}-promoCode`]: value})}
                          style={{width: 120, marginBottom: K.margin, textAlign: 'center'}}
                          value={this.state[`${productKey}-promoCode`]}
                          inputStyle={{backgroundColor: 'white'}}
                          placeholder='PROMO CODE'
                        />
                        <Button
                          label={this.state[`${productKey}-promoCode`] ? 'Use Code' : 'Add Seats'}
                          onPress={() => this.proceedToCheckout({productKey})}
                          style={{width: 120}}
                          mode='dark'
                        />
                      </Fragment>) : (<Fragment>
                        <Button
                          onPress={() => this.props.history.push(`/billing/seats/${userFriendlyProductKey}`)}
                          label='Manage Seats'
                          mode='dark'
                        />
                        <Label style={{marginTop: K.spacing, textAlign: 'center'}}>{`${seatCountsByProductKey[productKey].usedSeats} / ${seatCountsByProductKey[productKey].purchasedSeats} used`}</Label>
                      </Fragment>)}
                    </View>
                  </View>
                  <View style={{width: 170, alignItems: 'center'}}>
                    <Text style={{...styles.price}}>{price}</Text>
                    <Label style={{marginBottom: K.margin, opacity: 0.7, textAlign: 'center'}}>{`/seat /month`}</Label>
                    {_.get(user.licenseStatuses, `${productKey}.status`, 'inactive') === 'active' && (
                      <Label style={{marginVertical: K.margin}}>✓ Subscribed</Label>
                    )}
                  </View>
                  <Image style={{height: 100, width: 120, resizeMode: 'contain', alignItems: 'flex-end'}} source={icon}/>
                </View>)
              ))}
            </View>
            <View style={{width}}>
              <View style={{flexDirection: 'row', marginTop: K.spacing * 4, marginBottom: K.spacing * 1}}>
                <TextInput
                  inputStyle={{width: width - 1 - K.button.width, borderTopRightRadius: 0, borderBottomRightRadius: 0, marginRight: 1}}
                  onChange={({value}) => this.setState({billingGroupInviteeEmail: value})}
                  placeholder='INVITE SOMEONE TO HELP MANAGE BILLING BY EMAIL'
                  value={this.state.billingGroupInviteeEmail}
                />
                <Button icon={addIcon} mode='dark' style={{borderBottomLeftRadius: 0, borderTopLeftRadius: 0}} onPress={this.addToBillingGroup}/>
              </View>
              {billingGroupAdminId === user.id && this.billingGroupMembers.length > 1 && <LabelledView gray label='Transfer Admin Role' styles={{outerView: {marginBottom: K.spacing * 2}}}>
                <PickerInput
                  options={_.map(billingGroupMembers, u => ({value: u.id, title: `${getDisplayNameForUser({user: u, currentUserId: user.id})} (${u.email})`}))}
                  onChange={({value}) => this.handleChangeAdmin({userId: value})}
                  key={this.state.billingAdminSwapKey}
                  value={billingGroupAdminId}
                  showDownArrow
                />
              </LabelledView>}
              <View>
                {_.map(billingGroupMembers, u => {
                  var canRemove = !(u.id === user.id && !this.billingGroupSelfRemovalPermission);

                  return (
                    <View key={u.id} style={{flexDirection: 'row', height: K.button.height, alignItems: 'center', marginBottom: K.margin, backgroundColor: K.colors.gray, borderRadius: K.borderRadius}}>
                      <Text style={{marginLeft: K.spacing, flex: 1}}>{getDisplayNameForUser({user: u, currentUserId: user.id})} ({u.email})</Text>
                      {(billingGroupAdminId === user.id || user.id === u.id) && user.billingGroupId && <Button
                        style={{borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderLeftWidth: 1, borderLeftColor: 'white'}}
                        icon={canRemove ? removeIconWhite: removeIconBlack}
                        onPress={() => this.removeFromBillingGroup(u)}
                        mode={canRemove ? 'dark' : 'light'}
                      />}
                    </View>
                  )
                })}
              </View>
            </View>
            <Label style={{marginTop: K.spacing * 3, opacity: 0.7}}>support@symbolicframeworks.com</Label>
            <View {...s.termsPrivacyContainer}>
              <TouchableOpacity onPress={() => this.setState({isShowingTermsPrivacyPopup: true})}>
                <Label {...s.termsPrivacyLink}>Terms & Privacy</Label>
              </TouchableOpacity>
              {this.state.isShowingTermsPrivacyPopup && (
                <TermsPrivacyPopup onClose={() => this.setState({isShowingTermsPrivacyPopup: false})}/>
              )}
            </View>
          </Fragment>)}
          {managingSeatsForProductKey && (
            <View style={{width}}>
              <View style={{flexDirection: 'row', alignItems: 'center'}}>
                <Button mode='dark' icon={backIcon} style={{marginRight: K.margin}} onPress={() => this.props.history.push('/billing')}/>
                <Button mode='dark' label='Add Seats' onPress={() => this.proceedToCheckout({productKey: managingSeatsForProductKey})}/>
                {purchasedSeats > 0 && (
                  <Button
                    style={{marginLeft: K.margin}}
                    onPress={this.handleCancelPress}
                    label='Reduce seats'
                  />
                )}
                <Label style={{marginLeft: K.spacing}}>
                  {usedSeats}/{purchasedSeats} Used
                </Label>
              </View>
              <View style={{flexDirection: 'row', marginTop: K.spacing * 2, marginBottom: K.spacing * 4}}>
                <TextInput
                  inputStyle={{width: width - 1 - K.button.width, borderTopRightRadius: 0, borderBottomRightRadius: 0, marginRight: 1}}
                  onInput={({value}) => this.setState({assigneeEmail: value})}
                  placeholder='ASSIGN SEAT BY EMAIL'
                  value={this.state.assigneeEmail}
                />
                <Button
                  style={{borderBottomLeftRadius: 0, borderTopLeftRadius: 0}}
                  onPress={() => this.assignSeat({assigneeEmail: this.state.assigneeEmail})}
                  icon={addIcon} mode='dark'
                />
              </View>
              <View style={{marginBottom: K.spacing * 4}}>
                {_.map([
                  {users: ownedUsers, label: 'Assigned Seats'},
                  {users: unownedUsers, label: 'Other Users'},
                ], ({users, label}) => users.length > 0 && (
                  <View key={label}>
                    <LabelledView label={label} styles={{outerView: {marginBottom: K.spacing * 2}}}>
                      {_.map(users, otherUser => {
                        var {id, licenseStatuses, email} = otherUser;
                        var {status, billingGroupId} = _.get(licenseStatuses, `${managingSeatsForProductKey}`, {status: 'inactive'});
                        var otherAssigner = billingGroupId && (billingGroupId !== user.billingGroupId);
                        var canUnassign = !otherAssigner && status === 'active';

                        var tooltipText = 'Assign Seat';

                        if (otherAssigner) tooltipText = 'Covered by someone else';
                        if (canUnassign) tooltipText = 'Unassign Seat';

                        return (
                          <View key={id} style={{flexDirection: 'row', alignItems: 'center', marginBottom: K.margin, backgroundColor: K.colors.gray, borderRadius: K.borderRadius}}>
                            <Text style={{marginLeft: K.spacing, flex: 1}}>{getDisplayNameForUser({user: otherUser, currentUserId: user.id})} ({email})</Text>
                            <Tooltip text={tooltipText}>
                              <Button
                                style={{...s.assignmentButton, backgroundColor: otherAssigner ? K.colors.gray : 'black', opacity: otherAssigner ? 0.5 : 1, borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderLeftWidth: 1, borderLeftColor: 'white'}}
                                onPress={canUnassign ? () => this.unassignSeat({unassigneeUser: otherUser}) : () => this.assignSeat({assigneeId: id})}
                                icon={canUnassign ? removeIconWhite : (otherAssigner ? checkIcon : addIcon)}
                                disabled={otherAssigner}
                                mode='dark'
                              />
                            </Tooltip>
                          </View>
                        )
                      })}
                    </LabelledView>
                  </View>
                ))}
              </View>
            </View>
          )}
        </ScrollView>
      </View>
    );
  }
}

export default withSafeAreaInsets(connect({
  mapState: state => ({session: state.session, users: state.resources.users.byId}),
  mapDispatch: {updateUserProps}
})(BillingPortal));
