import React, { Component, Fragment } from 'react';

import { View, ScrollView, Keyboard, Platform, TouchableOpacity } from 'react-native';
import { connect, updateMyAccount } from '@symbolic/redux';
import { validation } from '@symbolic/lib';
import { K } from '../../../styles';
import { withSafeAreaInsets } from 'react-native-safe-area-context';

import Button from '../../button/button';
import Text from '../../text/text';
import Label from '../../label/label';
import LabelledView from '../../labelled-view/labelled-view';

import TextInput from '../../text-input/text-input';
import CheckboxInput from '../../checkbox-input/checkbox-input';

import styleSpread from '../../../style-spread';
import styles from './my-account-view.styles';
import _ from 'lodash';
import lib from '@symbolic/lib';
import ManageSubscriptionButton from '../../manage-subscription-button/manage-subscription-button';
import TermsPrivacyPopup from '../../terms-privacy-popup/terms-privacy-popup';
import DocumentTitle from '../../document-title/document-title';

var s = styleSpread(styles);

class MyAccountView extends Component {
  constructor(props) {
    super(props);

    var user = _.get(props, 'session.user');

    this.state = {
      saveText: 'save',
      keyboardIsShowing: false,
      isShowingTermsPrivacyPopup: false,
      userProps: {
        ..._.pick(user, ['id', 'hue', 'firstName', 'lastName', 'email', 'notificationSettings'])
      }
    };
  }

  componentDidMount() {
    Keyboard.addListener(Platform.OS === 'ios' ? 'keyboardWillShow' : 'keyboardDidShow', this.handleKeyboardShow);
    Keyboard.addListener(Platform.OS === 'ios' ? 'keyboardWillHide' : 'keyboardDidHide', this.handleKeyboardHide);
  }

  componentWillUnmount() {
    Keyboard.removeListener(Platform.OS === 'ios' ? 'keyboardWillShow' : 'keyboardDidShow', this.handleKeyboardShow);
    Keyboard.removeListener(Platform.OS === 'ios' ? 'keyboardWillHide' : 'keyboardDidHide', this.handleKeyboardHide);
  }

  handleKeyboardShow = () => this.setState({keyboardIsShowing: true});
  handleKeyboardHide = () => this.setState({keyboardIsShowing: false});

  handleInputChange({key, value}) {
    this.setState(({userProps}) => ({userProps: {...userProps, [key]: value}}));
  }

  handleNotificationSettingsChange({key, value}) {
    var {userProps} = this.state;

    userProps = _.set(userProps, `notificationSettings.general.${key}`, value);

    this.setState({userProps});
  }

  save = async () => {
    var {userProps} = this.state;

    var hasSomePasswordInput = userProps.newPassword;

    if (hasSomePasswordInput && !userProps.oldPassword) {
      this.setState({errorMessage: `please provide current password`});
    }
    else if (hasSomePasswordInput && userProps.newPassword.length < 8) {
      this.setState({errorMessage: `password must be at least 8 characters`});
    }
    else if (hasSomePasswordInput && !_.isEqual(userProps.newPassword, userProps.confirmedPassword)) {
      this.setState({errorMessage: `passwords don't match`});
    }
    else if (userProps.email && !validation.emailIsValid(userProps.email)) {
      this.setState({errorMessage: `please provide a valid email`});
    }
    else {
      this.setState({saveText: 'saving'});

      await this.props.updateMyAccount({
        ..._.pick(userProps, ['hue', 'firstName', 'lastName', 'email', 'notificationSettings']),
        oldPassword: userProps.oldPassword,
        password: userProps.newPassword
      });

      if (!_.get(this, 'props.session.error')) {
        this.setState({saveText: 'saved', errorMessage: undefined});

        setTimeout(() => {
          this.setState({saveText: 'save'});
        }, 1000);
      }
    }
  }

  handleHueChange = async ({value}) => {
    var hue = Math.floor(parseInt(value));

    if (hue && typeof(hue) === 'number' && hue > 0 && hue <= 360) {
      this.handleInputChange({key: 'hue', value: hue});
    }
    else {
      alert('Please enter a whole number between 1 and 360');

      this.handleInputChange({key: 'hue', value: null});
    }
  }

  render() {
    var {insets, session} = this.props;
    var {userProps, errorMessage, saveText} = this.state;
    var {user} = session;

    var errorMessage = errorMessage || _.get(this, 'props.session.error.message');

    return (
      <DocumentTitle title={`Account Settings - ${this.props.appName}`}>
        <View {...s.myAccountView}>
          {errorMessage && <View {...s.errorMessage}>
            <Text {...s.errorMessageText}>{errorMessage}</Text>
          </View>}
          <ScrollView
            style={{width: '100%', height: '100%'}}
            contentContainerStyle={{...(K.isWeb ? {maxWidth: 400, width: '100%', alignSelf: 'center'} : {}), padding: K.spacing, paddingBottom: K.spacing * 3, paddingLeft: K.insets(insets, 'left', K.spacing), paddingRight: K.insets(insets, 'right', K.spacing)}}
            keyboardShouldPersistTaps='handled'
          >
            <View style={{...styles.inputGroup, marginBottom: user.isGuest !== 1 ? styles.inputGroup.marginBottom : 0}}>
              <TextInput
                {...s.input}
                grayLabelledView
                label='first name'
                value={userProps.firstName}
                onChangeText={value => this.handleInputChange({key: 'firstName', value})}
              />
              <TextInput
                {...s.input}
                grayLabelledView
                label='last name'
                value={userProps.lastName}
                onChangeText={value => this.handleInputChange({key: 'lastName', value})}
              />
              <TextInput
                {...s.input}
                grayLabelledView
                autoCapitalize='none'
                label='email'
                value={userProps.email}
                onChangeText={value => this.handleInputChange({key: 'email', value})}
              />
            </View>
            {user.isGuest !== 1 && (<Fragment>
              <View style={{marginBottom: K.spacing * 2}}>
                <TextInput
                  {...s.input}
                  grayLabelledView
                  label='current password'
                  secureTextEntry
                  onChangeText={value => this.handleInputChange({key: 'oldPassword', value})}
                />
                <TextInput
                  {...s.input}
                  grayLabelledView
                  autoComplete="chrome-off" //HINT prevent autofill chrome email
                  label='new password'
                  secureTextEntry
                  onChangeText={value => this.handleInputChange({key: 'newPassword', value})}
                />
                <TextInput
                  {...s.input}
                  grayLabelledView
                  label='confirm password'
                  secureTextEntry
                  onChangeText={value => this.handleInputChange({key: 'confirmedPassword', value})}
                />
              </View>
            </Fragment>)}
            <View style={{marginBottom: K.spacing * 2}}>
              <LabelledView label='Custom User Color' gray styles={{innerView: {flexDirection: 'row'}}}>
                  <TextInput
                    {...s.input}
                    editable={this.isOwner}
                    value={userProps.hue || ''}
                    placeholder='ENTER A HUE: 1-360'
                    onChange={this.handleHueChange}
                    style={{borderTopLeftRadius: 0, borderTopRightRadius: 0, borderBottomRightRadius: 0, flex: 1}}
                  />
                  <View style={{width: K.inputHeight, height: K.inputHeight, borderBottomRightRadius: K.borderRadius, marginLeft: 1, backgroundColor: lib.colors.colorFor({user: userProps})}}/>
                </LabelledView>
            </View>
            <View>
                {_.map([{key: 'email', title: 'Email'}, /*{key: 'desktop', title: 'Desktop'}, */{key: 'mobile', title: 'Mobile'}], ({key, title}) => {
                  return (
                    <View key={key} style={{marginBottom: K.margin}}>
                      <CheckboxInput
                        onChange={({value}) => this.handleNotificationSettingsChange({key, value})}
                        value={_.get(userProps, `notificationSettings.general.${key}`)}
                        options={[{value: 1, title: 'on'}, {value: 0, title: 'off'}]}
                        labelStyles={{minWidth: 200}}
                        style={{marginBottom: 1}}
                        label={`${title} Notifications`}
                      />
                  </View>);
                })}
              </View>
            <View {...s.termsPrivacyContainer}>
              <TouchableOpacity onPress={() => this.setState({isShowingTermsPrivacyPopup: true})}>
                <Text {...s.termsPrivacyLink}>Terms & Privacy</Text>
              </TouchableOpacity>
              {this.state.isShowingTermsPrivacyPopup && (
                <TermsPrivacyPopup onClose={() => this.setState({isShowingTermsPrivacyPopup: false})}/>
              )}
            </View>
          </ScrollView>
          <View style={{
            ...styles.buttonContainer,
            ...(this.state.keyboardIsShowing ? {...K.shadow} : {paddingBottom: Platform.OS === 'ios' ? K.spacing * 2 : K.spacing}),
            paddingLeft: K.insets(insets, 'left', K.spacing), paddingRight: K.insets(insets, 'right', K.spacing)
          }}>
            <Button
              {...s.saveButton}
              textStyle={{color: 'white'}}
              label={saveText}
              onPress={this.save}
            />
          </View>
        </View>
      </DocumentTitle>  
    );
  }
}

export default withSafeAreaInsets(connect({
  mapState: ['session'],
  mapDispatch: {updateMyAccount}
})(MyAccountView));
