import {K} from '../../styles';

var s = {};

s.input = {
  borderBottomWidth: 0,
  backgroundColor: K.colors.gray,
  // paddingHorizontal: K.story.paddingHorizontal,
  // flex: 1,
  height: K.inputHeight,
  minHeight: K.isWeb ? K.inputHeight : 0,
  justifyContent: 'center',
  alignItems: 'center',
  borderColor: 'white'
};
  s.buttonStyle = {
    borderRadius: K.borderRadius,
    justifyContent: 'center',
    alignItems: 'center'
  };
  s.leftButtonStyle = {
    borderBottomLeftRadius: K.borderRadius,
    borderTopLeftRadius: K.borderRadius
  };
  s.rightButtonStyle = {
    borderBottomRightRadius: K.borderRadius,
    borderTopRightRadius: K.borderRadius
  };
  s.inlineContainer = {
    flexDirection: 'row',
  };
s.modalView = {
  justifyContent: 'flex-end',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)'
};
  s.tray = {
    backgroundColor: 'white'
  };


export default s;
