import { K } from '../../styles';

var s = {};

s.slide = {
  justifyContent: 'center',
  flex: 1,
  paddingBottom: K.spacing * 4
}
  s.slideText = {
    ...(K.isWeb ? K.fonts.pageHeader : {}),
    opacity: 0.6,
    textAlign: 'center',
    paddingHorizontal: K.spacing * 2
  };
  s.slideTitle = {
    ...s.slideText,
    opacity: 1
  };
  s.slideMedia = {
    marginVertical: K.isWeb ? K.spacing * 4 : K.spacing * 2,
    justifyContent: 'center',
    alignItems: 'center'
  };
  s.slideBody = {
    ...s.slideText
  };
s.slider = {};

  s.slider.style = {
  };
  s.slider.dotStyle = {
    width: 6,
    height: 6,
    backgroundColor: 'rgba(0, 0, 0, 0.2)'
  };
  s.slider.activeDotStyle = {
    backgroundColor: 'black',
    width: 8,
    height: 8
  }

export default s;
