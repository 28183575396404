import {K} from '../../../styles';

var s = {};

var smallGrayText = {
  fontSize: 13,
  opacity: 0.5,
  letterSpacing: 0.8,
}

s.authView = {
  backgroundColor: 'white',
  flex: 1,
  width: '100%',
  height: '100%',
  ...(K.isWeb ? {paddingVertical: K.spacing * 2} : {paddingTop: K.spacing * 3})
};
  s.logo = {
    marginHorizontal: K.spacing,
    alignSelf: 'center',
    alignItems: 'center',
    ...(K.isWeb ? {
      alignSelf: 'center',
      alignItems: 'center',
      width: 400
    } : {
      marginTop: 30,
      // alignSelf: 'flex-end',
      // alignItems: 'flex-end',
    })
  };
    s.header = {
      ...K.fonts.label,
      lineHeight: 20 * 1.2,
      fontSize: 20,
      opacity: 1,
      // textAlign: 'right',
      marginBottom: 10,
      letterSpacing: 4
    };
    s.subheader = {
      ...smallGrayText,
      // textAlign: 'right',
      marginBottom: 10
    };
  s.appDescription = {
    marginTop: K.spacing,
    marginBottom: K.spacing * 4,
    marginHorizontal: K.spacing,
    textAlign: 'center',
    fontSize: 14,
    lineHeight: 20,
    alignSelf: 'center',
    alignItems: 'center',
    ...(K.isWeb ? {
      alignSelf: 'center',
      alignItems: 'center',
      width: 400
    } : {
      // alignSelf: 'flex-end',
      // alignItems: 'flex-end',
      // textAlign: 'right',
    })
  };
    s.scrollView = {
      width: '100%',
      height: '100%'
    };
      s.scrollViewContent = {
        ...(K.isWeb ? {justifyContent: 'center', minHeight: '100%'} : {paddingBottom: K.spacing * 4})
        // justifyContent: 'flex-end',
        // paddingHorizontal: K.spacing
      };
        s.inputs = {
          ...(K.isWeb ? {
            width: 250 + K.calc(100) + K.spacing * 2
          } : {}),
          width: 250,
          alignSelf: 'center',
          // ...(K.isWeb ? {left: -1 * (K.calc(50) + K.spacing)} : {})
        };
    s.labelledViewStyles = {
      outerView: {
        flexDirection: 'row',
        alignItems: 'center',
        alignSelf: 'center',
        justifyContent: 'center',
        // height: K.inputHeight + K.spacing,
        width: '100%',
        // paddingTop: 0,
        marginBottom: 1,
        width: 250,
        position: 'relative'
        // paddingRight: 0
      },
      label: {
        position: 'absolute',
        // minWidth: K.calc(100),
        top: 5,
        left: 0,
        width: '100%',
        // left:
        // textAlign: 'right',
        opacity: 0.7
      },
      innerView: {
        flex: 1,
        paddingTop: 0
      }
    }
    s.input = {
      width: '100%',
      paddingTop: K.spacing + 5,
      paddingBottom: 2,
      height: K.inputHeight + 10,
      ...(K.isWeb ? {} : {
        // borderTopRightRadius: 0,
        // borderBottomRightRadius: 0
      })
    };
    s.linksContainer = {
      marginTop: K.spacing * 2,
      width: 250,
      alignSelf: 'center',
      // ...(K.isWeb ? {
      //   alignSelf: 'center',
      // } : {
      //   alignSelf: 'flex-end'
      // })
    };
      s.link = {
        width: '100%',
        backgroundColor: K.colors.gray,
        //alignSelf: 'center'
        // ...(K.isWeb ? {} : {borderTopRightRadius: 0, borderBottomRightRadius: 0})
      };
        s.linkText = {
          opacity: 1
          //...smallGrayText,
        };
      s.termsPrivacyContainer = {
        marginTop: K.spacing * 6,
        justifyContent: 'center',
        alignItems: 'center',
      };
        s.termsPrivacyLink = {
          opacity: 0.3
        };
    s.errorMessage = {
      marginTop: K.spacing * 2,
      marginBottom: K.margin,
      minHeight: K.spacing,
    };
      s.errorMessageText = {
        ...smallGrayText,
        opacity: 0.8,
        alignSelf: 'center',
        color: 'red',
        textAlign: 'center',
        ...(K.isWeb ? {
          alignSelf: 'center',
          alignItems: 'center',
          width: 400
        } : {})
      };
    s.button = {
      // flexGrow: 1,
      // // height: 60,
      // alignItems: 'center',
      // justifyContent: 'center',
      backgroundColor: 'black',
      width: '100%'
      // alignItems: 'center'
    };
      s.buttonText = {
        color: 'white',
        opacity: 1
      };

export default s;
