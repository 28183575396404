import { LayoutAnimation, Platform, UIManager } from 'react-native';

var prepareToAnimate = (config) => {
  LayoutAnimation.configureNext({
    duration: 300,
    create: {
      type: LayoutAnimation.Types.easeInEaseOut,
      property: LayoutAnimation.Properties.opacity,
    },
    update: {
      type: LayoutAnimation.Types.easeInEaseOut,
    },
    ...config
  });
}

// if (Platform.OS === 'android') prepareToAnimate = () => false;

if (Platform.OS === 'android') {
  if (UIManager.setLayoutAnimationEnabledExperimental) {
    UIManager.setLayoutAnimationEnabledExperimental(true);
  }
}

export default prepareToAnimate;
