import { K } from '../../../styles';

var s = {};

s.processTypePicker = {
  width: '100%',
  maxWidth: 400
};
    s.processTypeCategories = {

    };
      s.processTypeCategory = {
        marginTop: K.margin,
        backgroundColor: K.colors.gray,
        borderRadius: K.borderRadius,
        minHeight: K.button.height,
        justifyContent: 'center'
      };
        s.categoryTitle = {
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
          paddingHorizontal: K.spacing,
          height: K.button.height,
          backgroundColor: '#98a0b8',
          borderRadius: K.borderRadius
        };
          s.categoryTitleTextContainer = {
            zIndex: 1,
          };
            s.categoryTitleText = {
              // ...K.fonts.su,
              // fontSize: K.calc(15),
              // fontWeight: 'bold',
              opacity: 1,
              color: 'white',
            };
          s.categoryTitleLine = {
            flex: 1,
            borderBottomWidth: 1,
            opacity: 0.1
          };
          s.subCategoryContainer = {
            marginTop: K.spacing
          };
            s.subCategoryTitle = {
              ...K.fonts.su,
              fontSize: K.calc(13),
              marginBottom: K.margin,
              alignSelf: 'center',
              fontWeight: 'bold'
            };
      s.processTypes = {
        marginBottom: K.spacing,
        marginHorizontal: K.spacing
      };
        s.processType = {
          flex: 1,
          paddingVertical: K.margin,
          paddingHorizontal: K.spacing,
          backgroundColor: 'black',
          borderRadius: K.borderRadius,
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: K.button.height,
        };
          s.processTypeTitle = {
            ...K.fonts.standard,
            textTransform: 'none',
            color: 'white',
          };

export default s;
