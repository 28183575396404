import React, { Component } from 'react';
import { View } from 'react-native';
import { K } from '../../styles';

import Popup from '../popup/popup';
import ScrollView from '../scroll-view/scroll-view';
import Text from '../text/text';
import Button from '../button/button';

import Markdown from 'react-native-easy-markdown';
import styleSpread from '../../style-spread';
import styles from './terms-privacy-popup.styles';

import termsOfServiceMarkdownString from './terms-of-service';
import privacyPolicyMarkdownString from './privacy-policy';

var s = styleSpread(styles);

class TermsPrivacyPopup extends Component {
  render() {
    return (
      <Popup height={500} onClose={this.props.onClose} disableSwipeToClose={true}>
        {this.props.onAccept && (
          <View {...s.buttons}>
            <Button {...s.cancelButton} label={'Cancel'} onPress={this.props.onClose}/>
            <Button {...s.acceptButton} label={'Accept'} textStyle={{color: 'white'}} onPress={this.props.onAccept}/>
          </View>
        )}
        <View {...s.docContainer}>
          <Text {...s.docTitle}>Terms of Service</Text>
          <ScrollView {...s.docContent}>
            <Markdown>{termsOfServiceMarkdownString}</Markdown>
          </ScrollView>
        </View>
        <View style={{...styles.docContainer, marginTop: K.spacing}}>
          <Text {...s.docTitle}>Privacy Policy</Text>
          <ScrollView {...s.docContent}>
            <Markdown>{privacyPolicyMarkdownString}</Markdown>
          </ScrollView>
        </View>
      </Popup>
    );
  }
}

export default TermsPrivacyPopup;
