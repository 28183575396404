import React, { Component } from 'react';

import Text from '../../text/text';
import confirm from '../../../confirm';
import Button from '../../button/button';
import styleSpread from '../../../style-spread';
import { View, TouchableOpacity, ScrollView, Image } from 'react-native';
import { api } from '@symbolic/lib';
import { K } from '../../../styles';

import _ from 'lodash';
import standardTemplateCategories from './templates';
import styles from './template-picker.styles';
import deleteIcon from '../../../assets/x-icon-white.png';

var s = styleSpread(styles);

class TemplateCategory extends Component {
  state = {isExpanded: false};

  async deleteTemplate({id}) {
    if (await confirm('', 'Are you sure you want to delete this template? It will be removed for everyone in the workspace.')) {
      this.props.destroyDecisionTemplate({id});
    }
  }

  render() {
    var {templateCategory} = this.props;
    var {isExpanded} = this.state;

    return (
      <View {...s.templateCategory} key={templateCategory.title}>
        <TouchableOpacity
          style={{
            ...styles.categoryTitle,
            ...(isExpanded ? {borderBottomRightRadius: 0, borderBottomLeftRadius: 0} : {}),
            ...(templateCategory.color ? {backgroundColor: templateCategory.color} : {})
          }}
          onPress={() => this.setState({isExpanded: !isExpanded})}
        >
          <View {...s.categoryTitleTextContainer}>
            <Text style={{...styles.categoryTitleText, textAlign: this.props.isCentered ? 'center' : 'left'}}>{templateCategory.title}</Text>
          </View>
        </TouchableOpacity>
        {isExpanded && (
          <ScrollView>
            {templateCategory.content.templates ? (
              <View {...s.templates}>
                {_.map(templateCategory.content.templates, (template) => (
                  <TouchableOpacity
                    key={template.title}
                    onPress={() => this.props.onSelect({template})}
                    {...s.template}
                  >
                    <Text style={{...styles.templateTitle, textAlign: this.props.isCentered ? 'center' : 'left'}}>{template.title}</Text>
                  </TouchableOpacity>
                ))}
              </View>
            ) : (
              <View>
                {_.map(templateCategory.content.subCategories, (subCategory) => (
                  <View {...s.subCategoryContainer} key={subCategory.title}>
                    <Text {...s.subCategoryTitle}>{subCategory.title}</Text>
                    <View {...s.templates}>
                      {_.map(subCategory.templates, (template) => (
                        <View style={{flexDirection: 'row', marginTop: K.margin}}>
                          <TouchableOpacity
                            key={template.title}
                            onPress={() => this.props.onSelect({template})}
                            style={{...styles.template, ...(template.isDeletable ? {borderTopRightRadius: 0, borderBottomRightRadius: 0} : {})}}
                          >
                            <Text style={{...styles.templateTitle, textAlign: this.props.isCentered ? 'center' : 'left'}}>{template.title}</Text>
                          </TouchableOpacity>
                          {template.isDeletable && (
                            <Button
                              style={{backgroundColor: 'black', borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderLeftWidth: 1, borderLeftColor: 'rgba(255, 255, 255, 0.5)'}}
                              onPress={() => this.deleteTemplate({id: template.id})}
                            >
                              <Image source={deleteIcon} style={{width: K.calc(20), height: K.calc(20)}}/>
                            </Button>
                          )}
                        </View>
                      ))}
                    </View>
                  </View>
                ))}
              </View>
            )}
          </ScrollView>
        )}
      </View>
    );
  }
}

class TemplatePicker extends Component {
  createDecisionFromTemplate = async ({template}) => {
    var {processId, processStepId, beforeCreateDecision, afterCreateDecision, logAnalyticsEvent, session, sessionStore} = this.props;

    if (beforeCreateDecision) beforeCreateDecision();

    var {title, factorTitles=null, factorWeights=[], factorNotes=[]} = template;

    if (!title) {
      title = '';

      logAnalyticsEvent('blank_decision_created');
    }
    else {
      logAnalyticsEvent('template_decision_created');
    }

    sessionStore.set('hasCreatedDecision', true);

    var decision = await api.create('decision', {
      title,
      orgId: session.activeOrg.id,
      ownerId: session.user.id,
      status: 'brainstorming',
      wasModified: processId ? 1: 0,
      collaborationMode: 'manyPrivateSheets',
      processInstanceId: processId || null,
      processStepId: processStepId || null
    });

    if (factorTitles) {
      await Promise.all(_.map(factorTitles, async (title, index) => {
        var weight = factorWeights[index] || 1;

        await api.create('decisionFactor', {title, decisionId: decision.id, weight, notes: factorNotes[index] || '', orgId: decision.orgId})
      }));

      await api.create('decisionOptions', _.map(template.optionTitles, title => ({title: title, decisionId: decision.id, orgId: decision.orgId})));
    }

    var decisionSheet = await api.create('decisionSheet', {
      title: 'Main Perspective',
      userId: session.user.id,
      editableBy: session.user.id,
      decisionId: decision.id,
      orgId: decision.orgId,
      isMasterSheet: true
    });

    await this.props.trackDecisionSheets({decisionSheets: [decisionSheet]});
    await this.props.trackDecisions({decisions: [decision]});

    if (afterCreateDecision) afterCreateDecision({decision});
  }

  handleTemplateSelect = ({template}) => {
    this.createDecisionFromTemplate({template});
  }

  render() {
    var {destroyDecisionTemplate, usersById, session={}} = this.props;
    var {user, activeOrg={}, orgs} = session;
    var templateCategories = [...standardTemplateCategories];

    _.forEach(_.sortBy(orgs, type => type === 'personal' ? 0 : 1), org => {
      var decisionTemplates = _.filter(this.props.decisionTemplates, {orgId: org.id});

      if (decisionTemplates.length) {
        templateCategories = [{
          title: `${org.type === 'personal' ? 'My' : org.title} Templates`,
          color: '#676d82',
          content: {
            subCategories: [
              {
                title: '',
                templates: _.map(decisionTemplates, template => ({
                  ...template,
                  factorTitles: _.map(template.factorsData, 'title'),
                  factorWeights: _.map(template.factorsData, 'weight'),
                  factorNotes: _.map(template.factorsData, 'notes'),
                  isDeletable: activeOrg.role === 'owner' || template.ownerId === user.id,
                  optionTitles: ['', '']
                }))
              }
            ]
          }
        }, ...templateCategories];
      }
    });

    return (
      <View {...s.templatePicker}>
        {this.props.showBlank && (
          <View style={{marginBottom: K.spacing * 2}}>
            <TouchableOpacity onPress={() => this.handleTemplateSelect({template: {title: '', factorTitles: ['', '', ''], factorWeights: [1, 1, 1]}})} {...s.template}>
              <Text {...s.templateTitle}>create a blank/custom decision</Text>
            </TouchableOpacity>
            <Text style ={{marginTop: K.spacing * 2, alignSelf: 'center'}}>OR</Text>
          </View>
        )}
        <ScrollView>
          <Text style={{opacity: 0.5, textAlign: 'center', marginBottom: this.props.showBlank ? 0 : K.spacing, marginHorizontal: K.spacing * 2}}>{this.props.showBlank ? 'Start with a template' : 'Choose a template to get familiar with the app.\n\nYou\'ll be able to create decisions from scratch later.'}</Text>
          {_.map(templateCategories, templateCategory => (
            <TemplateCategory
              {...{templateCategory, destroyDecisionTemplate, usersById}}
              onSelect={this.handleTemplateSelect}
              key={templateCategory.title}
              isCentered={true}
            />
          ))}
          <Text style={{opacity: 0.5, textAlign: 'center', marginTop: K.spacing, marginHorizontal: K.spacing * 2}}>{`Create custom templates by viewing a decision > settings gear icon > save as a reusable template`}</Text>
        </ScrollView>
      </View>
    );
  }
}

export default TemplatePicker;
