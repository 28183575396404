import React from 'react';
import ReactDOM from 'react-dom';
import { Modal as RNModal, Platform } from 'react-native';

export default function Modal(props) {
  if (Platform.OS === 'web') {
    return ReactDOM.createPortal((
      <RNModal
        {...props}
        style={{
          ...props.style,
          height: '100%',
          width: '100%',
          position: 'fixed',
          top: 0,
          left: 0,
          borderWidth: 0
        }}
      />
    ), document.getElementById('root'));
  }
  else {
    return <RNModal {...props}/>;
  }
}
