import React, { Component } from 'react';
import { MentionInput, replaceMentionValues } from '@symbolic/react-native-controlled-mentions';
import { Platform, ScrollView, View, TouchableOpacity } from 'react-native';

import TextInput from '../../text-input/text-input';
import Text from '../../text/text';
import styleSpread from '../../../style-spread';
import Button from '../../button/button';
import transferIcon from '../../../assets/transfer-icon-white.png';
import CheckboxInput from '../../checkbox-input/checkbox-input';
import {K} from '../../../styles';
import _ from 'lodash';

import styles from './comment-popup.styles';

var s = styleSpread(styles);

export default function commentPopupClassFor({connect, resourceActions, appKey}) {
  class CommentPopup extends Component {
    state = {
      commentBody: '',
      notifyAllCollaborators: 1
    };

    constructor(props) {
      super(props);

      this.create = this.create.bind(this);
      this.renderSuggestions = this.renderSuggestions.bind(this);
    }

    renderSuggestions({keyword, onSuggestionPress}) {
      if (keyword == null || keyword == undefined) return null;

      return (
        <ScrollView keyboardShouldPersistTaps='handled' style={{maxHeight: 125, backgroundColor: 'black', borderRadius: K.borderRadius, zIndex: 1, ...(Platform.OS !== 'android' ? {position: 'absolute', bottom: '100%', width: '100%'} : {})}}>
          {this.suggestions
            .filter(one => one.name && one.name.toLocaleLowerCase().includes(keyword.toLocaleLowerCase()))
            .map(one => (
              <TouchableOpacity
                key={one.id}
                onPress={() => onSuggestionPress(one)}
                style={{...K.button, marginBottom: 1, paddingHorizontal: K.spacing, width: 'auto', alignItems: 'flex-start'}}
              >
                <Text style={{color: 'white'}}><Text style={{opacity: 0.5, color: 'white'}}>@</Text>{one.name || ''}</Text>
              </TouchableOpacity>
            ))
          }
        </ScrollView>
      );
    }

    create() {
      if (this.state.commentBody) {
        var {resourceId, type, creationProps, onCreate} = this.props;
        var {commentBody, notifyAllCollaborators} = this.state;

        var commentProps = {
          orgId: this.props.session.activeOrg.id,
          body: _.trim(commentBody),
          authorUserId: this.props.session.user.id,
          data: _.pick(this.props, ['decisionOptionId', 'decisionOptionFactorId', 'processInstanceStepId']),
          resourceId,
          type,
          notifyAllCollaborators,
          ...creationProps
        };

        this.props.createComment({
          props: commentProps
        });

        this.setState({commentBody: ''});

        if (onCreate) onCreate({notifyAllCollaborators, hasMentionedUsers: _.get(commentBody.match(/({{@user\d*}})/g), 'length', 0) > 0});
      }

      this.props.hideCommentPopup();
    }

    handleCommentChange(value) {
      value = replaceMentionValues(value, ({id}) => `{{@user${id}}}`);

      this.setState({commentBody: value});
    }

    get suggestions() {
      var users = this.props.usersById;

      if (process.env.NODE_ENV === 'production') {
        users = _.reject(users, {id: this.props.session.userId});
      }

      return _.map(users, user => {
        return {id: user.id, name: user.firstName}
      });
    }

    get displayValue() {
      return this.state.commentBody ? _.replace(this.state.commentBody, /{{@user(\d*)}}/g, (mention) => {
        var userId = _.replace(mention, /\D/g, '');
        var user = _.get(this.props.usersById, `[${userId}]`);

        return user ? `@[${user.firstName}](${user.id})` : mention;
      }) : '';
    }

    render() {
      return (
        <View style={{marginBottom: K.margin}}>
          <View style={{zIndex: 1, flexDirection: 'row'}}>
            <View style={{flex: 1}}>
              <MentionInput
                component={TextInput}
                multiline
                autoFocus={_.get(this, 'props.autoFocus', true)}
                isInsertSpaceAfterMention
                style={{...styles.commentInput, minHeight: 50, position: 'relative'}}
                placeholder={this.props.placeholder || 'LEAVE A COMMENT...'}
                value={this.displayValue || ''}
                onChange={(value) => this.handleCommentChange(value)}
                partTypes={[{trigger: '@', renderSuggestions: this.renderSuggestions, textStyle: {fontWeight: 'bold'}}]}
              />
            </View>
            {Boolean(this.state.commentBody) && (
              <View {...s.footer}>
                <View style={{...styles.footerRightContent, marginRight: this.props.type === 'story' ? 15 : 0}}>
                  <Button {...s.addCommentButton} onPress={this.create} icon={transferIcon}/>
                </View>
              </View>
            )}
          </View>
          {this.state.commentBody !== '' && appKey === 'weflowLite' && !!this.props.isPublished && (
            <View style={{marginTop: K.margin}}>
              <CheckboxInput onChange={({value}) => this.setState({notifyAllCollaborators: value})} label='Notify all collaborators' value={this.state.notifyAllCollaborators}/>
            </View>
          )}
        </View>
      );
    }
  }

  CommentPopup = connect({
    mapState: (state, ownProps) => ({
      session: state.session,
      usersById: {..._.keyBy(ownProps.additionalUsers, 'id')}
    }),
    mapDispatch: {
      ..._.pick(resourceActions.comments, ['createComment']),
    }
  })(CommentPopup);

  return CommentPopup;
}
