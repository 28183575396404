import {K} from '../../../styles';

var s = {};

s.myAccountView = {
  height: '100%',
  width: '100%',
  display: 'flex',
  flex: 1,
  position: 'absolute',
  backgroundColor: 'white'
}
  s.errorMessage = {
    paddingVertical: 15,
    height: 45,
    alignSelf: 'flex-end',
    marginRight: 20
  };
    s.errorMessageText = {
      ...K.fonts.sl,
      color: '#eb6c6c'
    }

  s.inputGroup = {
    marginBottom: 30,
  };
    s.inputRow = {
      flexDirection: 'row',
    }
      s.input = {

      };
      s.inputContainer = {
        outerView: {paddingTop: K.spacing, flex: 1}
      }
      s.leftInputContainer = {
        ...s.inputContainer,
        outerView: {...s.inputContainer.outerView, marginRight: K.margin}
      }
      s.fullLineInputContainer = {
        ...s.inputContainer
      }
    s.termsPrivacyContainer = {
      marginTop: K.spacing * 1,
      justifyContent: 'center',
      alignItems: 'center',
    };
      s.termsPrivacyLink = {
        opacity: 0.3
      };
    s.buttonContainer = {
      backgroundColor: 'white',
      padding: K.spacing,
      alignItems: 'center'
    };
      s.saveButton = {
        backgroundColor: 'black',
        maxWidth: 400,
        width: '100%'
      };


export default s;
