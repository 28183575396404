var decisionTemplateCategories = [
  {title: 'Business Decision Templates', content: {
    subCategories: [
      {title: 'Team Building', templates: [
        {title: 'hiring the right executive',
          key: 'team-executive',
          factorTitles: [
            'Leadership Experience',
            'Team development',
            'Strategic planning',
            'Results',
            'Resilience',
            'Communication',
            'Related experience',
            'Integrity'
          ],
          optionTitles: ['Candidate 1', 'Candidate 2']
        },
        {title: 'post-pandemic workplace options',
          key: 'post-pandemic-workplace-options',
          factorTitles: [
            'Cost Savings',
            'Strengthen Culture',
            'Job Appeal',
            'Client Service',
            'Productivity',
            'Pos. impact on training',
          ],
          optionTitles: [
            '100% in office',
            '50/50 hybrid',
            '80/20 hybrid',
            '100% remote',
            '1 wk/mth at home'
          ]
        },
        {title: 'most impactful staff benefits',
          key: 'most-impactful-staff-benefits',
          factorTitles: [
            '% of staff impacted',
            'Appeal to staff',
            'Long term impact',
            'Ease of Administration',
            'Affordability',
            'Benefit to business'
          ],
          optionTitles: [
            'Hybrid Office/Home Schedule',
            'Professional Development',
            'Additional Vacation',
            'Improved Health Care',
          ]
        },
        // {title: 'hiring: select the right specialist',
        //   key: 'hiring-specialist',
        //   factorTitles: [
        //     'Relevant skill level',
        //     'Ability to Complete Independently',
        //     'Depth of Experience',
        //     'Drive, Energy, Work Ethic',
        //     'Teamwork Experience',
        //     'Project Management',
        //     'Communication',
        //     'Integrity'
        //   ],
        //   optionTitles: ['Candidate 1', 'Candidate 2']
        // },
        // {title: 'hiring: select the right intern',
        //   key: 'hiring-intern',
        //   factorTitles: [
        //     'education alignment',
        //     'excelled previously',
        //     'references',
        //     'communication',
        //     'enthusiasm'
        //   ],
        //   optionTitles: ['Intern 1', 'Intern 2', 'Intern 3']
        // },
        {title: 'choosing the best partnerships',
          key: 'team-partnerships',
          factorTitles: [
            'clear mutual benefit',
            'brand association',
            'ability to execute',
            'potential to grow sales',
            'potential to expand product or service',
            'longevity potential',
            'reward/effort ratio'
          ],
          optionTitles: ['Partner 1', 'Partner 2', 'Partner 3']
        },
        {title: 'promoting the right people',
          key: 'team-promotions',
          factorTitles: [
            'Respect of Team',
            'Leadership mindset',
            'Clarity of communication',
            'Quality of relationships',
            'Vision',
            'Accountability for results'
          ],
          optionTitles: ['Team Member 1', 'Team Member 2', 'Team Member 3']
        },
        {title: 'training for the right skills',
          key: 'team-training',
          factorTitles: [
            'Impact on workflow',
            'Increase in Morale',
            'Increase in Quality',
            'Increase in Collaboration',
            'Increase in Sales',
            'Increase in Margin'
          ],
          optionTitles: ['Team Member 1', 'Team Member 2', 'Team Member 3']
        },
      ]},
      {title: 'Products and Marketing', templates: [
        {title: 'where to advertise or market',
          key: 'products-marketing-where-to-market',
          factorTitles: [
            'precision of targeting',
            'impressions per dollar',
            'proximity to decision',
            'ability to generate repeat impressions',
            'brand esteem value',
            'awareness',
            'lead generation',
          ],
          optionTitles: ['Publication 1', 'Publication 2']
        },
        {title: 'which new products/services',
          key: 'products-marketing-which-products',
          factorTitles: [
            'client experience impact',
            'sales potential',
            'marketing leverage',
            'distinguishes company',
            'margin potential',
            'lifecycle',
          ],
          optionTitles: ['Product 1', 'Product 2']
        },
        {title: 'which new market to enter',
          key: 'products-marketing-which-market',
          factorTitles: [
            'size of market',
            'ability to compete',
            'leverate via existing customer base',
            'brand value',
            'low barriers to entry',
            'ability to minimize risk',
            'cultural fit'
          ],
          optionTitles: ['Market 1', 'Market 2']
        }
      ]},
      {title: 'Planning and Operations', templates: [
        {title: 'capital investments: which ones',
          key: 'planning-operations-capital-investments',
          factorTitles: [
            'sales growth',
            'margin increase',
            'low impact of fixed costs',
            'return on investment',
            'short ROI timeframe',
            'impact on staff',
            'impact on clients',
            'likelihood of success'
          ],
          optionTitles: ['Investment 1', 'Investment 2']
        },
        {title: 'strategic planning: which initiatives',
          key: 'planning-operations-initiatives',
          factorTitles: [
            'leverage: effort/reward ratio',
            'impact on sales growth',
            'impact on margin',
            'impact on future client engagement',
            'impact on quality of product or service',
            'likelihood of successful execution',
            'ability to execute within required timeframe',
            'low disruption risk'
          ],
          optionTitles: ['Initiative 1', 'Initiative 2']
        },
        {title: 'improvements: which projects',
          key: 'planning-operations-which-improvement-projects',
          factorTitles: [
            'Impact (% of business affected)',
            'Ability to do quickly',
            'Will not create disruption',
            'Increase to flow value',
            'Eliminates rework',
            'Eliminates bottleneck',
            'Implementation cost-effectiveness',
            'Increase in # of people who can execute',
            'Creates freedom to do things that matter',
            'Fixed cost reduction'
          ],
          optionTitles: ['Project 1', 'Project 2']
        },
      ]},
      {title: 'Reflection: Big Picture', templates: [
        {title: 'smartest things we could do',
        key: 'reflection-smartest',
        factorTitles: [
          'increase in flow',
          'increase in quality',
          'increase in margin',
          'increase in focus',
          'increase in retention',
          'increase in sales'
        ],
        optionTitles: ['cut low margin services or products', 'invest in skill development', 'create more repeat business']},
        {title: 'what should we stop doing',
          key: 'reflection-stop',
          factorTitles: [
            'margin increase',
            'sales increase',
            'morale increase',
            'focus increase'
          ],
          optionTitles: ['stop discounting', 'stop selling X', 'stop overtime', 'stop unproductive meetings']
        },
        {title: 'top messages for team',
          key: 'reflection-messages',
          factorTitles: [
            'increase in focus',
            'increase in ownership',
            'increase in synergies',
            'improvement in product/service'
          ],
          optionTitles: ['Focus on more deeply understanding the customer', 'Emphasize cross-training and skill development', 'Identify new market opportunities']},
      ]}
    ]
  }},
  {title: 'Life Decision Templates', content: {
    subCategories: [
      {title: 'What to do next', templates: [
        {title: 'choosing a vacation destination',
          key: 'life-vacation',
          factorTitles: [
            'Uniqueness of Experience',
            'Adventure',
            'Culture',
            'Lodging Quality, Location',
            'Food',
            'Relaxation',
            'Outdoor Activities',
            'Walkability, Transportation',
            'Affordability',
          ],
          optionTitles: ['New York', 'Paris']
        },
        {title: 'which career',
          key: 'life-career',
          factorTitles: [
            'growth potential',
            'positive impact on others',
            'leverage your strengths',
            'stability',
            'income potential',
            'inspiration',
            'mobility'
          ],
          optionTitles: ['career 1', 'career 2']},
        {title: 'which college major',
          key: 'life-college-major',
          factorTitles: [
            'interest level',
            'leverage your strengths',
            'long term career opportunities',
            'long term quality of life',
            'doing it because YOU want to?'
          ],
          optionTitles: ['Philosophy', 'Chemistry', 'Nursing', 'Computer Science', 'Fine Art']},
        {title: 'choosing a college',
          key: 'life-which-college',
          factorTitles: ['academic quality', 'programs of interest', 'resume value', 'cultural fit', 'affordability'],
          optionTitles: ['College 1', 'college 2']
        }
      ]},
      {title: 'Where you want to be', templates: [
        {title: 'where to live',
          key: 'life-which-city',
          factorTitles: ['affordability', 'friends/family', 'career opportunities', 'lifestyle alignment', 'weather', 'food', 'culture'],
          optionTitles: ['New York', 'San Fran']
        },
        {title: 'which neighborhood to live in',
          key: 'life-which-neighborhood',
          factorTitles: [
            'commute to work/school',
            'walkability',
            'recreation',
            'restaurants',
            'affordability',
            'diversity',
            'culture',
            'services',
            'schools'
          ],
          optionTitles: ['neighborhood 1', 'neighborhood 2']
        },
        {title: 'where to retire',
          key: 'life-where-to-retire',
          factorTitles: [
            'new friend potential',
            'recreation',
            'will friends visit?',
            'long term affordability',
            'impact on health',
            'culture',
            'medical services',
            'other amenities'
          ],
          optionTitles: ['location 1', 'location 2']}
      ]},
      {title: 'Your home', templates: [
        {title: 'which home to purchase',
          key: 'life-which-home-to-purchase',
          factorTitles: [
            'how much do you love it?',
            'desirable location for you?',
            'desirable location for buyers?',
            'future potential to improve',
            'short term affordability',
            'afforable to get it in the condition that you want?',
            'neighborhood improving?'
          ],
          optionTitles: ['home 1', 'home 2']
        },
        {title: 'which remodeling project',
          key: 'life-which-remodeling-project',
          factorTitles: [
            'required to make home safe/sound',
            'required to avoid further damage',
            'increase lengevity of home',
            'reward to cost ratio',
            'affordability',
            'return on investment',
            'joy factor',
            'partner wants it too?'
          ],
          optionTitles: ['option 1', 'option 2']
        }
      ]},
      {title: 'Personal Finance', templates: [
        {title: 'buying a car',
          key: 'life-which-car',
          factorTitles: ['affordability', 'reliability', 'value retention', 'mpg', 'safety', 'size', 'perfomance', 'comfort', 'fun'],
          optionTitles: ['Car 1', 'Car 2']
        },
        {title: 'which stocks to invest in',
          key: 'life-which-stocks',
          factorTitles: ['capitalization', 'return on capital', 'low price to earnings ratio', 'low price/book value ratio', 'your understanding of product/industry/brand', 'history of results'],
          optionTitles: ['Company 1', 'Company 2']
        },
        {title: 'what to do with your money',
          key: 'life-what-to-do-with-money',
          factorTitles: [
            'can it be done now?',
            'likely to increase in value?',
            'does it increase your human capital?',
            'liquidity',
            'low risk of loss of capital',
            'potential return',
            'timeframe for return right for you?'
          ],
          optionTitles: ['invest in stocks', 'buy a home', 'invest in education']}
      ]},
      {title: 'The really big picture', templates: [
        {title: 'most impact on your life',
          key: 'life-big-picture',
          factorTitles: [
            'joy',
            'relationships',
            'health',
            'improves life for your family',
            'financial stability',
            'daily enjoyment',
            'relieves long term stress'
          ],
          optionTitles: ['exercise', 'travel', 'career advancement', 'community service', 'learning an instrument', 'learning a new language']}
      ]}
    ]
  }}
];

export default decisionTemplateCategories;
