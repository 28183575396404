import {K} from '../../../styles';

var s = {};

s.notificationContainer = {
  // flex: 1,
  marginBottom: K.margin,
  alignSelf: 'stretch'
};
s.notification = {
  // paddingHorizontal: K.paddingHorizontal,
  // paddingVertical: K.paddingVertical,
  backgroundColor: K.colors.gray,
  borderRadius: K.borderRadius,
  alignSelf: 'stretch'
  // flex: 1
};
  s.swipeActionView = {
    justifyContent: 'center',
    paddingHorizontal: K.spacing,
    // flex: 1,
    // height: '100%',
    borderTopLeftRadius: K.borderRadius,
    borderBottomLeftRadius: K.borderRadius
  };
    s.swipeActionText = {
      ...K.fonts.label,
      opacity: 1
    };
  s.notificationBody = {
    // lineHeight: 20,
    paddingHorizontal: K.spacing,
    paddingVertical: K.calc(10)
  };
  s.notificationTimeContainer = {
    flexDirection: 'row',
    alignItems: 'center'
  };
    s.sourceUser = {
      paddingHorizontal: K.spacing,
      paddingVertical: K.calc(10),
      borderTopLeftRadius: K.borderRadius,
      borderBottomRightRadius: K.borderRadius,
      marginRight: K.spacing,
    }
    s.sourceUserText = {

    };
    s.notificationTimeText = {
      color: '#aaa',
      paddingVertical: K.calc(10),
    };
  s.hideNotificationButton = {
    backgroundColor: K.colors.gray,
    // height: 'auto',
    flex: 1,
    alignItems: 'flex-end',
    width: K.button.width
  };
    s.hideNotificationImage = {
      width: 14,
      height: 14,
      opacity: 0.5,
      marginRight: K.margin,
      marginTop: K.margin
    };

export default s;
