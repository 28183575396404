import { Modal, TouchableOpacity, PanResponder, Animated, KeyboardAvoidingView, Platform, ScrollView, Keyboard, View } from 'react-native';
import prepareToAnimate from '../../prepare-to-animate';
import withKeyEvents from '../../with-key-events';
import styleSpread from '../../style-spread';
import { withSafeAreaInsets } from 'react-native-safe-area-context';
import React, { Component } from 'react';
import confirm from '../../confirm';
import { K } from '../../styles';
import { Image } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import ReactDOM from 'react-dom';
import lib from '@symbolic/lib';
import Button from '../button/button';

import closeIcon from '../../assets/x-icon.png';
import closeIconWhite from '../../assets/x-icon-white.png';
import _ from 'lodash';
import styles from './popup.styles';

var s = styleSpread(styles);

class Popup extends Component {
  state = {
    translateValue: new Animated.Value(500),
    opacity: new Animated.Value(Platform.OS === 'android' ? 1 : 0),
    keyboardIsShowing: false,
    isMounted: false,
    windowHeight: 500
  }

  constructor(props) {
    super(props);

    if (!this.disableSwipeToClose && !K.isWeb) {
      this.panResponder = PanResponder.create({
        onStartShouldSetPanResponder: () => true,
        onPanResponderMove: (_event, gestureState) => {
          if (!this.props.closeDisabled) {
            var dy = Math.max(0, gestureState.dy)

            this.state.translateValue.setValue(dy);
            this.state.opacity.setValue(Math.min(1, 1 - (dy / this.height)));
          }
        },
        onPanResponderRelease: (_event, gesture) => {
          if (!this.props.closeDisabled) {
            var shouldClose = gesture.vy > 0.5;

            Animated.timing(this.state.translateValue, {duration: 100, useNativeDriver: true, toValue: shouldClose ? this.height : 0}).start();
            Animated.timing(this.state.opacity, {duration: 100, useNativeDriver: true, toValue: shouldClose ? 0 : 1}).start();

            if (shouldClose) {
              this.isAnimating = true;

              setTimeout(() => this.props.onClose(), 100);
            }
          }
        }
      });
    }
    else {
      this.panResponder = {};
    }
  }

  componentDidMount() {
    Animated.timing(this.state.translateValue, {toValue: 0, duration: 300, useNativeDriver: true}).start();
    Animated.timing(this.state.opacity, {toValue: 1, duration: 300, useNativeDriver: true}).start();

    this.keyboardShowListener = Keyboard.addListener('keyboardWillShow', this.handleKeyboardShow);
    this.keyboardHideListener = Keyboard.addListener('keyboardWillHide', this.handleKeyboardHide);

    setTimeout(() => this.setState({isMounted: true}), K.isWeb ? 0 : 50);
  }

  componentWillUnmount() {
    this.keyboardShowListener.remove();
    this.keyboardHideListener.remove();

    this.setState({isMounted: false});
  }

  componentDidUpdate() {
    if (this.isAnimating) {
      setTimeout(() => {
        this.isAnimating = false;
      }, 300);
    }
    else if (this.props.shouldClose) {
      this.animateClose();
    }
  }

  handleKeyboardShow = () => {
    prepareToAnimate();

    this.setState({keyboardIsShowing: true});
  }

  handleKeyboardHide = () => {
    prepareToAnimate();

    this.setState({keyboardIsShowing: false});
  }

  handleKeyDown = async event => {
    var deleteTriggered = (lib.event.keyPressed(event, 'delete') || lib.event.keyPressed(event, 'backspace')) && this.props.cancelOnDelete && !_.includes(['INPUT', 'TEXTAREA'], document.activeElement.tagName);

    if (lib.event.keyPressed(event, 'esc')) {
      document.activeElement.blur();

      setTimeout(() => this.animateClose());
    }
    else if (lib.event.keyPressed(event, 'enter') && this.props.closeOnEnter && document.activeElement.tagName !== 'TEXTAREA') {
      this.props.onClose('confirm');
    }
    else if (deleteTriggered) {
      if (this.props.onDelete) {
        this.props.onDelete();
      }
      else if (this.props.deleteWithoutConfirming) {
        this.props.onClose('cancel');
      }
      else if (await confirm('Cancel', this.props.cancelConfirmMessage || 'Are you sure?')) {
        this.props.onClose('cancel');
      }
    }
  }

  animateClose = async () => {
    if (!this.props.closeDisabled && (!this.props.closeConfirmMessage || await confirm('Cancel', this.props.closeConfirmMessage))) {
      Keyboard.dismiss();

      setTimeout(() => {
        this.isAnimating = true;
        this.setState({isClosing: true});

        if (this.props.onAnimateClose) this.props.onAnimateClose();

        Animated.timing(this.state.translateValue, {toValue: this.height, duration: 300, useNativeDriver: true}).start();
        Animated.timing(this.state.opacity, {toValue: 0, duration: 300, useNativeDriver: true}).start();

        setTimeout(() => {
          this.setState({isClosed: true}, () => {
            this.props.onClose();
          });
        }, 300);
      }, K.isWeb ? 0 : 50); //HINT to wait for keyboard to trigger blur/change on text inputs
    }
  }

  handleWindowLayout = (event) => {
    this.setState({windowHeight: event.nativeEvent.layout.height});
  }

  get height() {
    return this.state.windowHeight;
  }

  get disableSwipeToClose() {
    return this.props.disableSwipeToClose || (this.props.disableSwipeToClose === undefined && this.props.scrollEnabled) || !!this.props.closeConfirmMessage;
  }

  render() {
    var {compact, noPadding, closeDisabled, useCloseButton, scrollEnabled, insets} = this.props;
    var {disableSwipeToClose} = this;
    var height = K.orientation === 'landscape' ? 300 : 500;

    var displayMode = _.get(this.props, 'displayMode', 'paneRight');

    scrollEnabled = true; //OVERRIDE, just make always scrollable

    var paddingStyle = {
      paddingBottom: noPadding ? 0 : this.state.keyboardIsShowing ? (compact ? K.spacing : K.spacing * 2) : 50,
      paddingTop: noPadding ? 0 : (K.isWeb || compact || scrollEnabled ? K.spacing : K.spacing * 1.5),
      padding: noPadding ? 0 : K.spacing
    };

    var horizontalPadding = {
      paddingLeft: K.insets(insets, 'left'),
      paddingRight: K.insets(insets, 'right'),
    }

    var inlineCloseButton = this.state.keyboardIsShowing || K.orientation === 'landscape';
    var showCloseButton = !K.isWeb && !this.state.isClosing && !closeDisabled;

    var closeButton = K.isWeb ? null : (
      <View
        style={{
          ...(inlineCloseButton ? {
            marginTop: insets.top,
            marginBottom: K.spacing,
            alignItems: 'flex-end'
          } : {
            zIndex: 100,
            position: 'absolute',
            top: -K.button.height - K.spacing,
            right: K.spacing + horizontalPadding.paddingRight
          })
        }}
      >
        <Button {...(useCloseButton ? {icon: inlineCloseButton ? closeIconWhite : closeIcon} : {label: 'Done'})} mode={inlineCloseButton ? 'dark' : 'light'} onPress={this.animateClose}/>
      </View>
    );

    var component = (
      <Modal transparent {...s.popup} supportedOrientations={['portrait', 'landscape']}>
        <KeyboardAvoidingView style={{height: '100%'}} behavior={Platform.OS === 'ios' ? 'padding' : null}>
          <View style={{height: '100%'}}>
            <ScrollView
              scrollEnabled={false}
              keyboardShouldPersistTaps={this.props.keyboardShouldPersistTaps || 'handled'}
              style={{height: '100%'}}
              contentContainerStyle={{flex: 1, height: '100%', position: 'relative', ...(K.isWeb ? {flexDirection: 'row'} : {})}}
            >
              <Animated.View style={[styles.background, {opacity: this.state.opacity}]}/>
              <TouchableOpacity {...s.pressOutsideButton} onPress={this.animateClose}/>
              {displayMode === 'paneRight' && (
                <Animated.View
                  dataSet={{popup: 1}}
                  onLayout={this.handleWindowLayout}
                  style={[
                    styles.window,
                    {
                      ...(K.isWeb ? {width: K.standardColumnWidth} : {}),
                      ...(scrollEnabled ? {} : paddingStyle)
                    },
                    this.props.windowStyle,
                    (K.isWeb ? {opacity: this.state.opacity} : {transform: [{translateY: this.state.translateValue}]})
                  ]}
                  {...this.panResponder.panHandlers}
                >
                  {showCloseButton && !inlineCloseButton && (closeButton)}
                  {!scrollEnabled && !compact && !closeDisabled && !K.isWeb && !disableSwipeToClose && (<View {...s.swipeIndicator}/>)}
                  {scrollEnabled ? (
                    <KeyboardAwareScrollView keyboardShouldPersistTaps={this.props.keyboardShouldPersistTaps || 'handled'} style={{...horizontalPadding, height: (K.isWeb || this.state.keyboardIsShowing || K.orientation === 'landscape') ? '100%' : height}} contentContainerStyle={paddingStyle}>
                      {showCloseButton && inlineCloseButton && (closeButton)}
                      {this.props.children}
                    </KeyboardAwareScrollView>
                  ) : (
                    <View style={{...horizontalPadding}}>
                      {showCloseButton && inlineCloseButton && (closeButton)}
                      {this.props.children}
                    </View>
                  )}
                </Animated.View>
              )}
              {displayMode === 'fitted' && (
                <Animated.View
                  dataSet={{popup: 1}}
                  onLayout={this.handleWindowLayout}
                  style={[
                    styles.window,
                    {
                      position: 'absolute', margin: 'auto', left: 0, right: 0, top: 0, bottom: 0,
                      ...(K.isWeb ? {width: K.standardColumnWidth, height: 200} : {})
                    },
                    this.props.windowStyle,
                    (K.isWeb ? {opacity: this.state.opacity} : {transform: [{translateY: this.state.translateValue}]})
                  ]}
                >
                  <View style={{padding: K.spacing * 2, flex: 1, justifyContent: 'center'}}>
                    {this.props.children}
                  </View>
                </Animated.View>
              )}
            </ScrollView>
          </View>
        </KeyboardAvoidingView>
      </Modal>
    );

    //WARNING isMounted is here to wait to render content that might autofocus until after keyboard event listener is added
    if (this.state.isClosed || !this.state.isMounted) return null;

    if (K.isWeb) return ReactDOM.createPortal(component, document.getElementById('root'));
    else return component;
  }
}

export default withSafeAreaInsets(withKeyEvents(Popup));
