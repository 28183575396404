import React, { Component, Fragment } from 'react';

import { TouchableOpacity, Platform, View, FlatList } from 'react-native';
import { K } from '../../styles';

import TextInput from '../text-input/text-input';
import Text from '../text/text';
import Popup from '../popup/popup';
import lib from '@symbolic/lib';
import Button from '../button/button';
import _ from 'lodash';

class AutocompleteInput extends Component {
  state = {
    popupIsVisible: false,
    activeKeyboardIndex: 0
  }

  handleKeyDown = (event) => {
    if (this.props.autocompleteIsVisible) {
      var {activeKeyboardIndex} = this.state;
      var {data} = this.props;

      if (lib.event.keyPressed(event, 'up') || lib.event.keyPressed(event, 'down')) {
        event.preventDefault();

        if (lib.event.keyPressed(event, 'up')) {
          this.setState({activeKeyboardIndex: Math.max(0, activeKeyboardIndex - 1)});
        }
        if (lib.event.keyPressed(event, 'down')) {
          this.setState({activeKeyboardIndex: Math.min(data.length - 1, activeKeyboardIndex + 1)});
        }
      }
      else if (lib.event.keyPressed(event, 'enter')) {
        event.preventDefault();

        if (data[activeKeyboardIndex]) {
          this.selectItem(data[activeKeyboardIndex]);
        }
      }
    }
  }

  selectItem = (item) => {
    if (item.invalidMessage) {
      alert(item.invalidMessage);
    }
    else {
      this.props.onSelect(item);

      this.setState({activeKeyboardIndex: 0, popupIsVisible: false});
    }
  }

  renderItem = ({item, index}) => (
    <TouchableOpacity
      focusable={false}
      dataSet={{nohover: 1}}
      key={`${item.id}-autocompletesuggestion`}
      style={{
        minHeight: K.button.height, justifyContent: 'center', paddingVertical: K.margin, paddingHorizontal: K.spacing, backgroundColor: K.colors.gray, borderTopWidth: 1, borderTopColor: `rgba(0, 0, 0, 0.05)`,
        ...(this.state.activeKeyboardIndex === index && K.isWeb ? {backgroundColor: K.colors.doubleGray} : {}),
      }}
      onPress={() => this.selectItem(item)}
      onPressIn={() => this.pressingAutofill = true}
      onPressOut={() => this.pressingAutofill = false}
    >
      <Text>{`${item.firstName}${item.lastName ? ` ${item.lastName}` : ''}`}</Text>
    </TouchableOpacity>
  )

  render() {
    var {data, autocompleteIsVisible, setAutocompleteIsVisible, inputValue, setInputValue, placeholder} = this.props;

    if (K.isWeb || this.state.popupIsVisible) {
      var inputAndResults = (<Fragment>
        <TextInput
          autoFocus={this.state.popupIsVisible}
          value={inputValue}
          placeholder={placeholder}
          onKeyPress={this.handleKeyDown}
          style={{...(!autocompleteIsVisible || data.length === 0 ? {} : {borderBottomLeftRadius: 0, borderBottomRightRadius: 0})}}
          autoCapitalize='none'
          onBlur={() => {
            //HINT ensures autofill option is selected before options are hidden
            //HINT don't hide autofill once opened android
            setTimeout(() => {
              if (!this.pressingAutofill) setAutocompleteIsVisible(false);
            }, 100);
          }}
          onFocus={() => setAutocompleteIsVisible(true)}
          onChangeText={(text) => {
            this.setState({activeKeyboardIndex: 0});

            setInputValue(text);
            setAutocompleteIsVisible(true);
          }}
        />
        {autocompleteIsVisible && (
          <View style={{...(K.isWeb ? {position: 'absolute', top: '100%', zIndex: 1000, ...K.shadow} : {}), width: '100%'}}>
            <FlatList
              keyboardShouldPersistTaps='handled'
              style={{maxHeight: K.isWeb ? 130 : 210, padding: 0, borderWidth: 0, borderBottomRightRadius: K.borderRadius, borderBottomLeftRadius: K.borderRadius}}
              data={data}
              keyExtractor={item => `${item.key || item.id}`}
              renderItem={this.renderItem}
            />
          </View>
        )}
      </Fragment>);
    }

    return (
      <View style={{position: 'relative', zIndex: 1000, ...this.props.style}}>
        {K.isWeb ? (
          inputAndResults
        ) : (
          <Button onPress={() => this.setState({popupIsVisible: true})} label={this.props.placeholder || '+'} style={{paddingHorizontal: K.spacing, alignItems: 'flex-start'}} textStyle={{opacity: 0.4}}/>
        )}
        {this.state.popupIsVisible && (
          <Popup onClose={() => this.setState({popupIsVisible: false})}>
            {inputAndResults}
          </Popup>
        )}
      </View>
    );
  }
}

export default AutocompleteInput;
