import React, { Component, Fragment } from 'react';
import lib, { api } from '@symbolic/lib';

import Button from '../../button/button';
import BasicTextInput from '../../text-input/text-input';
import Text from '../../text/text';
import ScrollView from '../../scroll-view/scroll-view';
import Url from 'url-parse';
import TermsPrivacyPopup from '../../terms-privacy-popup/terms-privacy-popup';
import _ from 'lodash';
import getDevice from '../../../get-device';
import appleAppStoreDownloadIcon from '../../../assets/apple-app-store-download-icon.png';
import googleAppStoreDownloadIcon from '../../../assets/google-app-store-download-icon.png';
import * as Linking from 'expo-linking';
import styleSpread from '../../../style-spread';
import styles from './auth-view.styles';
import withKeyEvents from '../../../with-key-events';

import { connect, logIn, signUp, resetPassword, clearError } from '@symbolic/redux';
import { Platform, View, Keyboard, TouchableOpacity, Image } from 'react-native';
import { withRouter } from 'react-router-native';
import { K } from '../../../styles';

var s = styleSpread(styles);

var TextInput = (props) => {
  var labelledViewStyles = _.defaultsDeep(props.labelledViewStyles, styles.labelledViewStyles);
  var style = {...styles.input, ...props.style};

  return <BasicTextInput {...props} {...{labelledViewStyles, style}}/>
}

class AuthView extends Component {
  state = {
    formKey: 'login',
    keyboardIsShowing: false,
    resettingIsGuestPassword: false
  };

  handleSubmitButtonPress = () => {
    if (this.state.formKey === 'register') {
      this.setState({isAcceptingTerms: true});
    }
    else {
      this.submit();
    }
  }

  async submit() {
    this.props.clearError();

    var {state} = this;

    var device = await getDevice({appKey: this.props.appKey});

    var sendToInitialLink = () => {
      var {initialUrl} = this.props;

      if (initialUrl) {
        var initialUrlPathName = (new Url(initialUrl)).pathname;

        if (!initialUrl.includes('expo.io') && initialUrlPathName !== '' && initialUrlPathName !== '/') {
          this.props.history.push(initialUrlPathName);
        }
        else {
          this.props.history.push('/');
        }
      }
    }

    if (state.formKey === 'login') {
      if (state.email && state.password) {
        await this.props.logIn({..._.pick(state, ['email', 'password']), device});

        sendToInitialLink();
      }
      else {
        //TODO show missing fields
      }
    }
    else if (state.formKey === 'register') {
      if (state.email && state.password && state.confirmedPassword && state.firstName && state.lastName) {
        if (state.password === state.confirmedPassword) {
          try {
            await this.props.signUp({
              ..._.pick(state, ['email', 'password', 'firstName', 'lastName']), device
            });

            sendToInitialLink();
          }
          catch(err) {
            alert('registration failed');
          }
        }
        else {
          alert(`passwords don't match`);
        }
      }
      else {
        alert('please complete all fields');
      }
    }
    else if (state.formKey === 'resetPassword') {
      if (state.email && state.oneTimeCode && state.password && state.confirmedPassword) {
        if (state.password === state.confirmedPassword) {
          try {
            await this.props.resetPassword({
              ..._.pick(state, ['email', 'password', 'confirmedPassword', 'oneTimeCode', 'firstName', 'lastName']), device
            });

            sendToInitialLink();
          }
          catch(err) {
            alert('something went wrong');
          }
        }
        else {
          alert(`passwords don't match`);
        }
      }
      else {
        alert('please complete all fields');
      }
    }
    else if (state.formKey === 'forgotPassword') {
      if (state.email) {
        //if (lib.validation.emailIsValid(state.email)) {
          await api.request({
            uri: '/forgot-password',
            body: {email: state.email, appKey: this.props.appKey, originData: {operatingSystem: Platform.OS}}
          });

          this.setState({formKey: 'resetPassword'});

          alert(`Please enter the code we just emailed you once you receive it`);
        //}
        // else {
        //   alert('please enter a valid email');
        // }
      }
      else {
        alert('please enter your email to receive a password reset code');
      }
    }
  }

  setFormKey(formKey) {
    this.props.clearError();

    this.setState({formKey});
  }

  componentDidMount() {
    Keyboard.addListener(Platform.OS === 'ios' ? 'keyboardWillShow' : 'keyboardDidShow', this.handleKeyboardShow);
    Keyboard.addListener(Platform.OS === 'ios' ? 'keyboardWillHide' : 'keyboardDidHide', this.handleKeyboardHide);
  }

  componentWillUnmount(...args) {
    Keyboard.removeListener(Platform.OS === 'ios' ? 'keyboardWillShow' : 'keyboardDidShow', this.handleKeyboardShow);
    Keyboard.removeListener(Platform.OS === 'ios' ? 'keyboardWillHide' : 'keyboardDidHide', this.handleKeyboardHide);
  }

  async componentDidUpdate(prevProps) {
    if (this.state.formKey === 'register' && _.get(this.props, 'session.error') && this.props.session.error.message === `Please enter the verification code we just emailed you to finish signing up.`) {
      await api.request({
        uri: '/forgot-password',
        body: {email: this.state.email, appKey: this.props.appKey, isGuestSignup: true, originData: {operatingSystem: Platform.OS}}
      });

      this.setState({formKey: 'resetPassword', resettingIsGuestPassword: true});
    }
  }

  handleKeyDown = event => {
    if (lib.event.keyPressed(event, 'enter')) handleSubmitButtonPress();
  }

  handleKeyboardShow = () => this.setState({keyboardIsShowing: true});
  handleKeyboardHide = () => this.setState({keyboardIsShowing: false});

  render () {
    var {formKey, resettingIsGuestPassword} = this.state;
    var {appKey} = this.props;

    var submitText = {
      login: 'log in',
      register: 'submit',
      resetPassword: 'log in',
      forgotPassword: 'submit'
    }[formKey];

    var errorMessage = _.get(this.props.session, 'error.message');

    var orgId, accentColor = 'black';

    if (window.location.hostname === 'portal.meljac-na.com') {
      orgId = 850;
    }

    if (orgId) {
      var logoUrl = `https://symbolic-public.s3.amazonaws.com/org-logos/light-background/${orgId}.png`;
      var accentColor = '#837D72';
      // var title = 'Meljac North America Client Portal';
      //TODO get org based on url - either portal.meljac-na.com or ?workspaceId=850
    }

    return (
      <View {...s.authView}>
        {this.state.isAcceptingTerms && (
          <TermsPrivacyPopup
            onAccept={() => {
              this.setState({isAcceptingTerms: false});
              this.submit();
            }}
            onClose={() => this.setState({isAcceptingTerms: false})}
          />
        )}
        <ScrollView
          style={styles.scrollView}
          contentContainerStyle={styles.scrollViewContent}
          keyboardShouldPersistTaps='handled'>
            <View {...s.logo}>
              {orgId ? (
                <Image source={logoUrl} style={{width: 200, height: 100, marginBottom: K.spacing * 3}} resizeMode='contain'/>
              ) : (
                <Text {...s.header}>{this.props.appName}</Text>
              )}
              {!orgId && (<Text {...s.subheader}>by Symbolic Frameworks</Text>)}
            </View>
            {!orgId && (this.props.appDescription && formKey !== 'resetPassword') && (
              <Text {...s.appDescription}>{this.props.appDescription}</Text>
            )}
            {formKey === 'resetPassword' && (
              <Text {...s.appDescription}>{'A code has been sent to your email. Please enter it and set your password'}</Text>
            )}
            <View {...s.inputs}>
              {(formKey === 'register' || (formKey === 'resetPassword' && resettingIsGuestPassword)) && (
                <Fragment>
                  <TextInput
                    textContentType='givenName'
                    autoCompleteType='name'
                    label='first name'
                    returnKeyType='next'
                    labelAfter
                    blurOnSubmit={false}
                    value={this.state.firstName || ''}
                    onSubmitEditing={() => this.lastNameRef.focus()}
                    onChangeText={text => this.setState({firstName: _.trim(text)})}
                  />
                  <TextInput
                    textContentType='familyName'
                    autoCompleteType='name'
                    label='last name'
                    returnKeyType='next'
                    labelAfter
                    blurOnSubmit={false}
                    value={this.state.lastName || ''}
                    getInputRef={ref => this.lastNameRef = ref}
                    onSubmitEditing={() => this.emailRef.focus()}
                    onChangeText={text => this.setState({lastName: _.trim(text)})}
                  />
                </Fragment>
              )}
              <TextInput
                dataSet={{emailInput: 1}}
                autoCapitalize='none'
                textContentType='emailAddress'
                keyboardType='email-address'
                autoCompleteType='email'
                returnKeyType={formKey !== 'forgotPassword' ? 'next' : 'go'}
                value={this.state.email || ''}
                label='email'
                labelAfter
                blurOnSubmit={false}
                onChangeText={text => this.setState({email: _.trim(text)})}
                getInputRef={ref => this.emailRef = ref}
                onSubmitEditing={() => {
                  if (formKey !== 'forgotPassword') this.passwordRef.focus()
                  else if (formKey === 'resetPassword') this.oneTimeCodeRef.focus()
                  else this.handleSubmitButtonPress()
                }}
              />
              {formKey === 'resetPassword' && (
                <TextInput
                  textContentType='oneTimeCode'
                  returnKeyType='next'
                  label='code'
                  labelAfter
                  getInputRef={ref => this.oneTimeCodeRef = ref}
                  onChangeText={text => this.setState({oneTimeCode: _.trim(text)})}
                />
              )}
              {formKey !== 'forgotPassword' && (
                <TextInput
                  dataSet={{passwordInput: 1}}
                  secureTextEntry
                  textContentType={formKey === 'login' ? 'password' : 'newPassword'}
                  label='password'
                  labelAfter
                  getInputRef={ref => this.passwordRef = ref}
                  onChangeText={text => this.setState({password: text})}
                  {...(formKey === 'login' ? {
                    returnKeyType: 'go',
                    onSubmitEditing: this.handleSubmitButtonPress
                  } : {
                    returnKeyType: 'next',
                    onSubmitEditing: () => this.confirmPasswordRef.focus()
                  })}
                />
              )}
              {!_.includes(['forgotPassword', 'login'], formKey) && (
                <TextInput
                  secureTextEntry
                  label='confirm'
                  labelAfter
                  onChangeText={text => this.setState({confirmedPassword: text})}
                  returnKeyType='go'
                  getInputRef={ref => this.confirmPasswordRef = ref}
                  onSubmitEditing={this.handleSubmitButtonPress}
                />
              )}
            </View>
            {errorMessage && (
              <View {...s.errorMessage}>
                <Text {...s.errorMessageText}>{errorMessage}</Text>
              </View>
            )}
            <View style={{...styles.linksContainer}}>
              {formKey === 'signUp' && (
                <View {...s.termsPrivacyContainer}>
                  <TouchableOpacity onPress={() => this.setState({isShowingTermsPrivacyPopup: true})}>
                    <Text {...s.termsPrivacyLink}>Terms & Privacy</Text>
                  </TouchableOpacity>
                  {this.state.isShowingTermsPrivacyPopup && (
                    <TermsPrivacyPopup onClose={() => this.setState({isShowingTermsPrivacyPopup: false})}/>
                  )}
                </View>
              )}
              <Button
                dataSet={{submitButton: 1}}
                style={{...styles.link, backgroundColor: accentColor, marginBottom: K.margin}}
                label={submitText}
                textStyle={{...styles.buttonText}}
                onPress={this.handleSubmitButtonPress}
              />
              {formKey === 'login' ? (
                <Fragment>
                  {!orgId && (<Button style={{...styles.link, backgroundColor: '#ddd', marginBottom: K.margin}} label='sign up' onPress={() => this.setFormKey('register')} textStyle={{...styles.linkText}}/>)}
                  <Button style={{...styles.link}} label='Forgot Password' onPress={() => this.setFormKey('forgotPassword')} textStyle={{...styles.linkText}}/>
                </Fragment>
              ) : (
                <Button style={{...styles.link}} label='back' textStyle={{...styles.linkText}} onPress={() => this.setFormKey('login')}/>
              )}
            </View>
          {(K.isWeb && appKey !== 'designEngine') && (
            <View style={{marginTop: 80, width: 250, flexDirection: 'row', alignSelf: 'center', alignItems: 'center', alignSelf: 'center'}}>
              <TouchableOpacity onPress={() => Linking.openURL(K.appStoreLinks.apple[appKey])}>
                <Image source={appleAppStoreDownloadIcon} resizeMode='contain' style={{width: 122, height: 35, marginRight: K.margin}}/>
              </TouchableOpacity>
              <TouchableOpacity onPress={() => Linking.openURL(K.appStoreLinks.google[appKey])}>
                <Image source={googleAppStoreDownloadIcon} resizeMode='contain' style={{width: 121, height: 35}}/>
              </TouchableOpacity>
            </View>
          )}
        </ScrollView>
      </View>
    );
  }
}

export default withRouter(connect({
  mapState: ['session'],
  mapDispatch: {logIn, signUp, resetPassword, clearError}
})(withKeyEvents(AuthView)));
