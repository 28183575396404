import React from 'react';
import {ScrollView as RNScrollView} from 'react-native';

var style = {

};

export default function ScrollView(props) {
  return (
    <RNScrollView {...props} ref={props.getRef} style={{...style, ...props.style}}>
      {props.children}
    </RNScrollView>
  );
};
