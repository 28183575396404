import React, { Component } from 'react';

import { View } from 'react-native';
import Button from '../button/button';
import Text from '../text/text';
import _ from 'lodash';
import lib from '@symbolic/lib';
import styleSpread from '../../style-spread';
import styles from './story-icon.styles';
import {K} from '../../styles';

var s = styleSpread(styles);

class StoryIcon extends Component {
  render() {
    var {storyModel, title, showFullTitle, textStyle, color, onPress} = this.props;

    if (storyModel) {
      title = showFullTitle ? storyModel.title : storyModel.initials;
      color = color || lib.colors.colorFor({id: storyModel.id});
    }

    var viewStyle = {...styles.view, ...this.props.viewStyle, backgroundColor: color, paddingHorizontal: showFullTitle ? K.spacing : 0};
    var textStyle = {...(showFullTitle ? styles.fullTitleText : styles.initialsText), ...textStyle};

    return onPress ? (
      <Button style={{...styles.button, ...viewStyle}} {...{onPress}}>
        <Text style={textStyle} numberOfLine={1}>{`${title}`}</Text>
      </Button>
    ) : (
      <View style={viewStyle}>
        <Text style={textStyle} numberOfLine={1}>{`${title}`}</Text>
      </View>
    );
  }
}

export default StoryIcon;
