import { Alert, Platform } from 'react-native';

export default async function confirm(title='Are you sure?', message='') {
  return new Promise ((resolve) => {
    if (Platform.OS === 'web') {
      resolve(window.confirm(message));
    }
    else {
      var buttons = [
        {text: 'Cancel', onPress: () => resolve(false)},
        {text: 'Ok', onPress: () => resolve(true)}
      ];

      var options = {onDismiss: () => resolve(false)};

      Alert.alert(title, message, buttons, options);
    }
  });
}
