import React, { Component } from 'react';
import { ScrollView, View, TouchableOpacity } from 'react-native';
import Button from '../button/button';
import PickerInput from '../picker-input/picker-input';
import Text from '../text/text';
import styleSpread from '../../style-spread';

import _ from 'lodash';
import styles from './comments.styles';
import {K} from '../../styles';
import commentClassFor from './comment/comment';
import commentPopupClassFor from './comment-popup/comment-popup';

var s = styleSpread(styles);

export default function commentsClassFor({CommentsComponent, CommentComponent, connect, resourceActions, appKey='protagonist'}) {
  var Comment = commentClassFor({CommentComponent, resourceActions, appKey});
  var CommentPopup = commentPopupClassFor({connect, resourceActions, appKey});

  class Comments extends Component {
    render() {
      return (
        <View style={{...this.props.style}}>
          <CommentPopup {..._.pick(this.props, ['autoFocus', 'resourceId', 'isPublished', 'type', 'decisionOptionId', 'decisionOptionFactorId', 'processInstanceStepId', 'placeholder', 'creationProps', 'onCreate', 'additionalUsers'])} hideCommentPopup={() => this.setState({addingComment: false})}/>
          {_.map(_.reverse([...this.props.comments]), comment => (
            <Comment id={comment.id} key={comment.id} additionalUsers={this.props.additionalUsers}/>
          ))}
        </View>
      );
    }
  }

  Comments = CommentsComponent.connect(Comments, {
    resourcesFilterDataFor: ({ownProps}) => {
      return {mode: 'filter', filter: ({resource}) => {
        return _.get(resource, `resourceId`) === ownProps.resourceId &&
          _.get(resource, 'type') === ownProps.type &&
          (_.includes(['decisionOption', 'decisionOptionFactor'], ownProps.type) ? (_.get(resource, 'data.decisionOptionId') && _.get(resource, 'data.decisionOptionId') === ownProps.decisionOptionId) : true) &&
          (ownProps.type === 'decisionOptionFactor' ? (_.get(resource, 'data.decisionOptionFactorId') && _.get(resource, 'data.decisionOptionFactorId') === ownProps.decisionOptionFactorId) : true) &&
          (ownProps.type === 'processInstanceStep' ? (_.get(resource, 'data.processInstanceStepId') && _.get(resource, 'data.processInstanceStepId') === ownProps.processInstanceStepId) : true);
      }};
    },
  });

  return Comments;
}
