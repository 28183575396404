import React, { Component } from 'react';
import { View, TouchableOpacity, Image } from 'react-native';
import { Route, Switch, Link } from 'react-router-native';
import { withSafeAreaInsets } from 'react-native-safe-area-context';
import withKeyEvents from '../../with-key-events';
import lib from '@symbolic/lib';

import { K } from '../../styles';

import Menu from '../menu/menu';
import { connect } from '@symbolic/redux';
import { withRouter } from 'react-router-native';
import Text from '../text/text';
import Label from '../label/label';
import styleSpread from '../../style-spread';
import _ from 'lodash';
import backIcon from './home-icon-white.png';
import menuIcon from './menu-icon-white.png';

import styles from './header.styles';

var s = styleSpread(styles);

class Header extends Component {
  state = {
    menuIsVisible: false
  };

  handleKeyDown = event => {
    if (this.state.menuIsVisible && lib.event.keyPressed(event, 'esc')) {
      this.hideMenu();
    }
  }

  hideMenu = () => this.setState({menuIsVisible: false});
  showMenu = () => this.setState({menuIsVisible: true});

  render() {
    var {onSetActiveOrg, onBeforeSetActiveOrg, headerContent: HeaderContent, headerStyle, unseenNotificationCount, insets, allowOrgSwitching, onNotificationPress, Notifications, onLogOut, session, appKey, appName} = this.props;
    var {hideMenu} = this;
    var {activeOrg} = session;

    return (
      <View style={{
        ...styles.header,
        backgroundColor: this.props.accentColor || 'black',
        height: K.isWeb || K.orientation === 'landscape' ? 'auto' : K.button.height + K.spacing * 2,
        paddingLeft: K.insets(insets, 'left', styles.header.padding),
        paddingRight: K.insets(insets, 'right', styles.header.padding),
        paddingVertical: K.orientation === 'landscape' ? K.spacing / 2 : K.spacing,
        ...headerStyle
      }}>
        <View style={{...styles.headerChildren, ...this.props.headerContentContainerStyle}}>
          <Switch>
            <Route exact path='/my-account' render={() => _.includes(['member', 'owner', 'guest'], activeOrg.role) ? (
              <Link to='/' style={{...styles.backButton, backgroundColor: this.props.noButtonColor ? undefined : K.colors.blackGray}}>
              <Image source={backIcon} style={{width: K.calc(20), height: K.calc(20)}}/>
            </Link>
            ) : null}/>
            <Route render={() => <HeaderContent {...this.props.headerContentProps}/>}/>
          </Switch>
        </View>
        <TouchableOpacity style={{...styles.button, backgroundColor: this.props.noButtonColor ? undefined : this.props.accentColor || K.colors.blackGray, ...this.props.menuButtonStyle}} onPress={this.showMenu}>
          <Image source={menuIcon} style={{width: K.calc(20), height: K.calc(20)}}/>
          {unseenNotificationCount > 0 && (
            <View style={{
                position: 'absolute', right: -8, top: -8, backgroundColor: '#ed667a', borderRadius: 15, minWidth: 18, minHeight: 18,
                alignItems: 'center', justifyContent: 'center', paddingHorizontal: 5
            }}>
              <Text style={{color: 'white', fontSize: K.calcFont(10), left: 0.5}}>{unseenNotificationCount}</Text>
            </View>
          )}
        </TouchableOpacity>
        {this.state.menuIsVisible && (<Menu {...{appKey, appName, onLogOut, hideMenu, onSetActiveOrg, onBeforeSetActiveOrg, allowOrgSwitching, onNotificationPress, Notifications}}/>)}
      </View>
    );
  }
}

export default withSafeAreaInsets(connect({
  mapState: state => {
    return {
      unseenNotificationCount: _.filter(state.resources.notifications.byId, {status: 'unseen'}).length,
      session: state.session
    };
}})(withKeyEvents(Header)));
