import React, { useState, useEffect } from 'react';
import { ActivityIndicator, Image } from 'react-native';
import { K } from '../../styles';

import issueIcon from '../../assets/issue-icon-red.png';
import savedIcon from '../../assets/check-icon-white.png';
import NetInfo from '@react-native-community/netinfo';
import Tooltip from '../tooltip/tooltip';
import Text from '../text/text';
import prepareToAnimate from '../../prepare-to-animate';

export default class NetworkIndicator extends React.Component {
  state = {isLoading: false, isVisible: false, isConnected: true};

  componentDidMount() {
    this.unsubscribeNetInfo = NetInfo.addEventListener(state => this.setState({isConnected: state.isConnected}));

    this.interval = setInterval(() => {
      var {isLoading} = this.state;
      var newIsLoading = global.activeRequestsCount !== 0;

      if (newIsLoading !== isLoading) {
        prepareToAnimate();

        this.setState({isLoading: newIsLoading, isVisible: true});

        clearTimeout(this.timeout);

        if (!newIsLoading) {
          this.timeout = setTimeout(() => {
            prepareToAnimate();

            this.setState({isVisible: false});
          }, 3000);
        }
      }
    }, 100);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    clearInterval(this.timeout);

    this.unsubscribeNetInfo();
  }

  render() {
    var {isConnected, isLoading, isVisible} = this.state;

    return isConnected ? (isLoading ? (
      <ActivityIndicator size='small' color='white'/>
    ) : (
      <Tooltip style= {{flexDirection: 'row', alignItems: 'center'}} pressToView key='saved' text={isVisible ? 'Up to date' : ''}>
        {K.isWeb && (
          <Text dataSet={{'transition-opacity': 1}} style={{marginRight: K.spacing + K.margin, ...K.fonts.label, color: '#fff', opacity: isVisible ? 1 : 0}}>Saved</Text>
        )}
        <Image dataSet={{'transition-opacity': 1}} style={{opacity: isVisible ? 1 : 0, ...K.defaultIconSize}} source={savedIcon}/>
      </Tooltip>
    )) : (
      <Tooltip pressToView key='issue' text={'Not connected - changes may not save'}>
        <Image dataSet={{'transition-opacity': 1}} style={{...K.defaultIconSize}} source={issueIcon}/>
      </Tooltip>
    );
  }
}
