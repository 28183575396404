import { View, Pressable } from 'react-native';
import { K } from '../../styles';

import React from 'react';
import Text from '../text/text';
import _ from 'lodash';

var style = {
  outerView: {
    // padding: K.spacing
  },
  label: {
    ...K.fonts.label,
    fontSize: K.fonts.label.fontSize - 1,
    lineHeight: 8,
    marginLeft: K.spacing
  },
  innerView: {
    paddingTop: 5
  }
};

export default function LabelledView({styles={}, labelAfter, onPress, ...props}) {
  if (props.inline && props.gray) {
    styles = _.defaultsDeep(styles, {
      outerView: {borderRadius: K.borderRadius, backgroundColor: K.colors.gray, flexDirection: 'row', alignItems: 'center', marginBottom: K.margin},
      innerView: {paddingTop: 0, flexDirection: 'row', flex: 1},
      label: {paddingTop: 0, ...(props.minLabelWidth ? {minWidth: props.minLabelWidth} : {}), marginRight: K.spacing}
    });
  }
  else {
    if (props.gray) styles = _.defaultsDeep(styles, K.grayLabelledViewStyles);
  }

  var ViewComponent = onPress ? Pressable : View;

  return (
    <ViewComponent
      dataSet={{nohover: 1}}
      style={{...style.outerView, ...styles.outerView}}
      focusable={false}
      {...{onPress}}
    >
      {!labelAfter && (<Text style={{...style.label, ...styles.label}}>{props.label}</Text>)}
      <View style={{...style.innerView, ...styles.innerView}}>
        {props.children}
      </View>
      {labelAfter && (<Text style={{...style.label, ...styles.label}}>{props.label}</Text>)}
    </ViewComponent>
  );
};
