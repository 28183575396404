import { K } from '../styles';
import { View, Dimensions, Keyboard, Platform, Animated } from 'react-native';
import { initialWindowMetrics } from 'react-native-safe-area-context';
import { copilot as _copilot, walkthroughable, CopilotStep } from '@symbolic/react-native-copilot';
import React from 'react';
import Button from '../components/button/button';
import Text from '../components/text/text';
import AttentionIndicator from '../components/attention-indicator/attention-indicator';

var autopilotIsActive = false;
var activeCopilotKey;
var keyboardIsOpen = false;
var defaultOptions = {showDone: true, showExit: true};

global.copilotIsActive = false;
global.copilotOptions = {...defaultOptions};

Keyboard.addListener('keyboardWillShow', () => keyboardIsOpen = true);
Keyboard.addListener('keyboardWillHide', () => keyboardIsOpen = false);

var CopilotView = walkthroughable(View);
var AnimatedCopilotView = walkthroughable(Animated.View);

var CopilotStepView = ({order, name, text, hideExit, children, style, disabled=false, animated=false, onPress, ...props}) => {
  if (!name) name = `${order}`;

  var V = animated ? Animated.View : View;
  var CV = animated ? AnimatedCopilotView : CopilotView;

  return disabled ? (
      <V {...{style, ...props}}>
        {children}
      </V>
    ) : (
    <CopilotStep {...{order, name, text, hideExit, onPress, active: !disabled}}>
      <CV {...{style, ...props}}>
        {children}
      </CV>
    </CopilotStep>
  );
};

const Tooltip = ({isLastStep, handleNext, handleStop: _handleStop, currentStep}) => {
  var handleStop = () => {
    if (autopilotIsActive) autopilotIsActive = false;
    if (activeCopilotKey) global.setSessionStore({key: `copilot${activeCopilotKey}Seen`, value: true});

    _handleStop();

    global.copilotOptions = {...defaultOptions};
    global.copilotIsActive = false;
  }

  var buttonProps = {mode: 'dark', style: {backgroundColor: K.colors.blackGray}};

  if (isLastStep) {
    buttonProps = {...buttonProps, label: 'Done', onPress: handleStop};
  }
  else {
    buttonProps = {...buttonProps, label: 'Next', onPress: handleNext};
  }

  return (
    <View style={{flexDirection: 'row', alignItems: 'flex-start'}}>
      <View style={{flex: 1, justifyContent: 'center', marginRight: K.spacing}}>
        <Text style={{color: 'white'}}>{currentStep.text}</Text>
      </View>
      <View>
        {(!isLastStep || global.copilotOptions.showDone) && (
          <Button {...buttonProps}>
            <AttentionIndicator radius={7} style={{marginTop: K.isWeb ? 1 : 0, left: K.isWeb ? 29 : 33}}/>
          </Button>
        )}
        {!isLastStep && global.copilotOptions.showExit && !currentStep.hideExit && (
          <Button mode='dark' style={{marginTop: K.margin, backgroundColor: K.colors.blackGray}} label='Exit' onPress={() => {
            global.setSessionStore({key: 'autopilotDisabled', value: true});

            handleStop();
          }}/>
        )}
      </View>
    </View>
  );
};

var width = K.isWeb ? 400 : Dimensions.get('window').width - (2 * K.spacing);

var tooltipStyle = {
  backgroundColor: 'black',
  borderRadius: K.borderRadius,
  padding: K.spacing,
  width,
  maxWidth: width,
  ...(K.isWeb ? {} : {left: K.spacing})
};

// const svgMaskPath = ({ position, size, canvasSize, step }) => {
//   // if (step && step.name === 'hello') return `M0,0H${canvasSize.x}V${canvasSize.y}H0V0ZM${position.x._value},${position.y._value}Za50 50 0 1 0 100 0 50 50 0 1 0-100 0`;

//   return `M0,0H${canvasSize.x}V${canvasSize.y}H0V0ZM${position.x._value},${position.y._value}H${position.x._value + size.x._value}V${position.y._value + size.y._value}H${position.x._value}V${position.y._value}Z`;
// };

var copilot = (options) => _copilot({
  animated: true,
  overlay: 'svg',
  arrowColor: 'black',
  tooltipStyle,
  tooltipComponent: Tooltip,
  stepNumberComponent: () => null,
  // svgMaskPath,
  verticalOffset: Platform.OS === 'web' ? 1 : (Platform.OS === 'android' ? initialWindowMetrics.insets.top : 0),
  horizontalOffset: Platform.OS === 'web' ? 1 : 0,
  ...options
});

var willCopilot = async ({key, autopilot=false}) => {
  var willCopilot = false;

  if (!autopilot) willCopilot = true;

  var autopilotDisabled = await global.getSessionStore('autopilotDisabled') || await sessionStore.get('autopilotDisabled') || false;

  if (!autopilotDisabled) {
    var autopilotSeen = await global.getSessionStore(`copilot${key}Seen`) || await sessionStore.get(`copilot${key}Seen`) || false;

    if (!autopilotSeen) willCopilot = true;
  }

  return willCopilot;
};

var startCopilot = async ({key, start, options, startArgs=[], autopilot=false, getSessionStore, setSessionStore}) => {
  global = {...global, getSessionStore, setSessionStore};

  if (await willCopilot({key, autopilot})) {
    if (autopilot) autopilotIsActive = true;
    if (keyboardIsOpen) Keyboard.dismiss();

    global.copilotOptions = {...defaultOptions, ...options};

    setSessionStore({key: 'autopilotDisabled', value: false});

    setTimeout(() => {
      activeCopilotKey = key;

      start(...startArgs);
    }, keyboardIsOpen ? 300 : 0);
  }
}

export { copilot, startCopilot, willCopilot, CopilotStepView };
