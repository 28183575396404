var privacyPolicyMarkdownString = `**Last modified: April 7, 2021**

This Symbolic Frameworks Privacy Policy (this " **Policy**") describes the types of information that Symbolic Frameworks, Inc., a Washington corporation (" **Symbolic Frameworks**" " **we** ," " **our** ," or " **us**") may collect from you (" **you**"), that you may provide to us when you download and use our products and services through our mobile applications such as "Protagonist," our website [www.symbolicframeworks.com](http://www.symbolicframeworks.com/) (the " **Website**"), any other websites created and maintained by Symbolic Frameworks or any products or services provided via such websites that link to this Policy (collectively, the " **Services**"). This Policy also describes our practices for collecting, using, maintaining, protecting, and disclosing such information. This Policy does not apply to information collected by us offline or by any third-party that may be accessible or linked to or from the Services.

By accessing or using the Services, you agree to this Policy. If you do not agree with the terms of this Policy, do not use the Services or provide any information to us. This Policy may change from time to time and we will post such changes on the Website. Your continued use of the Services after any revisions we make to this Policy are posted on the Website constitutes your acceptance and agreement to those changes, so please check the Website periodically for updates.

1. **INFORMATION WE COLLECT****.**

We may collect the following types of information when you use the Services:

- " **Personal Information**": information that identifies you or another person as an individual, such as your or someone else's name, mailing address, e-mail address, telephone number, IP address, or any other identifier by which you or any other individual may be contacted online or offline.

- " **Non-Personal Information**": information that does not identify you as an individual, such as your browser type, your internet connection, the URL of the previous web site you visited, the equipment you use to access the Services, and details about how you use the Services.

1. **How We Collect Your Information****.**

We may collect information (i) when you provide it to us, or (ii) automatically through the Services.

Information You Provide to Us. When you use the Services, you may choose to provide us with both Personal and Non-Personal Information, including when you:

- Complete and submit forms on the Services, including registering an account with Symbolic Frameworks.
- Use the Services.
- Contact us.
- Report a problem with the Services.

Information We Collect Automatically. We do not collect Personal Information automatically, with the exception of IP addresses which may be collected automatically and are only used as described below. Other than IP addresses, your location, and your actions while using the Services, we do not collect real-time information about you or your devices via the Services. When you access and use the Services, we may use automatic data collection technologies to automatically collect:

- Usage details (e.g., details regarding your access to and use of the Services); and
- Device information (e.g., information about your computer or mobile device and/or internet connection, including the device's unique device identifier, operating system, browser type, location, and/or mobile network information).

If you do not want us to collect this information then please do not use the Services.

The technologies we use for automatic information collection may include "cookies." A cookie is a small file placed on your browsing device. It may be possible to refuse to accept cookies by activating the appropriate setting on your computer, tablet, or smartphone. However, if you select this setting you may be unable to access certain parts of our Services.

1. **Third Party USE OF COOKIES AND OTHER TRACKING TECHNOLOGIES; DO NOT TRACK**

- Cookies and Other Tracking Technologies. Some content on the Website or Services, including advertisements, may be served by third parties, including advertisers, ad networks and servers, content providers, and application providers. These third parties may use cookies or other tracking technologies to collect information about you when you use the Services. We do not control these third parties' tracking technologies or how they may be used. If you have any questions about an advertisement or other targeted content, you should contact the responsible provider directly.

- Do Not Track. "Do Not Track" is a privacy preference that you can set in your web browser. When you turn on the Do Not Track signal, your browser sends a message to websites requesting them not to track you. For information about Do Not Track, visit www.allaboutdnt.org. At this time, we do not respond to Do Not Track browser settings or signals. In addition, we may use other technology that is standard on the internet, such as pixel tags, web beacons, and other similar technologies, to track visitors to the Website or users of our Services. Those tools may be used by us and by third parties to collect information about you and your internet activity, even if you have turned on the Do Not Track signal.

1. **How We Use Your Information**

We may use the information provided by or collected from you, including Personal Information, in order to:

- Make the Website and Services available to you;
- Provide you with information, products, or Services that you request from us;
- Comply with applicable law;
- Fulfill any other purpose for which you provide or we receive the information; and
- Improve the Website, our products, and the Services.

1. **Disclosure of Your Information**

We do not share your Personal Information with third parties in any manner not described in this Policy.

We may disclose aggregated Non-Personal Information about our users, and information that does not identify any individual, to third parties to help us improve the Website and Services or for any other commercially valid purposes.

We may disclose your Personal Information:

- To contractors, service providers, and other third parties we use to support our business (collectively, " **Service Providers**"). In such circumstances, the Service Providers may be bound by contractual obligations to keep your Personal Information confidential and use it only for the purposes for which we disclose it to them; however, not all such Service Providers will be bound by such contractual obligations. In such an event your Personal Information will be governed by the Service Providers' privacy policies.

- To a buyer or successor in the event of a merger, reorganization, dissolution, or other sale or transfer of all or substantially all of Symbolic Frameworks's assets or equity interests (by merger or otherwise).

- To fulfill the purpose for which you provide your Personal Information or we receive your Personal Information with your authorization. For example, if you give us an email address in order for us to provide you more information about the Website or our Services, we will use that email address or that social media credential to provide the requested information to you.

- For any other purpose disclosed by us when you provide Personal Information.

- With your consent.

We may also disclose your Personal Information:

- To comply with any court order, law, or legal process, including to respond to any government or regulatory request.

- If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of Symbolic Frameworks or its affiliates, customers, or others.

1. **Your Choices and Opting Out**

We may periodically send you email messages describing news, information, updates, or promotional offers related to the Website, our products, or our Services.

You can "opt-out" of receiving emails from us at any time by following the instructions included in your email and we will make the requested changes. Whether you opt out of receiving promotional emails or not, Symbolic Frameworks reserves the right to email or otherwise contact you with respect to important information, important notices, or important changes relating to our products and Services.

1. **Accessing and Correcting Personal Information**

- You can review and change your Personal Information by logging into your account with us; or, if such Personal Information is not available by logging into your account with us, please send us an email at info@symbolicframeworks.com to request access to, or to correct or delete any of your Personal Information in our possession. We may not change or delete information if we believe the change or deletion would violate any law or legal requirement or cause the information to be incorrect.

- California Civil Code Section § 1798.83 permits California residents who use our Website or Services to request certain information regarding our disclosure of Personal Information to third parties for their direct marketing purposes. To make such a request, please send an email to info@symbolicframeworks.com.

1. **Data Security**

The transmission of information via the internet and the storage of such information is not completely secure. Although we have commercially reasonable security measures in place to protect your Personal Information, we cannot guarantee the security of your Personal Information. You acknowledge and agree that you are providing, or allow us to gather, your Personal Information to us at your own risk. We are not responsible for failure or circumvention of any privacy settings or security measures we may have in place to protect your Personal Information.

1. **Children Under the Age of 13**

The Services are not intended for use by children. We do not knowingly collect information from children under the age of 13. If you are under 13, do not use the Services or provide any information to Symbolic Frameworks through the Services. If we learn that Personal Information of a child under the age of 13 is collected, we will delete that information from our systems. If you believe we might have any information from or about a child under 13, please contact us at info@symbolicframeworks.com.

1. **Changes to Our Privacy Policy**

We may modify or amend this Policy from time to time. The date this Policy was last revised is identified at the top of the page and the terms of this Policy are effective as of that date.

1. **Contact Information**

To ask questions or comment about this Policy and our privacy practices, contact us at: info@symbolicframeworks.com.`;

export default privacyPolicyMarkdownString;
