import React, { Component } from 'react';

import { View, TouchableOpacity, Image } from 'react-native';
import { connect } from '@symbolic/redux';
import { withRouter } from 'react-router-native';
import { K } from '../../styles';

import Text from '../text/text';
import Label from '../label/label';
import OrgIcon from '../org-icon/org-icon';
import styleSpread from '../../style-spread';
import styles from './org-picker-input.styles';
import _ from 'lodash';

import editIconWhite from './edit-icon-white.png';
import editIconBlack from './edit-icon-black.png';

var s = styleSpread(styles);

class OrgPickerInput extends Component {
  render() {
    var {orgs, activeOrg} = this.props.session;

    if (this.props.hideActiveOrg) orgs = _.reject(orgs, {id: activeOrg.id});

    return (
      <View {...s.orgs}>
        {_.map(_.sortBy(orgs, org => org.type === 'personal' ? 0 : 1), org => {
          var isIndividualOrg = _.get(this, 'props.session.user.personalOrgId') === org.id;

          return (
            <TouchableOpacity
              key={org.id}
              style={{...styles.orgDescriptionContainer, backgroundColor: K.colors.gray, borderRadius: K.borderRadius}}
              dataSet={(isIndividualOrg ? {nohover: 1} : {})}
              onPress={() => {
                if (!isIndividualOrg) {
                  if (this.props.hideMenu) this.props.hideMenu();

                  setTimeout(() => {
                    this.props.history.push(`/edit-workspace/${org.id}`);
                  });
                }
              }}
            >
              {!isIndividualOrg && org.role === 'owner' && (<Image source={editIconBlack} {...s.editImage}/>)}
              <View {...s.orgTextContainer}>
                <Text style={{marginBottom: 5}}>{org.title}</Text>
                <Label style={{opacity: 0.3}}>{org.type === 'personal' ? 'private' : org.type}</Label>
              </View>
              <OrgIcon {...{org}} hideBorder/>
            </TouchableOpacity>
          );
        })}
      </View>
    );
  }
}

export default withRouter(connect({})(OrgPickerInput));
