import React, { Component, Fragment } from 'react';
import { connect } from '@symbolic/redux';
import SubscriptionPopup from '../subscription-popup/subscription-popup';
import getTrialStatus from '../../get-trial-status';
import _ from 'lodash';

class AccessManager extends Component {
  state = {showingPopup: false};

  get status() {
    return getTrialStatus({session: this.props.session});
  }

  showSubscriptionPopup = () => {
    this.setState({showingPopup: true});
  }

  get behavior() {
    return this.props.behaviorMap ? this.props.behaviorMap[this.status] : 'access';
  }

  render() {
    var {children, behaviorMap, webMessage=null, mobileMessage=null} = this.props;

    return (
      <Fragment>
        {(_.includes(['active', 'trial'], this.status) || !behaviorMap || this.behavior === 'show') ? (
          children
        ) : (
          this.behavior === 'showPopup' ? (
            React.cloneElement(children, {overrideOnPress: this.showSubscriptionPopup})
          ) : null
        )}
        {this.state.showingPopup && <SubscriptionPopup onClose={() => this.setState({showingPopup: false})} {...{webMessage, mobileMessage}}/>}
      </Fragment>
    )
  }
}

export default connect({
  mapState: state => ({session: state.session})
})(AccessManager);
