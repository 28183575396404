import React, { Component } from 'react';

import { TouchableOpacity } from 'react-native';
import { connect } from '@symbolic/redux';
import { withRouter } from 'react-router-native';
import { api } from '@symbolic/lib';
import { K } from '../../styles';

import Button from '../button/button';
import styleSpread from '../../style-spread';
import styles from './manage-subscription-button.styles';
import _ from 'lodash';

var s = styleSpread(styles);

class ManageSubscriptionButton extends Component {
  handlePress = async () => {
    var {user} = this.props;

    var subscriptionMethod = _.get(user, 'protagSubData.method');
    var subscriptionStatus = _.get(user, 'protagSubData.status');

    if (subscriptionStatus === 'active') {
      if (user.stripeCustomerId) {
        if (K.isWeb) {
          try {
            var {data} = await api.request({uri: '/payments/create-portal-session', body: {
              returnUrl: window.location.origin
            }});
          }
          catch(err) {
            alert('Something went wrong. Please close the app and try again.');
          }

          window.location.href = data.portalSessionUrl;
        }
        else {
          alert('We are unable to access your Stripe account from mobile. Please use the Protagonist desktop app to manage your subscription. We apologize for the inconvenience.');
        }
      }
      else if (subscriptionMethod === 'apple') {
        alert(`Please go to Settings > Apple ID > Subscriptions > Protagonist on your phone to manage your subscription`);
      }
      else if (subscriptionMethod === 'google') {
        alert(`We are unable to access Google's account settings from desktop. Please go to your Google Play account settings to manage your subscription. We apologize for the inconvenience.`);
      }
    }
    else {
      if (this.props.hideMenu) this.props.hideMenu();

      setTimeout(() => this.props.history.push('/billing'));
    }
  }

  render() {
    return (
      <Button style={this.props.style} label={'Billing'} onPress={this.handlePress}/>
    );
  }
}

export default withRouter(connect({
  mapState: state => ({
    user: state.session.user,
  }),
  mapDispatch: {}
})(ManageSubscriptionButton));
