import React, { Component } from 'react';
import { View, TouchableOpacity, Image, Dimensions } from 'react-native';
import Text from '../text/text';
import TextInput from '../text-input/text-input';
import Popup from '../popup/popup';
import styleSpread from '../../style-spread';
import CalendarPicker from 'react-native-calendar-picker';

import lib from '@symbolic/lib';
import moment from 'moment';
import _ from 'lodash';
import { K } from '../../styles';
import styles from './date-input.styles';

import previousIcon from '../../assets/left-arrow.png';
import nextIcon from '../../assets/right-arrow.png';

var s = styleSpread(styles);

class DateInput extends Component {
  state = {isShowingPopup: false, shouldClosePopup: false};

  showPopup = () => this.setState({isShowingPopup: true});
  startClosingPopup = () => this.setState({shouldClosePopup: true});
  handlePopupClose = () => this.setState({shouldClosePopup: false, isShowingPopup: false});

  render() {
    var {prefix='', onChange, value} = this.props;
    var {startClosingPopup, handlePopupClose} = this;
    var {shouldClosePopup, isShowingPopup} = this.state;
    var hasValue = !!value;

    return (
      <TouchableOpacity style={{...styles.dateInput, ...this.props.style}} onPress={this.showPopup} focusable={false}>
        <Text style={!hasValue ? {...K.fonts.label, opacity: 0.3, ...this.props.textStyle} : {...K.fonts.label, opacity: 1, ...this.props.textStyle}}>
          {hasValue ? prefix + lib.date.formatDate(value).toUpperCase() : this.props.placeholder || ''}
        </Text>
        {isShowingPopup && <DatePopup {...{onChange, value, startClosingPopup, handlePopupClose, shouldClosePopup}}/>}
      </TouchableOpacity>
    );
  }
}

function DatePopup(props) {
  var today = moment();
  var dayOfWeekIndex = moment().day();

  var quickValueRows = [
    [
      {isEnabled: true, title: 'No Date', value: null, buttonStyle: {
        borderWidth: 1, borderColor: '#ddd', backgroundColor: 'transparent'
      }, selectedStyles: {button: {borderColor: 'black'}}},
      {isEnabled: true, title: 'Today', value: today.clone()},
      {isEnabled: true, title: 'Tomorrow', value: today.clone().add(1, 'day')}
    ],
    [
      {isEnabled: dayOfWeekIndex <= 2, title: 'Thursday', value: moment(today).add(4 - dayOfWeekIndex, 'day')}, //<= Tue - Thu
      {isEnabled: dayOfWeekIndex <= 4, title: 'Saturday', value: moment(today).add(6 - dayOfWeekIndex, 'day')}, //<= Thu - Sat
      {isEnabled: dayOfWeekIndex > 0, title: 'Next Monday', value: moment(today).add(8 - dayOfWeekIndex, 'day')} //> Sun - Mon
    ]
  ];

  var changeAndHide = ({value}) => {
    props.onChange({value: value ? value.local() : null});

    setTimeout(() => props.startClosingPopup());
  }

  var customDatesStyles = date => {
    if (date.isSame(moment(), 'date') && !(props.value && date.isSame(props.value, 'date'))) {
      return {style: {
        backgroundColor: 'transparent',
        borderWidth: 1,
        borderColor: '#ddd',
        borderRadius: K.borderRadius,
        width: 44,
        height: 40
      }};
    }
  };

  var selectedDate = props.value || undefined;

  if (selectedDate) selectedDate = moment(selectedDate.format('YYYY-MM-DD'))

  var calendarPickerSize = K.isWeb ? K.standardColumnWidth : Dimensions.get('window').width;

  return (
    <Popup shouldClose={props.shouldClosePopup} onClose={props.handlePopupClose} {...s.popup}>
      {/* <TextInput/> */}
      <View {...s.quickValues}>
        {_.map(quickValueRows, (quickValues, row) => (
          <View key={row} style={{marginBottom: row === 0 ? 5 : 0, ...styles.quickValueRow}}>
            {_.map(quickValues, ({isEnabled, title, value, buttonStyle}, index) => {
              var ButtonElement = isEnabled ? TouchableOpacity : View;
              var isSelected = value ? props.value && value.isSame(props.value, 'date') : !props.value;

              return (
                <ButtonElement
                  key={index}
                  style={{
                    ...styles.quickValueButton,
                    ...buttonStyle,
                    ...(isSelected ? (value ? {backgroundColor: 'black'} : {borderColor: 'black'}) : {}),
                    marginRight: index < quickValues.length - 1 ? 5 : 0,
                    opacity: isEnabled ? 1 : 0
                  }}
                  onPress={() => changeAndHide({value})}
                >
                  <Text style={{...styles.quickValueText, color: isSelected && value ? 'white' : 'black'}}>{title}</Text>
                </ButtonElement>
              );
            })}
          </View>
        ))}
      </View>
      <View {...s.calendarBackground}>
        <CalendarPicker
          {...styles.calendar}
          {...{customDatesStyles}}
          width={calendarPickerSize}
          height={calendarPickerSize}
          customDatesStylesPriority={0}
          weekdays={['S', 'M', 'T', 'W', 'T', 'F', 'S']}
          initialDate={selectedDate}
          selectedStartDate={selectedDate}
          previousComponent={<View {...s.previousNextIconButton}><Image {...s.previousNextIcon} source={previousIcon}/></View>}
          nextComponent={<View {...s.previousNextIconButton}><Image {...s.previousNextIcon} source={nextIcon}/></View>}
          onDateChange={(value) => changeAndHide({value})}
          todayTextStyle={{color: props.value && moment().isSame(props.value, 'date') ? 'white' : 'black'}}
        />
      </View>
    </Popup>
  )
}

export default DateInput;
