import { TouchableOpacity, Image, View} from 'react-native';
import { K } from '../../styles';
import Text from '../text/text';
import Label from '../label/label';
import React, { PureComponent, Fragment } from 'react';
import _ from 'lodash';

export default class Button extends PureComponent {
  render() {
    var {text, label, icon, iconSize, mode='light', widthAuto=false, onPress, overrideOnPress, dark, ...props} = this.props;
    var {children} = props;

    if (dark) mode = dark;

    if (overrideOnPress) onPress = overrideOnPress;

    var buttonStyle = {
      ...K.button,
      position: 'relative',
      height: K.button.height,
      // flex: (widthAuto || text || label) ? 0 : undefined,
      width: (widthAuto || text || label) ? 'auto' : K.button.width,
      backgroundColor: mode === 'light' ? K.colors.gray : 'black',
      paddingHorizontal: (text || label) ? K.spacing : 0,
    };

    if (props.alignLeft) {
      buttonStyle = {...buttonStyle, alignItems: 'flex-start', paddingHorizontal: K.spacing};
    }

    buttonStyle = {...buttonStyle, ...props.style};

    if (text) {
      children = <Text style={{color: mode === 'light' ? 'black' : 'white', ...props.textStyle}}>{text}</Text>;
    }
    if (label) {
      children = <Label style={{color: mode === 'light' ? 'black' : 'white', opacity: 1, ...props.textStyle}}>{label}</Label>;
    }
    if (icon) {
      children = <Image source={icon} style={{...(iconSize || {width: K.calc(20), height: K.calc(20)}), ...props.iconStyle}}/>
    }
    if (icon && (label || text)){
      children =
      <View style={{width: '100%', flexDirection: 'row', alignItems: 'center'}}>
        <Image source={icon} style={{...(iconSize || {width: K.calc(20), height: K.calc(20)}), marginRight: K.spacing, ...props.iconStyle}}/>
        {text &&
          <Text style={{color: mode === 'light' ? 'black' : 'white', width: '50%', ...props.textStyle}}>{text}</Text>
        }
        {label &&
          <Label style={{color: mode === 'light' ? 'black' : 'white', opacity: 1, width: '50%', ...props.textStyle}}>{label}</Label>
        }
      </View>
    }

    /* HINT render additional components in addition to text/icon */
    if ((text || label || icon) && props.children) {
      children = <Fragment>{children}{props.children}</Fragment>
    }

    var ButtonComponent = props.component || TouchableOpacity;

    return (
      <ButtonComponent
        focusable={false}
        style={buttonStyle}
        onPress={onPress}
        {..._.omit(props, ['textStyle', 'style'])}
        {...(icon ? {dataSet: {'has-icon': 1, ...props.dataSet}} : {})}
      >
        {children}
      </ButtonComponent>
    );
  }
}
