import React, { Component } from 'react';
import { Platform, View, KeyboardAvoidingView, StatusBar, Text } from 'react-native';
import { SafeAreaProvider, useSafeAreaInsets } from 'react-native-safe-area-context';
import { NativeRouter, Route, Switch, withRouter } from 'react-router-native';
import { BrowserRouter } from 'react-router-dom';
import * as Linking from 'expo-linking';
import { K } from '../../styles';
import { connect, logIn, logOut, updateUserProps, resetPassword } from '@symbolic/redux';
import { api } from '@symbolic/lib';

import * as ExpoNotifications from 'expo-notifications';
import prepareToAnimate from '../../prepare-to-animate';
import Header from '../header/header';
import AuthView from '../views/auth-view/auth-view';
import EditOrgView from '../views/edit-org/edit-org-view';
import OrgInviteAccept from '../views/org-invite-accept/org-invite-accept';
import MobileWebView from '../views/mobile-web-view/mobile-web-view';
import MyAccountView from '../views/my-account/my-account-view';
import IntroSlider from '../intro-slider/intro-slider';
import IntroQuestionnaire from '../intro-questionnaire/intro-questionnaire';
import Url from 'url-parse';
import queryString from 'query-string';
import _ from 'lodash';
import Label from '../label/label';
import Link from '../link/link';
import Button from '../button/button';
import TextInput from '../text-input/text-input';

var AppRouter = Platform.OS === 'web' ? BrowserRouter : NativeRouter;

var RouterPropsRetriever = withRouter(props => {
  props.getRouterProps && props.getRouterProps(_.pick(props, ['location', 'history']));

  return null;
});

function HookedStatusBar({hidden=false}) {
  const insets = useSafeAreaInsets();

  return (
    <View style={{backgroundColor: !hidden ? 'black' : 'white', height: insets.top}}>
      <StatusBar barStyle={`light-content`} backgroundColor={'black'} hidden={hidden}/>
    </View>
  );
}

class AppWrapper extends Component {
  state = {isLoggingInOnMount: true, introSliderSeen: false, hasRequestedUsers: false}

  constructor(props) {
    super(props);

    this.handleLogOut = this.handleLogOut.bind(this);
  }

  async componentDidMount() {
    var token = await sessionStore.getToken();
    var activeOrgId = await sessionStore.get('activeOrgId');
    var introSliderSeen = (await sessionStore.get('introSliderSeen')) || false;

    if (token) {
      await this.props.logIn({token, activeOrgId});
    }
    else {
      await this.props.logOut({shouldRemoveToken: false});

      this.setState({hasRequestedUsers: false});
    }

    var initialUrl = await Linking.getInitialURL();

    this.setState({initialUrl, introSliderSeen, introSliderSeenPreviously: introSliderSeen});
  }

  async componentDidUpdate() {
    if (!this.props.session.isLoading && this.state.isLoggingInOnMount) {
      this.setState({isLoggingInOnMount: false});
    }

    if (this.props.session.user && !this.state.hasRequestedUsers) {
      var usersData = await api.request({uri: '/get-users', body: {}});
      var users = _.get(usersData, 'data.users', []);

      this.props.trackUsers({users});

      this.setState({hasRequestedUsers: true});
    }

    var {unseenNotificationsCount} = this.props;

    if (unseenNotificationsCount !== this.unseenNotificationsCount) {
      this.unseenNotificationsCount = unseenNotificationsCount;

      await ExpoNotifications.setBadgeCountAsync(unseenNotificationsCount, {web: {favicon: {
        backgroundColor: 'red',
        color: '#ffffff',
        //radius: 11,
        size: 9,
        horizontalMargin: 0,
        verticalMargin: 0,
        horizontalPadding: 3,
        verticalPadding: 3
      }}});
    }
  }

  handleIntroSliderDone = async ({history}) => {
    await this.props.setSessionStore({key: 'introSliderSeen', value: true});

    this.setState({introSliderSeen: true}, () => {
      this.sendToInitialUrl({history});
    });
  }

  handleIntroQuestionnaireDone = async ({history}) => {
    await this.props.setSessionStore({key: 'introQuestionnaireSeen', value: true});

    this.setState({introQuestionnaireSeen: true}, () => {
      if (!this.introSliderSeen) {
        history.push('/intro-slides');
      }
      else {
        this.sendToInitialUrl({history});
      }
    });
  }

  sendToInitialUrl({history}) {
    prepareToAnimate();

    var {initialUrl} = this.state;

    if (initialUrl) {
      var initialUrlPathName = (new Url(initialUrl)).pathname;

      history.push(initialUrlPathName);
    }
    else {
      history.push('/');
    }
  }

  handleLogOut() {
    this.setState({initialUrl: ''});
  }

  get isGuest () {
    return _.get(this.props.session, 'user.isGuest');
  }

  //WARNING all these conditions are sensitive - don't remove any without clear understanding
  get shouldShowIntroSlider() {
    return !this.introSliderSeen
      && this.props.session.isLoggedIn
      && this.props.introSlides
      && this.isGuest === 0 //HINT don't show to guests
  }

  get shouldShowIntroQuestionnaire() {
    return !(this.state.introSliderSeenPreviously || _.get(this.props.session, `user.appData.${this.props.appKey}.introSliderSeen`)) //HINT don't show to existing users
      && this.props.session.isLoggedIn
      && this.isGuest === 0 //HINT don't show to guests
      && this.props.appKey === 'weflowLite' //HINT only show for weflow
      && !_.get(this.props.session, `user.appData.${this.props.appKey}.introQuestionnaireSeen`);
  }

  //WARNING need to maintain old cookie method for a while so we don't re-show intro slider to early-stage users
  get introSliderSeen() {
    return this.state.introSliderSeen || _.get(this.props.session, `user.${this.appDataPath}.introSliderSeen`) === true;
  }

  get appDataPath() {
    return this.state.introQuestionnaireSeen || this.props.appKey === 'protagonist' ? 'protagonistData' : `appData.${this.props.appKey}`;
  }

  render() {
    var isLoggingInOnMount = this.state.isLoggingInOnMount || this.props.session.isLoggedIn === undefined;
    var {initialUrl} = this.state;
    var {isLoggedIn} = this.props.session;
    var {appName, appKey, appDescription, appTagline, hideBranding} = this.props;
    var {user} = this.props.session;
    var hasPassword = _.get(this.props.session, 'user.hasPassword');
    var oneTimeCode;

    if (typeof(location) !== 'undefined') {
      var oneTimeCode;

      const myURL = new URL(location.href);

      myURL.searchParams.forEach((value, name) => {
        if (name === 'oneTimeCode') oneTimeCode = value;
      });
    }

    return isLoggingInOnMount ? null : (
      (!hasPassword && oneTimeCode && user) ? (
        <View style={{flex: 1, alignItems: 'center', justifyContent: 'center'}}>
          <View style={{justifyContent: 'center'}}>
            <View style={{marginBottom: K.spacing, textAlign: 'center'}}>
              <Text style={{marginBottom: K.spacing}}>Set a password to enable your account</Text>
            </View>
            <View>
              <TextInput type='email' label='Email' value={_.get(user, 'email')} disabled grayLabelledView/>
              <TextInput label='First Name' value={_.get(user, 'firstName')} onChange={({value}) => this.setState({firstName: value})} grayLabelledView/>
              <TextInput label='Last Name' value={_.get(user, 'lastName')} onChange={({value}) => this.setState({lastName: value})} grayLabelledView/>
            </View>
            <View style={{marginBottom: K.spacing * 2}}>
              <Text style={{opacity: 0.8, fontSize: '75%', textAlign: 'center', marginBottom: K.spacing}}>
                Password must be at least 8 characters.
              </Text>
              <TextInput secureTextEntry type='password' label='Password' onChange={({value}) => this.setState({password: value})} grayLabelledView/>
              <TextInput secureTextEntry type='password' label='Confirm' onChange={({value}) => this.setState({confirmPassword: value})} grayLabelledView/>
            </View>
            <Button
              dark
              label='Submit'
              onPress={async () => {
                var {password, confirmPassword, firstName, lastName} = this.state;

                var passwordsMatch = password === confirmPassword;
                var passwordMeetsRequirements = password && password.length >= 8;

                if (!passwordsMatch) alert('Passwords do not match.');
                else if (!passwordMeetsRequirements) alert('Password does not meet requirements.');
                else {
                  var body = {email: user.email, password, passwordResetToken: oneTimeCode};

                  await api.request({uri: '/reset-password', body});

                  var userProps = {hasPassword: true};

                  if (firstName) userProps.firstName = firstName;
                  if (lastName) userProps.lastName = lastName;

                  this.props.updateUserProps({props: userProps});

                  window.location.history.push('/');
                }
              }}
            />
          </View>
        </View>
      ) : (
      K.isMobileWeb ? (
        <View style={{height: '100%'}}>
          <MobileWebView appKey={appKey} appName={appName}/>
        </View>
      ) : (
      <SafeAreaProvider>
        <KeyboardAvoidingView behavior={Platform.OS === 'ios' ? 'padding' : null}>
          <AppRouter>
            <RouterPropsRetriever getRouterProps={this.props.getRouterProps}/>
            <View style={{height: '100%'}}>
              <Switch>
                <Route exact path='/intro-questionnaire' render={({history}) => (
                  <IntroQuestionnaire
                    sessionStore={this.props.setSessionStore}
                    appName={this.props.appName}
                    appKey={this.props.appKey}
                    onDone={() => this.handleIntroQuestionnaireDone({history})}
                  />
                )}/>
                {this.props.introSlides && (
                  <Route exact path='/intro-slides' render={({history}) => (
                    <IntroSlider
                      appName={appName}
                      appTagline={appTagline}
                      slides={this.props.introSlides}
                      onSlideChange={this.props.onIntroSliderSlideChange}
                      onDone={() => this.handleIntroSliderDone({history})}
                    />
                  )}/>
                )}
                <Route path={['/404/workspace']} render={({match}) => {
                  return (
                    <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
                      <Label style={{paddingHorizontal: K.spacing, marginBottom: K.spacing * 3}}>{`Oops! Workspace not found.`}</Label>
                      <Label style={{paddingHorizontal: K.spacing, marginBottom: K.spacing * 3, textAlign: 'center'}}>{`You might need to ask for an invite or it might have been deleted`}</Label>
                      <Link to='/' style={{...K.button, backgroundColor: K.colors.gray, paddingHorizontal: K.spacing, width: 'auto'}}><Label>Go to your dashboard</Label></Link>
                    </View>
                  );
                }}/>
                <Route render={({history, location}) => {
                  if (this.shouldShowIntroSlider && !location.pathname.includes('intro-slides')) {
                    history.push('/intro-slides');

                    return null;
                  }
                  else if (this.shouldShowIntroQuestionnaire && !location.pathname.includes('intro-questionnaire')) {
                    history.push('/intro-questionnaire');

                    return null;
                  }
                  else {
                    return (
                      isLoggedIn ? (<React.Fragment>
                        <HookedStatusBar/>
                        <View style={{flex: 1}}>
                          {!this.props.hideHeader && (
                            <Header onLogOut={this.handleLogOut} {..._.pick(this.props, ['noButtonColor', 'accentColor', 'headerContent', 'headerContentProps', 'menuButtonStyle', 'headerContentContainerStyle', 'headerStyle', 'onNotificationPress', 'allowOrgSwitching', 'Notifications', 'appKey', 'appName'])}/>
                          )}
                          <View style={{flex: 1, position: 'relative'}}>
                            {this.props.children}
                            <Switch>
                              <Route exact path='/my-account' render={(props) => (
                                <MyAccountView {...props} appName={this.props.appName}/>
                              )}/>
                              <Route exact path='/edit-workspace/:orgId' render={props => (<EditOrgView {...props} appKey={appKey} appName={appName}/>)}/>
                              <Route exact path={['/workspace/invite/accept/:orgInviteId/:code/', '/workspace/invite/accept/:orgInviteId/:code/:email']} render={props => (<OrgInviteAccept {...props}/>)}/>
                            </Switch>
                          </View>
                        </View>
                      </React.Fragment>) : (
                        <AuthView {...{appName, appKey, appDescription, initialUrl, hideBranding}}/>
                      )
                    );
                  }
                }}/>
              </Switch>
            </View>
          </AppRouter>
        </KeyboardAvoidingView>
      </SafeAreaProvider>
    )));
  }
}

export default connect({
  mapState: state => ({
    session: state.session,
    unseenNotificationsCount: _.filter(state.resources.notifications.byId, {status: 'unseen'}).length
  }),
  mapDispatch: {logIn, logOut, updateUserProps, resetPassword}
})(AppWrapper);
