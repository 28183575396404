import React, { PureComponent, Fragment } from 'react';

import { Picker } from '@react-native-picker/picker';
import { View, Platform, TouchableOpacity, Modal, Pressable, Image } from 'react-native';
import { CopilotStepView } from '../../copilot/copilot';
import { K } from '../../styles';

import Text from '../text/text';
import styleSpread from '../../style-spread';
import styles from './picker-input.styles';
import prepareToAnimate from '../../prepare-to-animate';
import downArrow from './down-arrow.png';
import _ from 'lodash';

var s = styleSpread(styles);

class PickerInput extends PureComponent {
  state = {
    showingPicker: false
  }

  constructor(props) {
    super(props);

    this.showPicker = this.showPicker.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handlePickerChange = this.handlePickerChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
  }

  showPicker() {
    if (this.enabled) {
      prepareToAnimate();

      this.setState({showingPicker: true});
    }
  }

  handlePickerChange(value) {
    this.setState({pickerValue: value});
  }

  handleSelectChange(event) {
    var value = event.target.value;

    this.setState({pickerValue: value});

    this.props.onChange({value});
  }

  handleBlur() {
    var value = this.state.pickerValue;

    if (value && value !== this.props.value) {
      this.props.onChange({value});
    }

    prepareToAnimate();

    this.setState({showingPicker: false, pickerValue: undefined});
  }

  get activeOption() {
    return _.find(this.props.options, {value: this.props.value})
  }

  get displayValue() {
    return _.get(this.activeOption, 'title', '--');
  }

  get backgroundColor() {
    return _.get(this.activeOption, 'color', undefined);
  }

  get enabled() {
    var {enabled} = this.props;

    if (enabled === undefined) enabled = true;

    return enabled;
  }

  render() {
    var {backgroundColor, showPicker, displayValue, enabled} = this;
    var {textStyle, basic, inline} = this.props;

    return (
      <View style={{...(K.isWeb ? {position: 'relative'} : {}), ...this.props.style}}>
        {inline ? (
          <View style={{...styles.inlineContainer, ...(basic ? {flexDirection: 'row'} : {})}}>
            {_.map(this.props.options, (option, index) => {
              var style = {...styles.input, ...(K.isWeb ? {cursor: 'pointer'} : {}), ...this.props.buttonStyle};
              var textStyle = {...K.fonts.standard};
              var isActive = option.value === this.props.value;
              var containerStyle = basic ? {...this.props.containerStyle} : {minWidth: isActive ? '35%' : '13%', ...this.containerStyle};

              if (index === 0) style = {...style, ...styles.leftButtonStyle, ...this.props.leftButtonStyle};
              if (index === this.props.options.length - 1) style = {...style, ...styles.rightButtonStyle, ...this.props.rightButtonStyle};
              if (index !== 0) containerStyle = {...containerStyle, marginLeft: 1};

              if (basic) {
                textStyle = {opacity: 1, color: (isActive ? 'white' : 'black'), ...this.props.textStyle};
                style = {...style, ...(isActive ? {...this.props.activeStyle} : {}), paddingHorizontal: K.margin, flex: 1, flexDirection: 'row', backgroundColor: (isActive ? (this.props.activeColor || 'black') : (this.props.inactiveColor || K.colors.doubleGray)), ...(!option.copilotProps ? containerStyle : {}), opacity: enabled ? 1 : 0.5, ...option.style};
                containerStyle = {...containerStyle, flex: 1};
              }
              else {
                style = {...style, opacity: isActive ? 1 : 0.2, ...(!option.copilotProps ? containerStyle : {}), backgroundColor: option.color};
              }

              var button = (
                <Pressable
                  key={option.value}
                  style={style}
                  focusable={false}
                  onPress={() => {
                    if (enabled) {
                      this.props.onChange({value: option.value});
                    }
                  }}
                >
                  <Text style={{...textStyle}}>{basic ? option.title : (isActive ? option.title : option.title[0])}</Text>
                </Pressable>
              );

              return option.copilotProps ? (
                <CopilotStepView style={(option.copilotProps ? containerStyle : {})} {...option.copilotProps} key={option.value}>{button}</CopilotStepView>
              ) : button;
            })}
          </View>
        ) : (
          K.isWeb ? (<Fragment>
            <Picker
              autoComplete={this.props.autocomplete}
              style={{
                ...styles.input,
                ...styles.buttonStyle,
                ...K.fonts.standard,
                flexDirection: 'row',
                paddingHorizontal: K.spacing,
                paddingRight: K.spacing + (this.props.showDownArrow ? 15 : 0),
                minWidth: 60,
                flex: 1, ...(backgroundColor ? {backgroundColor} : {}),
                ...((this.state.pickerValue || this.props.value) === this.props.placeholderValue ? {...K.fonts.label, color: 'rgb(179, 179, 179)' || this.props.placeholderColor} : {}),
                ..._.omit(textStyle, ['opacity']),
                ...this.props.buttonStyle
              }}
              selectedValue={this.state.pickerValue || this.props.value}
              onChange={this.handleSelectChange}
              enabled={enabled}
              {..._.pick(this.props, ['dataSet', 'tabIndex'])}
            >
              {_.map(this.props.options, ({value, title}) => (
                <Picker.Item label={title} value={value} key={title + '' + value} style={{...K.fonts.standard}}/>
              ))}
            </Picker>
            {this.props.showDownArrow && (
              <Image source={downArrow} pointerEvents='none' style={{position: 'absolute', top: '50%', marginTop: -2, right: 10, width: 9, height: 5, opacity: 0.5}}/>
            )}
          </Fragment>) : (
            <TouchableOpacity
              onPress={showPicker}
              style={{...styles.input, ...styles.buttonStyle, opacity: enabled ? 1 : 0.5, flexDirection: 'row', justifyContent: 'flex-start', paddingHorizontal: K.spacing, minWidth: 60, flex: 1, ...(backgroundColor ? {backgroundColor} : {}), ...this.props.buttonStyle}}
            >
              <Text style={{...(this.props.flex ? {flex: 1} : {}), ...textStyle}}>{displayValue}</Text>
              {this.props.showDownArrow && (
                <Image source={downArrow} style={{width: 10, height: 6, opacity: 0.5, marginLeft: K.margin}}/>
              )}
            </TouchableOpacity>
          )
        )}
        {this.state.showingPicker && (
          <Modal transparent={true}>
            <View {...s.modalView}>
              <TouchableOpacity onPress={this.handleBlur} style={{flex: 1}}/>
              <View {...s.tray}>
                <Picker style={{...(Platform.OS === 'android' ? {height: 100} : {})}} selectedValue={this.state.pickerValue || this.props.value} onValueChange={this.handlePickerChange} enabled={enabled}>
                  {_.map(this.props.options, ({value, title}) => (
                    <Picker.Item label={title} value={value} key={value} style={{...K.fonts.standard}}/>
                  ))}
                </Picker>
              </View>
            </View>
          </Modal>
        )}
      </View>
    );
  }
}

export default PickerInput;
