import React, { Component } from 'react';
import Svg, { Path } from 'react-native-svg';

import { View, TouchableOpacity } from 'react-native';

import Label from '../label/label';
import lib from '@symbolic/lib';
import styleSpread from '../../style-spread';
import styles from './org-icon.styles';
import _ from 'lodash';

var s = styleSpread(styles);

class OrgIcon extends Component {
  render() {
    var {org, onPress} = this.props;
    var orgColor = lib.colors.colorFor({org});
    var words = _.map(_.split(org.title, ' '), word => word.replace(/[\W_]+/g, ''));
    var initials = '';

    if (words.length === 1) {
      initials = words[0].substring(0, 1);
    }
    else {
      initials = _.join(_.map(_.filter(words, (word, w) => w < 2 && word.length > 0), word => word[0]), '');
    }

    var IconComponent = onPress ? TouchableOpacity : View;

    return (
        <IconComponent style={{...styles.orgIcon, ...this.props.style}} {...{onPress}} focusable={false}>
          <Svg height="100%" width="100%" viewBox="0 0 100 118" focusable={false}>
            <Path
              focusable={false}
              {...({stroke: 'black', strokeWidth: this.props.hideBorder ? 0 : 2})}
              d={`M38,2
                L82,2
                A12,12 0 0,1 94,10
                L112,44
                A12,12 0 0,1 112,56
                L94,90
                A12,12 0 0,1 82,98
                L38,98
                A12,12 0 0,1 26,90
                L8,56
                A12,12 0 0,1 8,44
                L26,10
                A12,12 0 0,1 38,2`}
              transform='rotate(-28 0 0) translate(-46, 26)'
              fill={orgColor}
            />
          </Svg>
          <Label style={{...styles.orgIconInitials, ...this.props.initialsStyle}}>{initials}</Label>
        </IconComponent>
    );
  }
}

export default OrgIcon;
