import _ from 'lodash';

var processTypeCategories = [
  {title: 'View Business Templates', content: {
    subCategories: [
      {title: 'Coordination with Clients', processTypes: [
        {title: 'Architectural Client', pluralTitle: 'Architectural Clients', processSteps: [
          {title: 'Kickoff Meeting', color: 'orange'},
          {title: 'Contact Info', color: 'orange'},
          {title: 'Project Info', color: 'orange', substeps: [
            {title: 'Material Preferences', color: 'orange', inputType: 'text'},
            {title: 'Functional Requirements', color: 'orange', inputType: 'text'},
            {title: 'Aesthetic Directions', color: 'orange', inputType: 'text'},
          ]},
          {title: 'Design', color: 'purple', substeps: [
            {title: 'Schematic Design', color: 'purple'},
            {title: 'Design Development 1', color: 'purple'},
            {title: 'Design Development 2', color: 'purple'},
            {title: 'Design Final', color: 'purple'},
            {title: 'Signed off', color: 'purple'},
          ]},
          {title: 'Permit', color: 'blue'},
          {title: 'Construction', color: 'blue'},
          {title: 'Project Complete', color: 'green'}
        ]},
        {title: 'Contract Job', pluralTitle: 'Contract Jobs', processSteps: [
          {title: 'Consultation', color: 'orange'},
          {title: 'Proposal & Quote', color: 'purple'},
          {title: 'Proposal Signed', color: 'purple'},
          {title: 'Deposit Paid', color: 'purple'},
          {title: 'Work Started', color: 'blue'},
          {title: 'Work Complete', color: 'blue'},
          {title: 'Paid in Full', color: 'green'},
        ]},
        {title: 'Customer Delivery', pluralTitle: 'Customer Deliveries', processSteps: [
          {title: 'Delivery Prep', color: 'orange', substeps: [
            {title: 'Confirm Delivery Address', color: 'orange', inputType: 'text'},
            {title: 'Confirm Delivery Date', color: 'orange', inputType: 'text'},
          ]},
          {title: 'Ship', color: 'purple'},
          {title: 'Delivery Received', color: 'green'},
        ]}
      ]},
      {title: 'Coordination with Business Partners', processTypes: [
        {title: 'Product Collaboration', pluralTitle: 'Product Collaborations', processSteps: [
          {title: 'Brainstorm', color: 'orange'},
          {title: 'Define Requirements', color: 'orange'},
          {title: 'Agree on Direction', color: 'orange'},
          {title: 'Design 1', color: 'purple'},
          {title: 'Design 2', color: 'purple'},
          {title: 'Design 3', color: 'purple'},
          {title: 'Prototype 1', color: 'blue'},
          {title: 'Prototype 2', color: 'blue'},
          {title: 'Prototype 3', color: 'blue'},
          {title: 'Optimize for manufacturing', color: 'green'}
        ]},
        {title: 'Creative Collaboration', pluralTitle: 'Creative Collaborations', processSteps: [
          {title: 'Brainstorm', color: 'orange'},
          {title: 'Define Requirements', color: 'orange'},
          {title: 'Agree on Direction', color: 'orange'},
          {title: 'Draft 1', color: 'purple'},
          {title: 'Draft 2', color: 'purple'},
          {title: 'Finalize', color: 'green'}
        ]},
      ]},
      {title: 'Coordination with Vendors/Contractors', processTypes: [
        {title: 'Parts Order', pluralTitle: 'Part Orders', processSteps: [
          {title: 'Parts Specified', color: 'orange', defaultNotes: 'Link with order contents is attached'},
          {title: 'Submitted', color: 'purple'},
          {title: 'Ready to Ship', color: 'blue'},
          {title: 'Shipped', color: 'blue', defaultNotes: 'Please enter tracking code above', inputType: 'text'},
          {title: 'Received', color: 'green'},
          {title: 'Invoice Paid', color: 'green'}
        ]},
        {title: 'Contract Job', pluralTitle: 'Contracted Work', processSteps: [
          {title: 'Consultation', color: 'orange'},
          {title: 'Proposal & Quote', color: 'purple'},
          {title: 'Proposal Signed', color: 'purple'},
          {title: 'Deposit Paid', color: 'purple'},
          {title: 'Work Started', color: 'blue'},
          {title: 'Work Complete', color: 'blue'},
          {title: 'Paid in Full', color: 'green'},
        ]},
        {title: 'Outsourced Product', pluralTitle: 'Outsourced Products', processSteps: [
          {title: 'Kickoff Meeting', color: 'orange'},
          {title: 'Understand Outsourcing Limitations', color: 'orange'},
          {title: 'Specify Product Requirements', color: 'orange'},
          {title: 'Design 1', color: 'purple'},
          {title: 'Design 2', color: 'purple'},
          {title: 'Design 3', color: 'purple'},
          {title: 'Prototype 1', color: 'blue'},
          {title: 'Prototype 2', color: 'blue'},
          {title: 'Prototype 3', color: 'blue'},
          {title: 'Optimize for Manufacturing', color: 'green'}
        ]}
      ]},
      {title: 'Initiatives & Special Projects', processTypes: [
        {title: 'Generic Initiative', pluralTitle: 'Generic Initiatives', processSteps: [
          {title: 'Prep', color: 'orange', substeps: [
            {title: 'Define Goals', color: 'orange', inputType: 'text'},
            {title: 'Define Plan', color: 'orange', inputType: 'text'},
            {title: 'Assign Roles', color: 'orange'},
          ]},
          {title: 'Execute', color: 'purple', substeps: [
            {title: 'Phase 1', color: 'purple'},
            {title: 'Phase 2', color: 'purple'},
            {title: 'Phase 3', color: 'purple'},
          ]},
          {title: 'Review', color: 'blue'},
          {title: 'Done', color: 'green'}
        ]},
        {title: 'Process Improvement', pluralTitle: 'Process Improvements', processSteps: [
          {title: 'Write Proposal', color: 'orange'},
          {title: 'Get Approval', color: 'orange'},
          {title: 'Try New Process Out', color: 'purple'},
          {title: 'Make Part of Standard Process', color: 'green'},
        ]},
        {title: 'New Tool', pluralTitle: 'New Tools', processSteps: [
          {title: 'Research Options', color: 'orange'},
          {title: 'Write Proposal', color: 'orange'},
          {title: 'Get Approval', color: 'orange'},
          {title: 'Try Tool', color: 'purple'},
          {title: 'Standardize Tool Use', color: 'green'},
        ]},
        {title: 'Company Restructure', pluralTitle: 'Company Restructure', processSteps: [
          {title: 'Document Existing Issues', color: 'orange'},
          {title: 'Document Existing Strengths', color: 'orange'},
          {title: 'Agree on Goals', color: 'purple'},
          {title: 'Consider Directions', color: 'purple'},
          {title: 'Select Direction', color: 'blue'},
          {title: 'Announce', color: 'blue'},
          {title: 'Execute', color: 'green'},
          {title: 'Review', color: 'green'}
        ]},
      ]},
      {title: 'Team Building', processTypes: [
        {title: 'Team Position', pluralTitle: 'New Team Positions', exampleInstanceTitles: ['Position #1', 'Position #2'], processSteps: [
          {title: 'Outreach', color: 'orange'},
          {title: 'Select Candidates', color: 'orange', hasProtagonistDecision: 1},
          {title: 'Interview Candidates', color: 'orange'},
          {title: 'Make Offer', color: 'purple'},
          {title: 'Offer Accepted', color: 'green'}
        ]},
        {title: 'Recent Hire', pluralTitle: 'Recent Hires', exampleInstanceTitles: ['Person #1', 'Person #2'], processSteps: [
          {title: 'Offer Accepted', color: 'orange'},
          {title: 'Company Announcement', color: 'orange'},
          {title: 'Prepare Assets', color: 'purple'},
          {title: 'Handoff Assets', color: 'purple'},
          {title: 'Training', color: 'green'},
          {title: 'Operating Independently', color: 'green'}
        ]},
        {title: 'New Skill', pluralTitle: 'New Skills', exampleInstanceTitles: ['Person #1', 'Person #2'], processSteps: [
          {title: 'Commit to Improvement', color: 'orange'},
          {title: 'Define Goal', color: 'orange'},
          {title: 'Develop Training Plan', color: 'purple'},
          {title: 'Mid-way Check-in', color: 'blue'},
          {title: 'Goal Achieved', color: 'green'},
        ]},
      ]},
      {title: 'Operations', processTypes: [
        {title: 'Consulting Project', pluralTitle: 'Consulting Projects', exampleInstanceTitles: ['Project #1', 'Project #2'], processSteps: [
          {title: 'Initial Meeting', color: 'orange'},
          {title: 'Research', color: 'orange'},
          {title: 'Propose Solutions', color: 'purple'},
          {title: 'Incorporate Feedback', color: 'purple'},
          {title: 'Select Proposal', color: 'purple', hasProtagonistDecision: 1},
          {title: 'Implement Solution', color: 'green'}
        ]},
        {title: 'Sales Lead', pluralTitle: 'Sales Leads', exampleInstanceTitles: ['Lead #1', 'Lead #2'], processSteps: [
          {title: 'Initial Contact', color: 'orange'},
          {title: 'Shown Interest / Engaged', color: 'orange'},
          {title: 'Assess Needs / Requirements', color: 'purple'},
          {title: 'Qualified', color: 'purple'},
          {title: 'Proposal', color: 'purple'},
          {title: 'Verbal Agreement', color: 'green'},
          {title: 'Signed & Paid / Closed', color: 'darkGreen'},
          {title: 'Handoff', color: 'darkGreen'},
          {title: 'Lost', color: 'red'},
        ]},
        {title: 'Business Development Partner', pluralTitle: 'Business Development Partners', exampleInstanceTitles: ['Lead #1', 'Lead #2'], processSteps: [
          {title: 'Initial Contact', color: 'orange'},
          {title: 'Engaged', color: 'orange'},
          {title: 'Schedule Meeting', color: 'purple'},
          {title: 'Meeting', color: 'purple'},
          {title: 'Connect w/ CEO', color: 'purple'},
          {title: 'First Customer Generated', color: 'green'}
        ]},
      ]},
      {title: 'Research & Development', processTypes: [
        {title: 'Product', pluralTitle: 'Products Development', exampleInstanceTitles: ['Product #1', 'Product #2'], processSteps: [
          {title: 'Brainstorm', color: 'orange'},
          {title: 'Define Requirements', color: 'orange'},
          {title: 'Agree on Direction', color: 'orange'},
          {title: 'Design 1', color: 'purple'},
          {title: 'Design 2', color: 'purple'},
          {title: 'Design 3', color: 'purple'},
          {title: 'Prototype 1', color: 'blue'},
          {title: 'Prototype 2', color: 'blue'},
          {title: 'Prototype 3', color: 'blue'},
          {title: 'Optimize for Manufacturing', color: 'green'}
        ]},
        {title: 'Software Project', pluralTitle: 'Software Projects', exampleInstanceTitles: ['Feature #1', 'Feature #2'], processSteps: [
          {title: 'Plan/Design', color: 'orange'},
          {title: 'Write Specification', color: 'orange'},
          {title: 'Implement', color: 'purple'},
          {title: 'Test', color: 'blue'},
          {title: 'Go Live', color: 'green'}
        ]}
      ]},
    ]
  }}
];

// var x = '';

// _.forEach(processTypeCategories, category => {
//   _.forEach(category.content.subCategories, subCategory => {
//     x += `------ ${subCategory.title} ------\n\n\n`;

//     _.forEach(subCategory.processTypes, processType => {
//       x += `${processType.pluralTitle}:\n`;

//       _.forEach(processType.processSteps, processStep => {
//         x += `- ${processStep.title}\n`;

//         _.forEach(processStep.substeps, processStep => {
//           x += `--- ${processStep.title}\n`;
//         });
//       });

//       x += '\n\n';
//     });
//   });
// });

// console.log(x);

export default processTypeCategories;
