import moment from 'moment';
import _ from 'lodash';

export default function getTrialStatus({session}) {
  var {status, trialEndDate} = _.get(session.user, `licenseStatuses.${APP_KEY}`, {status: 'inactive', trialEndDate: null});

  if (status !== 'active') {
    if (_.get(session.user, `licenseStatuses.allApps`, {status: 'inactive'}).status === 'active') {
      status = 'active';
    }
    else if (!trialEndDate || moment().utc().isBefore(trialEndDate)) {
      status = 'trial';
    }
  }

  if (!status) status = 'inactive';

  return status;
}
