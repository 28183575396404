import { Platform } from 'react-native';

export default function withKeyEvents(Target) {
  if (Platform.OS !== 'web') return Target; //HINT this is a web-only mixin for standardized hotkey handling

  return class ComponentWithKeyEvents extends Target {
    componentDidMount(...args) {
      if (this.handleKeyDown) {
        document.addEventListener('keydown', this.handleKeyDown);
      }

      if (super.componentDidMount) super.componentDidMount(...args);
    }

    componentWillUnmount(...args) {
      if (super.componentWillUnmount) super.componentWillUnmount(...args);

      if (this.handleKeyDown) {
        document.removeEventListener('keydown', this.handleKeyDown);
      }
    }
  }
}
