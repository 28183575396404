import React, { PureComponent } from 'react';
import {Text as RNText} from 'react-native';
import {K} from '../../styles';

var style = {
  ...K.fonts.label
};

export default class Text extends PureComponent {
  render() {
    var {props} = this;

    return (
      <RNText {...props} style={{...style, ...props.style}}>
        {props.children}
      </RNText>
    );
  };
}
