import { K } from '../../styles';
import Text from '../text/text';
import { View, Pressable } from 'react-native';
import React, { useState, useCallback, useRef } from 'react';
import ReactDOM, { createPortal } from 'react-dom';
import _ from 'lodash';

export default class Tooltip extends React.Component {
  state = {isVisible: false, clientBoundingRect: null};

  constructor(props) {
    super(props);

    this.show = this.show.bind(this);
    this.hide = this.hide.bind(this);
    this.alertText = this.alertText.bind(this);
  }

  show() {
    this.setState({
      clientBoundingRect: K.isWeb ? ReactDOM.findDOMNode(this).getBoundingClientRect() : {},
      isVisible: true
    });
  }

  hide() {
    this.setState({isVisible: false});
  }

  alertText() {
    alert(this.props.text)
  }

  render() {
    var {props} = this;
    var {children, pressToView} = props;
    var {isVisible, clientBoundingRect} = this.state;
    var {yPosition = 'bottom'} = this.props;
    var ViewComponent = props.component || (pressToView ? Pressable : View);

    return (
      <ViewComponent
        style={{position: 'relative', ...props.style}}
        dataSet={{nohover: 1, ...props.dataSet}}
        {..._.pick(props, ['onPress', 'focusable'])}
        {...(K.isWeb ? {onMouseEnter: this.show, onMouseLeave: this.hide} : {})}
        {...(!K.isWeb && pressToView && props.text ? {onPress: this.alertText} : {})}
      >
        {isVisible && !!props.text && createPortal((
          <View
            style={{
              position: 'fixed',
              left: clientBoundingRect.left + (props.left ? 0 : clientBoundingRect.width),
              ...(yPosition === 'bottom' ? {top: clientBoundingRect.top + clientBoundingRect.height } 
                : {top: clientBoundingRect.top - clientBoundingRect.height - K.spacing}),
              zIndex: 100
            }}
            pointerEvents='none'
          >
            <View
              style={{
                backgroundColor: 'black',
                borderRadius: K.borderRadius,
                padding: K.margin,
                borderWidth: 1,
                position: 'relative',
                left: props.left ? 0 : '-100%',
                borderColor: 'rgba(255, 255, 255, 0.2)',
                zIndex: 100,
                marginTop: K.margin,
                minWidth: 80,
                maxWidth: props.maxWidth,
                ...props.tooltipStyle
              }}
            >
              <Text style={{color: 'white', ...props.tooltipTextStyle}}>{props.text}</Text>
            </View>
          </View>
        ), document.getElementById('root'))}
        {children}
      </ViewComponent>
    );
  }
}
