import AsyncStorage from '@react-native-async-storage/async-storage';
import Cookies from 'js-cookie';
import { Platform } from 'react-native';

if (Platform.OS === 'web') {
  var set = (key, value) => Cookies.set(key, value, {expires: 365*10});
  var get = (key) => Cookies.get(key);
  var remove = (key) => Cookies.remove(key);
}
else {
  var set = (key, value) => AsyncStorage.setItem(key, value);
  var get = (key) => AsyncStorage.getItem(key);
  var remove = (key) => AsyncStorage.removeItem(key);
}

global.sessionStore = {
  setToken: async (token) => {
    await set('@sessionToken', token);

    global.sessionToken = token;
  },
  getToken: async () => {
    var token = await get('@sessionToken');

    global.sessionToken = token;

    return token;
  },
  removeToken: async () => {
    await remove('@sessionToken');

    delete global.sessionToken;
  },
  set: async (key, value) => {
    try {
      var value = JSON.stringify(value)

      await set(`@${key}`, value);
    }
    catch (error) {
      console.error(error);
    }
  },
  get: async (key) => {
    try {
      var value = JSON.parse(await get(`@${key}`));
    }
    catch (error) {

    }

    return value;
  }
};
