import React, { PureComponent } from 'react';
import { TextInput as RNTextInput } from 'react-native';
import LabelledView from '../labelled-view/labelled-view';
import { K } from '../../styles';
import _ from 'lodash';

var styles = {
  input: {
    backgroundColor: K.colors.gray,
    borderRadius: K.borderRadius,
    paddingHorizontal: K.spacing,
    ...K.fonts.standard
  }
};

function getTextWidth({
  text,
  fontSize = 12.6,
  fontFamily = K.fontFamily,
  letterSpacing = 0.4,
  fontWeight = 'normal'
}) {
  var newEl = document.createElement('div');

  newEl.style.position = 'fixed';
  newEl.innerHTML = text;
  newEl.style['white-space'] = 'pre-line';
  newEl.style['font-size'] = fontSize + 'px';
  newEl.style['letter-spacing'] = letterSpacing + 'px';
  newEl.style['font-family'] = fontFamily;
  newEl.style['font-weight'] = fontWeight;

  document.body.appendChild(newEl);

  var height = newEl.clientWidth;

  newEl.remove();

  return height;
}

export default class TextInput extends PureComponent {
  state = {scrollHeight: null};

  constructor(props) {
    super(props);

    this.handleChangeText = this.handleChangeText.bind(this);
    this.updateScrollHeight = this.updateScrollHeight.bind(this);
    this.handleLabelledViewPress = this.handleLabelledViewPress.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.getRef = this.getRef.bind(this);
  }

  handleChangeText(...args) {
    var value = args[0];

    if (this.props.onChangeText) this.props.onChangeText(...args);
    if (this.props.onInput) this.props.onInput({value});

    if (typeof(document) !== 'undefined' && this.inputRef && this.inputRef !== document.activeElement) this.props.onChange({value});

    this.updateScrollHeight();

    this.setState({value});

    if (this.autoWidth) this.setState({textWidth: getTextWidth({text: value || this.props.placeholder || '', fontWeight: _.get(this.props.style, 'fontWeight', 'normal'), fontSize: _.get(this.props.style, 'fontSize', K.fonts.standard.fontSize)})});
  }

  handleFocus() {
    if (this.props.onFocus) this.props.onFocus({ref: this.inputRef});
  }

  handleBlur(event) {
    if (this.props.onChange && this.props.editable !== false && this.state.value !== this.props.value) this.props.onChange({value: this.state.value});
    if (this.props.onBlur) this.props.onBlur({value: this.state.value, event});
  }

  handleKeyDown = (event) => {
    if (this.props.blurOnEnter && event.keyCode === 13) {
      event.preventDefault();
      event.target.blur();
    }

    if (this.props.onKeyDown) this.props.onKeyDown(event);
  }

  handleLabelledViewPress() {
    this.inputRef.focus();
  }

  updateScrollHeight() {
    if (this.props.multiline && K.isWeb && !this.props.disableAutoHeight) {
      this.inputRef.style.height = 0;
      this.inputRef.style.overflow = 'hidden';
      this.inputRef.style.height = `${this.inputRef.scrollHeight + 1}px`;
      this.inputRef.style.overflow = 'auto';
    }
  }

  getRef(ref) {
    if (ref) {
      if (this.inputRef) {
        ref.removeEventListener('keydown', this.handleKeyDown);
      }

      ref.addEventListener('keydown', this.handleKeyDown);
    }

    this.inputRef = ref;

    this.props.getInputRef && this.props.getInputRef(ref);

    if (ref) this.updateScrollHeight();

    if (!this.initialSelectionSet && this.props.initialSelection) {
      this.initialSelectionSet = true;

      ref.setNativeProps({selection: this.props.initialSelection});
    }
  }

  get displayValue() {
    return this.state.value;
  }

  render() {
    var {props} = this;
    var {scrollHeight} = this.state;
    var inputStyle = {height: props.multiline ? 'auto' : K.inputHeight, ...styles.input, ...props.inputStyle, ...props.style};

    if (this.props.standardAutoheightStyles) {
      inputStyle = {...inputStyle, minHeight: K.inputHeight, paddingTop: K.verticalInputPadding, paddingBottom: K.verticalInputPadding, maxHeight: 150, ...props.style};
    }

    var TI = (
      <RNTextInput
        {..._.omit(props, ['onChange', 'onChangeText', 'value', 'onBlur'])}
        {...(!props.returnKeyType && !props.multiline ? {blurOnSubmit: true, returnKeyType: 'done'} : {blurOnSubmit: false})}
        ref={this.getRef}
        selection={this.props.initialSelection}
        value={this.displayValue}
        placeholderTextColor={this.props.placeholderTextColor || 'rgb(179, 179, 179)'}
        onBlur={this.handleBlur}
        onChangeText={this.handleChangeText}
        style={{
          ...inputStyle,
          ...(!this.displayValue && this.props.placeholder && this.props.placeholder.toUpperCase() === this.props.placeholder ? {...K.fonts.label, opacity: 1} : {}),
          ...(K.isWeb && props.multiline && !this.props.disableAutoHeight ? {height: inputStyle.maxHeight ? Math.min(scrollHeight, inputStyle.maxHeight) : scrollHeight, overflow: 'auto'} : {}),
          ...(props.inlineLabelledView ? {flex: 1} : {}),
          ...(props.autoWidth ? {width: (this.state.textWidth || getTextWidth({text: this.displayValue || this.props.placeholder || '', fontWeight: _.get(this.props.style, 'fontWeight', 'normal'), fontSize: _.get(this.props.style, 'fontSize', K.fonts.standard.fontSize)})) + 5} : {})
        }}
      />
    );

    return props.label ? (
      <LabelledView
        label={props.label}
        styles={props.labelledViewStyles}
        gray={props.grayLabelledView}
        inline={props.inlineLabelledView}
        minLabelWidth={props.minLabelWidth}
        labelAfter={props.labelAfter}
        onPress={this.handleLabelledViewPress}
      >
        {TI}
      </LabelledView>
    ) : TI;
  }

  static getDerivedStateFromProps(props, state) {
    var prevProps = state.prevProps || {};
    var value = prevProps.value !== props.value ? props.value : state.value;

    return {value, prevProps: props};
  }
}
