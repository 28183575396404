import { K } from '../../styles';

var s = {};

s.orgIcon = {
  position: 'relative',
  width: K.calc(44),
  height: K.calc(44),
  justifyContent: 'center',
  alignItems: 'center'
};
  s.orgIconInitials = {
    position: 'absolute',
    opacity: 1
  };

export default s;
