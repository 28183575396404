import React, { Component, Fragment } from 'react';
import { View, ScrollView, Keyboard, Platform, TouchableOpacity, Image } from 'react-native';
import { connect, deleteOrg, setActiveOrg, updateOrgs } from '@symbolic/redux';
import { withRouter } from 'react-router-native';
import { withSafeAreaInsets } from 'react-native-safe-area-context';
import FreeEmailDomains from 'free-email-domains';
import { K } from '../../../styles';
import Button from '../../button/button';
import PickerInput from '../../picker-input/picker-input';
import copyImage from '../../../assets/copy-icon.png';
import editIcon from './edit-icon-black.png';
import confirm from '../../../confirm.js';
import * as Clipboard from 'expo-clipboard';

import ColorSchemeEditor from '../../color-scheme-editor/color-scheme-editor';
import DocumentTitle from '../../document-title/document-title';
import Text from '../../text/text';
import Popup from '../../popup/popup';
import Tooltip from '../../tooltip/tooltip';
import TextInput from '../../text-input/text-input';
import LabelledView from '../../labelled-view/labelled-view';
import styleSpread from '../../../style-spread';
import styles from './edit-org-view.styles';
import lib, {api} from '@symbolic/lib';
import _ from 'lodash';

var s = styleSpread(styles);

class EditOrgView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      keyboardIsShowing: false,
      org: {},
      justCopied: false,
      hasCopied: false,
      inviteeEmail: '',
      invitePopupVisible: false,
      colorSchemePopupVisible: false,
      colorSchemeWasChanged: false
    };

    this.leaveWorkspace = this.leaveWorkspace.bind(this);
    this.deleteWorkspace = this.deleteWorkspace.bind(this);
    this.copyInviteLink = this.copyInviteLink.bind(this);
    this.inviteUserByEmail = this.inviteUserByEmail.bind(this);
  }

  async componentDidMount() {
    Keyboard.addListener(Platform.OS === 'ios' ? 'keyboardWillShow' : 'keyboardDidShow', this.handleKeyboardShow);
    Keyboard.addListener(Platform.OS === 'ios' ? 'keyboardWillHide' : 'keyboardDidHide', this.handleKeyboardHide);

    await this.considerLoadingResources();
  }

  async componentDidUpdate() {
    await this.considerLoadingResources();
  }

  async considerLoadingResources() {
    if (this.orgId !== _.get(this.state, 'org.id')) {
      var org = await api.get('org', {where: {id: this.orgId}});

      var orgUsersResponse = await api.request({uri: '/get-users', body: {orgId: this.orgId}});

      this.setState({org, orgUsers: _.filter(_.get(orgUsersResponse, 'data.users'), `rolesByOrgId.${this.orgId}`)});

      if (this.isOwner) {
        var response = await api.request({uri: '/orgs/invites/get', body: {orgId: this.orgId, domainOnly: 1}});
        var orgInvite = _.get(response, 'data.orgInvite');

        this.setState({inviteLink: `${{protagonist: 'protag.app', weflowLite: 'polydot.app', stories: 'stories.symbolicframeworks.com', todot: 'todot.symbolicframeworks.com', designEngine: 'config.symbolicframeworks.com'}[this.props.appKey]}/workspace/invite/accept/${orgInvite.id}/${orgInvite.code}`});
      }
    }
  }

  componentWillUnmount() {
    Keyboard.removeListener(Platform.OS === 'ios' ? 'keyboardWillShow' : 'keyboardDidShow', this.handleKeyboardShow);
    Keyboard.removeListener(Platform.OS === 'ios' ? 'keyboardWillHide' : 'keyboardDidHide', this.handleKeyboardHide);
  }

  handleKeyboardShow = () => this.setState({keyboardIsShowing: true});
  handleKeyboardHide = () => this.setState({keyboardIsShowing: false});

  async handleOrgUpdate({key, value}) {
    var valueValid = true;

    if (key === 'domain') {
      valueValid = !FreeEmailDomains.includes(value);

      if (!valueValid) {
        alert('Please enter a private domain.');
      }
    }

    if (valueValid) {
      await api.update('orgs', {where: {id: this.orgId}, props: {[key]: value}});

      this.setState(({org}) => ({org: {...org, [key]: value}}));
    }
  }

  async handleUserRoleChange({orgUser, role}) {
    if (role === 'left') {
      alert(`Only a user who leaves an org themselves can have the role 'left' - 'disabled' is probably what you are looking for.`);
    }
    else {
      await api.request({uri: '/orgs/update-user', body: {orgUserId: orgUser.id, role, orgId: this.orgId}});

      var {orgUsers} = this.state;

      var userIndex = _.findIndex(orgUsers, {id: orgUser.id});

      orgUsers[userIndex] = {...orgUsers[userIndex], rolesByOrgId: {...orgUsers[userIndex].rolesByOrgId, [this.orgId]: role}};

      this.setState({orgUsers});
    }
  }

  handleHueChange = async ({value}) => {
    var hue = Math.floor(parseInt(value));

    if (hue && typeof(hue) === 'number' && hue > 0 && hue <= 360) {
      this.handleOrgUpdate({key: 'hue', value: hue});
    }
    else {
      alert('Please enter a whole number between 1 and 360');

      this.handleOrgUpdate({key: 'hue', value: null});
    }
  }

  async leaveWorkspace() {
    if (this.isIndividualOrg) {
      alert('You cannot leave your individual org');
    }
    else if (this.isOnlyOwner) {
      alert('Please promote a new workspace owner before leaving the workspace');
    }
    else {
      if (await confirm('Leave', `Are you sure you want to remove yourself from this workspace permanently? This cannot be undone.`)) {
        await api.request({uri: '/orgs/leave', body: {orgId: this.orgId}});

        var personalOrg = _.find(this.props.session.orgs, {id: this.props.session.user.personalOrgId});

        personalOrg = personalOrg || _.find(this.props.session.orgs, org1 => {
          return org1.id !== this.orgId;
        });

        await this.props.setActiveOrg({activeOrgId: personalOrg.id});

        await this.props.updateOrgs({orgs: _.filter(this.props.session.orgs, org => org.id !== this.orgId)});

        this.props.history.push('/');
      }
    }
  }

  async deleteWorkspace() {
    if (this.isIndividualOrg) {
      alert('You cannot delete your individual org');
    }
    else {
      if (await confirm('Delete', `Delete this workspace?`)) {
        if (await confirm('Confirm', 'Are you 100% sure?... Just checking, this cannot be undone.')) {
          this.props.deleteOrg({orgId: this.orgId});

          this.props.history.push('/');
        }
      }
    }
  }

  async inviteUserByEmail() {
    var {inviteeEmail} = this.state;
    var {orgId} = this;

    if (!lib.validation.emailIsValid(inviteeEmail)) {
      alert('Please provide a valid email');
    }
    else {
      await api.request({uri: '/orgs/invites/create', body: {orgId, email: inviteeEmail, appKey: this.props.appKey}});

      this.setState({inviteeEmail: 'Invite email sent'});

      setTimeout(() => this.setState({inviteeEmail: ''}), 1000);
    }
  }

  copyInviteLink() {
    Clipboard.setString(this.state.inviteLink);

    this.setState({justCopied: true, hasCopied: true});

    setTimeout(() => this.setState({justCopied: false}), 1000);
  }

  get orgId() {
    return _.toNumber(this.props.match.params.orgId);
  }

  get isOwner() {
    var {orgUsers} = this.state;

    var orgUser = _.find(orgUsers, {id: _.get(this.props, 'session.user.id')});

    return  _.get(orgUser, `rolesByOrgId.${this.orgId}`) === 'owner';
  }

  get isOnlyOwner() {
    var {orgUsers} = this.state;

    var ownerOrgUsers = _.filter(orgUsers, [`rolesByOrgId.${this.orgId}`, 'owner']);

    return this.isOwner && ownerOrgUsers.length === 1;
  }

  get isIndividualOrg() {
    var {orgs} = this.props.session.user;

    return _.get(this, 'props.session.user.personalOrgId') === this.orgId;
  }

  render() {
    var {errorMessage, org, orgUsers, invitePopupVisible, colorSchemePopupVisible} = this.state;

    var errorMessage = errorMessage || _.get(this, 'props.session.error.message');

    return (
      <DocumentTitle title={`${org.title} Workspace - ${this.props.appName}`}>
        <View {...s.editOrgView}>
          {errorMessage && <View {...s.errorMessage}>
            <Text {...s.errorMessageText}>{errorMessage}</Text>
          </View>}
          <ScrollView
            style={{width: '100%', height: '100%'}}
            contentContainerStyle={{justifyContent: 'flex-end', padding: K.spacing, paddingVertical: K.spacing * (K.isWeb ? 4 : 2)}}
            keyboardShouldPersistTaps='handled'
          >
            <View style={{maxWidth: 400, width: '100%', alignSelf: 'center'}}>
              <TextInput
                {...s.input}
                grayLabelledView
                label='Title'
                editable={this.isOwner}
                value={org.title || ''}
                onChange={({value}) => this.handleOrgUpdate({key: 'title', value})}
              />
              {!!this.isOwner && (<Fragment>
                <Tooltip text={`Auto-include anyone who signs up with this email domain`}>
                  <TextInput
                    {...s.input}
                    grayLabelledView
                    editable={this.isOwner}
                    value={org.domain || ''}
                    placeholder='COMPANY.COM'
                    autoCapitalize='none'
                    label='Domain'
                    onChange={({value}) => this.handleOrgUpdate({key: 'domain', value})}
                  />
                </Tooltip>
                <LabelledView label='Custom Workspace Color' gray styles={{innerView: {flexDirection: 'row'}}}>
                  <TextInput
                    {...s.input}
                    editable={this.isOwner}
                    value={org.hue || ''}
                    placeholder='ENTER A HUE: 1-360'
                    onChange={this.handleHueChange}
                    style={{borderTopLeftRadius: 0, borderTopRightRadius: 0, borderBottomRightRadius: 0, flex: 1}}
                  />
                  <View style={{width: K.inputHeight, height: K.inputHeight, borderBottomRightRadius: K.borderRadius, marginLeft: 1, backgroundColor: lib.colors.colorFor({org})}}/>
                </LabelledView>

                <LabelledView onPress={() => this.setState({colorSchemePopupVisible: true})} label='Workspace Color Scheme' gray styles={{innerView: {flexDirection: 'row'}}}>
                  <TextInput
                    {...s.input}
                    editable={false}
                    placeholder={org.colorScheme ? org.colorScheme.templateKey : 'NONE'}
                    style={{textTransform:'uppercase', borderTopLeftRadius: 0, borderTopRightRadius: 0, borderBottomRightRadius: 0, flex: 1}}

                  />
                  <Button
                    onPress={() => this.setState({colorSchemePopupVisible: true})}
                    icon={this.isOwner ? editIcon : '' }
                    style={{width: K.inputHeight, height: K.inputHeight}}
                  />
                </LabelledView>

                <Button
                  style={{alignSelf: 'stretch', alignSelf: 'stretch', marginTop: K.spacing * 4}}
                  mode='dark'
                  label='Invite Team Members'
                  onPress={() => this.setState({invitePopupVisible: true})}
                />
              </Fragment>)}
              <View {...s.inputGroup}>
                <View style={{...styles.inputRow, marginBottom: K.spacing * 2}}>
                  <View style={{flex: 1, width: '100%'}}>
                    {_.map(orgUsers, orgUser => {
                      var role = _.get(orgUser, `rolesByOrgId.${this.orgId}`);

                      return (
                        <View style={{flex: 1, width: '100%', flexDirection: 'row', borderRadius: K.borderRadius, marginBottom: 1}} key={orgUser.id}>
                          <View style={{borderTopLeftRadius: K.borderRadius, borderBottomLeftRadius: K.borderRadius, justifyContent: 'center', flexGrow: 1, height: '100%', paddingHorizontal: K.spacing, backgroundColor: K.colors.gray}}>
                            <Text>{orgUser.name}</Text>
                          </View>
                          <PickerInput
                            enabled={this.isOwner || role === 'left'}
                            value={role}
                            buttonStyle={{borderRadius: 0}}
                            onChange={({value}) => {
                              this.handleUserRoleChange({orgUser, role: value});
                            }}
                            showDownArrow
                            options={[{value: 'owner', title: 'Owner'}, {value: 'member', title: 'Member'}, {value: 'disabled', title: 'Disabled'}, {value: 'left', title: 'Left'}]}
                          />
                          <View style={{...styles.userEditButton, backgroundColor: lib.colors.colorFor({user: orgUser})}}/>
                          {/*<TouchableOpacity style={{...styles.userEditButton, backgroundColor: lib.colors.colorFor({userId: orgUser.id})}} onPress={() => {

                          }}>
                            <Image source={editIconBlack} {...s.editImage}/>
                          </TouchableOpacity>*/}

                        </View>
                      );
                    })}
                  </View>
                </View>
              </View>
              {!this.isIndividualOrg && (<View {...s.inputGroup}>
                {/*<View {...s.buttonContainer}>
                  <Button
                    {...s.importBackupButton}
                    textStyle={{color: 'white'}}
                    label='Import Backup'
                    onPress={this.importBackup}
                  />
                </View>*/}
                {!this.isIndividualOrg && (
                  <Button
                    {...s.leaveWorkspaceButton}
                    textStyle={{color: 'black'}}
                    label='Remove yourself from workspace'
                    onPress={this.leaveWorkspace}
                  />
                )}
                {this.isOwner && !this.isIndividualOrg && (
                  <Button
                    {...s.deleteWorkspaceButton}
                    textStyle={{color: 'black'}}
                    label='Delete Workspace'
                    onPress={this.deleteWorkspace}
                  />
                )}
              </View>)}
            </View>
          </ScrollView>
          {colorSchemePopupVisible && (
            <Popup
              onClose={() => this.setState({colorSchemePopupVisible: false, colorSchemeWasChanged: false})}
              closeConfirmMessage={this.state.colorSchemeWasChanged ? `Are you sure you want to cancel editing your workspace color scheme?`: null}
            >
              <ColorSchemeEditor
                orgId={this.orgId}
                org={org}
                onCancel={() => this.setState({colorSchemePopupVisible: false})}
                onChange={({value}) => this.setState({colorSchemeWasChanged: true, org: {...this.state.org, colorScheme: value}})}
              />
            </Popup>
          )}

          {invitePopupVisible && (
            <Popup
              onClose={() => this.setState({invitePopupVisible: false})}
            >
              {!!org.domain && (
                <View>
                  <View {...s.inputRow}>
                    <View {...s.inviteLinkRow}>
                      <TextInput style={{flex: 1, backgroundColor: K.colors.gray, borderTopRightRadius: 0, borderBottomRightRadius: 0}} editable={false} selectTextOnFocus value={this.state.justCopied ? 'Copied to clipboard!' : this.state.inviteLink}/>
                      <TouchableOpacity style={{backgroundColor: K.colors.gray, borderTopRightRadius: K.borderRadius, borderBottomRightRadius: K.borderRadius, width: K.button.width, alignItems: 'center', justifyContent: 'center', marginLeft: 1}} onPress={this.copyInviteLink}>
                        <Image {...s.inviteLinkImage} source={copyImage}/>
                      </TouchableOpacity>
                    </View>
                  </View>
                  <Text style={{opacity: 0.7, marginBottom: K.spacing, padding: K.spacing, borderRadius: K.borderRadius, fontStyle: 'italic'}}>
                    {`People with @${org.domain} emails can register using this link`}
                  </Text>
                </View>
              )}
              <View {...s.inputRow}>
                <View style={{flex: 1, flexDirection: 'row'}}>
                  <TextInput
                    style={{flexGrow: 1, borderTopRightRadius: 0, borderBottomRightRadius: 0}}
                    labelledViewStyles={styles.fullLineInputContainer}
                    autoCapitalize='none'
                    label='Invite by email'
                    value={this.state.inviteeEmail || ''}
                    onChange={({value}) => this.setState({inviteeEmail: value})}
                  />
                <Button
                  style={{marginLeft: 1, height: K.inputHeight, alignSelf: 'flex-end', borderTopLeftRadius: 0, borderBottomLeftRadius: 0}}
                  label='Invite'
                  onPress={this.inviteUserByEmail}
                />
                </View>
              </View>
            </Popup>
          )}
        </View>
      </DocumentTitle>
    );
  }
}

export default withSafeAreaInsets(withRouter(connect({
  mapState: (state, ownProps) => {
    var {session} = state;
    var {orgId} = ownProps.match.params;
    orgId = _.toNumber(orgId);
    var org = _.find(session.orgs, {id: orgId});

    if (ownProps.history && !org) ownProps.history.push('/404/workspace');

    return {session};
  },
  mapDispatch: {setActiveOrg, updateOrgs, deleteOrg}
})(EditOrgView)))
