import {K} from '../../styles';

var s = {};

s.button = {
  paddingHorizontal: 0,
  paddingVertical: 0,
  backgroundColor: 'transparent'
};
  s.view = {
    ...K.button,
    width: 'auto',
    minWidth: K.button.width
  };
    s.initialsText = {
      ...K.fonts.label,
      opacity: 0.8
    };
    s.fullTitleText = {
      ...K.fonts.label,
      opacity: 1,
      ...(K.isWeb ? {maxWidth: 200, textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'} : {})
    };

export default s;
