import React, { Component, Fragment } from 'react';

import { TouchableOpacity, View, Image } from 'react-native';
import { K } from '../../styles';

import Label from '../label/label';
import Text from '../text/text';
import prepareToAnimate from '../../prepare-to-animate';
import _ from 'lodash';
import checkIcon from '../../assets/check-icon.png';
import checkIconWhite from '../../assets/check-icon-white.png';

class CheckboxInput extends Component {
  handleChange = () => {
    prepareToAnimate();

    this.props.onChange({value: this.props.value ? 0 : 1});
  }

  render() {
    var {value} = this.props;
    var LabelComponent = this.props.text ? Text : Label;

    return (
      <TouchableOpacity
        onPress={this.handleChange}
        style={{flexDirection: 'row', alignItems: 'center', minHeight: K.button.height, borderRadius: K.borderRadius, backgroundColor: K.colors.gray, ...this.props.style}}
      >
        <LabelComponent style={{flex: 1, paddingVertical: K.margin - K.margin / 3, paddingHorizontal: K.spacing - K.spacing / 3, ...this.props.labelStyles}}>{this.props.label}</LabelComponent>
        <View style={{backgroundColor: value ? 'black' : 'transparent', justifyContent: 'center', alignItems: 'center', borderLeftWidth: 1, borderLeftColor: 'white', alignSelf: 'stretch', width: K.button.width, borderTopRightRadius: K.borderRadius, borderBottomRightRadius: K.borderRadius, ...this.props.checkboxStyle}}>
          <Image source={value ? checkIconWhite : checkIcon} style={{opacity: value ? 1 : 0.3, width: K.calc(20), height: K.calc(20)}}/>
        </View>
      </TouchableOpacity>
    );
  }
}

export default CheckboxInput;
