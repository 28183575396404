import React, { Component } from 'react';
import { connect } from '@symbolic/redux';
import { withRouter } from 'react-router-native';
import { View, Text } from 'react-native';
import Link from '../link/link';
import { K } from '../../styles';
import moment from 'moment';
import _ from 'lodash';

import styles from './trial-status-button.styles';
import styleSpread from '../../style-spread';

var s = styleSpread(styles);

class TrialStatusButton extends Component {
  render() {
    var {user} = this.props.session;

    var isSubscribed = (_.get(user, `licenseStatuses.${APP_KEY}.status`, 'inactive') === 'active') || (_.get(user, 'licenseStatuses.allApps.status', 'inactive') === 'active');
    var trialEndDate = _.get(user, `licenseStatuses.${APP_KEY}.trialEndDate`);
    var daysLeft = trialEndDate && moment.utc(trialEndDate).diff(moment.utc(), 'days');

    // var licensePurchases = _.get(user, 'billingGroup.licensePurchases', {[APP_KEY]: 0, allApps: 0});
    // var licensedUserIds = _.get(user, 'billingGroup.licensedUserIds', {[APP_KEY]: {}, allApps: {}});

    // var unassignedSeats = _.sumBy([APP_KEY, 'allApps'], productKey => (licensePurchases[productKey] || 0) - _.keys(licensedUserIds[productKey]).length);

    return (
      !isSubscribed && K.isWeb && !this.props.activeProcessInstance && (
        <Link {...s.trialButton} to='/billing'>
          <View {...s.trialButtonTextContainer}>{/* WARNING important container element because of web <a tag */}
            {/*unassignedSeats > 0 ? (<View>
              <Text {...s.trialText}>You have {unassignedSeats}</Text>
              <Text style={{...styles.trialText}}>unassigned seat{unassignedSeats === 1 ? '' : 's'}!</Text>
            </View>) : */}
            {!trialEndDate ? (
              <View>
                <Text style={{...styles.trialText, opacity: 0.7}}>{`Free`}</Text>
                <Text style={{...styles.trialText, opacity: 1}}>{`Trial`}</Text>
              </View>
            ) : (
              daysLeft > 0 ? (
                <View>
                  <Text {...s.trialText}>{`${daysLeft} day${daysLeft === 1 ? '' : 's'}`}</Text>
                  <Text style={{...styles.trialText, opacity: 0.7}}>{`Trial`}</Text>
                </View>
              ) : (
                <View>
                  <Text {...s.trialText}>{`Free Plan`}</Text>
                  <Text style={{...styles.trialText, color: 'red'}}>{`Trial Expired`}</Text>
                </View>
              )
            )}
          </View>
        </Link>
      )
    );
  };
}

export default withRouter(connect({
  mapState: state => ({session: state.session})
})(TrialStatusButton));
